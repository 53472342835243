import { Dispatch } from 'redux';

import { configs } from '$configs';
import { localStorage } from '$gbusiness/services';
import { fetchApi } from '$gbusiness/services/api';
// import loginMock from '$gbusiness/mocks/auth/login.json';
// import registerMock from '$gbusiness/mocks/auth/register.json';
// import resetMock from '$gbusiness/mocks/auth/resetPassword.json';
import { toast } from '$gcomponents/reusables';
import { COLORS } from '$gbusiness/enums';
import UserModel, { defaultUser, deriveRawToUser, LoginModel, RegisterModel } from '$gbusiness/models/user';

import {
  AuthActionTypes,
  PROCESSING,
  LOGGING_IN,
  LOGGING_OUT,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_SUCCESS,
} from './types';
import { addMinutesToTimestamp } from '$ghelpers/util';
import { SET_SCREEN_STATE } from '../router/types';
import { LOAD_CARDS_SUCCESS } from '../card/types';

const storeAuth = async (response, user) => {
  const authObj = {
    accessToken: response.accessToken,
    refreshToken: response.refreshToken,
    ...(response.expiredAt && { expTimestp: addMinutesToTimestamp(response.expiredAt) }),
    ...(user && { userId: user.userId }),
  };

  await localStorage.setStorageItems(authObj);
};

export function handleFail(dispatch, err, key, toastStyle = '') {
  const errorKey = key || 'ERROR.SERVER';
  dispatch({
    type: LOGIN_FAILURE,
    err: errorKey,
  });

  if (toastStyle) toast({ text: err, message: errorKey, color: COLORS.DANGER, cssClass: toastStyle });
}

export function login(param: LoginModel) {
  return async (dispatch: Dispatch<AuthActionTypes>, getState) => {
    dispatch({
      type: LOGGING_IN,
      loadingText: 'PROGRESS.LOGGING_IN',
    });

    const response = await fetchApi({
      url: configs.api.login,
      param,
      isPublic: true,
      // mockData: loginMock,
    });

    if (!response || !response.user || !response.accessToken) {
      handleFail(dispatch, response?.message, 'MESSAGE.LOGIN_FAIL', 'large');
      return;
    }

    const user: UserModel = deriveRawToUser(response.user) || defaultUser;
    await storeAuth(response, user);

    dispatch({
      type: LOGIN_SUCCESS,
      accessToken: response.accessToken,
      user,
    });

    dispatch({
      type: LOAD_CARDS_SUCCESS,
      cards: response.cards,
    });
  };
}

export function logout() {
  return (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch({
      type: LOGGING_OUT,
      loadingText: 'PROGRESS.LOGGING_OUT',
    });

    setTimeout(() => {
      localStorage.clearAuth();
      window.location.reload();
    }, 1000);
  };
}

export function register(param: RegisterModel) {
  return async (dispatch: Dispatch<AuthActionTypes>, getState) => {
    dispatch({
      type: LOGGING_IN,
      loadingText: 'PROGRESS.SUBMITTING',
    });

    const response = await fetchApi({
      url: configs.api.register,
      param,
      isPublic: true,
      // mockData: registerMock,
    });

    if (!response || !response.user || !response.accessToken) {
      handleFail(dispatch, response?.message, 'MESSAGE.REGISTER_FAIL', 'large');
      return;
    }

    const user = deriveRawToUser(response.user) || defaultUser;
    await storeAuth(response, user);

    dispatch({
      type: REGISTER_SUCCESS,
      accessToken: response.accessToken,
      user,
    });
    dispatch({
      type: LOAD_CARDS_SUCCESS,
      cards: response.cards,
    });
  };
}

export function requestPasswordReset(email) {
  return async (dispatch: Dispatch, getState) => {
    dispatch({
      type: PROCESSING,
      loadingText: 'PROGRESS.SUBMITTING',
    });

    const response = await fetchApi({
      url: configs.api.forgotPassword,
      param: { email },
      isPublic: true,
    });

    if (!response || response.status !== 200) {
      dispatch({
        type: LOGIN_FAILURE,
        err: 'ERROR.NETWORK',
      });

      toast({ message: response.err || 'MESSAGE.NETWORK', color: COLORS.DANGER });
      return;
    }

    dispatch({
      type: SET_SCREEN_STATE,
      payload: 'FORGET_PASSWORD',
    });
  };
}

export function resetPassword(token, email, password) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PROCESSING,
      loadingText: 'PROGRESS.SUBMITTING',
    });

    const response = await fetchApi({
      url: configs.api.resetPassword,
      param: {
        token,
        email,
        password,
      },
      // mockData: resetMock,
    });

    if (!response || !response.success) {
      handleFail(dispatch, response?.message, 'ERROR.PASSWORD_RESET', 'large');
      return;
    }

    dispatch({
      type: SET_SCREEN_STATE,
      payload: 'PASSWORD_RESET',
    });
  };
}
