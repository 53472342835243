import React from 'react';
import CategoryModel from '$business/models/category';
import { Row, Col } from '$gstyles';

import { Wrapper } from './styles';
import ProductSection from '../productSection';
import { SettingModel } from '$business/models/store';
import DisplaySecond from '$components/helpers/displaySecond';

interface CategorySectionProps {
  setting: SettingModel;
  category: CategoryModel;
}

const CategorySection: React.FC<CategorySectionProps> = ({ category, setting }) => {
  if (!category) return null;
  const { categories, products } = category;
  return (
    <Wrapper>
      <div className="category-name">
        <DisplaySecond obj={category} prop="categoryName" display2nd={setting.displaySecond} />
      </div>
      {products && products?.length > 0 && (
        <Row className="products">
          {products?.map((product, i) => (
            <Col key={i} gridSize={6} className="col">
              <ProductSection product={product} setting={setting} />
            </Col>
          ))}
        </Row>
      )}
      {categories?.map((category, i) => (
        <CategorySection key={i} category={category} setting={setting} />
      ))}
    </Wrapper>
  );
};

export default CategorySection;
