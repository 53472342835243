import styled from 'styled-components';

import { Div, FONT, SPACE } from '$gstyles';
import { Link } from '$gcomponents/primitives';

export const Wrapper = styled(Link)`
  position: relative;
  width: ${props => props.size.width};
  min-width: 100px;
  border-radius: ${props => props.borderRadius || '9px'};
  height: ${props => props.size.height};
  background-color: ${props => props.bgcolor};
  color: ${props => props.color};
  margin: ${props => `${props.size.marginHeight} ${props.size.marginWidth}`};
  cursor: pointer;
  display: flex;
  box-shadow: 0.1em 0.1em 0.2em rgb(0 0 0 / 30%);
  flex-direction: column;

  .upper-box {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .is-new {
    position: absolute;
    color: #ffff00;
    font-weight: 700;
    font-size: 0.9em;
    text-transform: uppercase;
    background-color: #ff1114;
    padding: 0px 6px;
    left: -12px;
    top: 0px;
    border-radius: 5px;
    transform: rotate(-45deg);
  }
  .flags {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
  }
  .flags > span {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    top: 0;
    left: 4px;
    display: inline-block;
    width: 20px;
    background-color: #fff;
    margin: 0 -3px;
    height: 20px;
  }
  .flags .vegan {
    background-image: url(/assets/img/vegan.png);
  }
  .flags .gf {
    background-image: url(/assets/img/gf.png);
  }
  .flags .hot {
    background-image: url(/assets/img/hot.png);
  }
  .info {
    position: absolute;
    right: -1px;
    bottom: -4px;
    z-index: 3;
    border-radius: 50%;

    .MuiSvgIcon-root {
      font-size: 36px;
    }
  }
`;

export const TitleSection = styled.div`
  height: ${props => props.height};
  position: relative;
  line-height: 100%;
  letter-spacing: -0.5px;
  width: 96%;
  margin: auto;
`;

export const TitleInner = styled.div`
  position: absolute;
  margin: 0;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

export const ImgSectionWrapper = styled.div`
  position: relative;
  flex: 1;
  padding: ${SPACE.SMALL};
  padding-top: 0;

  > div {
    background-image: url(${props => props.src});
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 7px;
  }
`;

export const PriceSectionWrapper = styled.div`
  background-color: var(--ion-color-danger);
  padding: ${SPACE.MEDIUM};
  color: var(--ion-color-danger-contrast);
  height: ${props => props.height};
  text-align: center;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

export const IconWrapper = styled(Div)`
  position: absolute;
  top: -${SPACE.LARGE};
  right: -${SPACE.LARGE};
  left: initial;
  z-index: 1;
  color: var(--ion-color-danger);

  ion-icon {
    font-size: ${FONT.XXXLARGE};
    --ionicon-stroke-width: 96px;
  }
`;
