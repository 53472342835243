import _ from 'lodash';
import CartItemModel, { CartModifierModel } from '$business/models/cartItem';
import CartModel from '../../models/cart';
import ProductModel from '../../models/product';

export function getModifiersFromCart(cart, productIndex) {
  if (!cart.products[productIndex]) return [];
  return cart.products[productIndex].modifiers || [];
}

export function getModifierFromCart(cart, productIndex, modifierIndex) {
  const modifiers = getModifiersFromCart(cart, productIndex);
  if (!modifiers.length || !modifiers[modifierIndex]) return undefined;
  return modifiers[modifierIndex];
}

export function changeField(list, index, key, value) {
  if (!list.length) return list;

  return list.map((item, i) => {
    if (i !== index) return item;
    else
      return {
        ...item,
        [key]: value,
      };
  });
}

export function sortModifiers(modifiers) {
  return modifiers.sort(
    (a, b) => a.modifierSetIndex - b.modifierSetIndex || a.modifierIndex - b.modifierIndex,
  );
}

export function changeModifierField(list, msindex, mindex, key, value) {
  if (!list.length) return list;

  return list.map(m => {
    if (m.modifierSetIndex !== msindex || m.modifierIndex !== mindex) return m;
    else
      return {
        ...m,
        [key]: value,
      };
  });
}

export function mergeObject(list, obj, defaultIndex) {
  if (!list.length) return list;

  const index = defaultIndex < 0 ? list.length - 1 : defaultIndex;
  return list.map((item, i) => {
    if (i !== index) return item;
    else return _.merge(item, obj);
  });
}

export function calculateItemPriceFromList(list) {
  if (!list.length) return list;

  const item = list[list.length - 1];
  return calculateItemPrice(item);
}

export function deriveModifierPrice(modifier, baseOnly = false) {
  if (!modifier) return 0;
  const {
    quantity,
    price: { original, price2, price3 },
    splits,
  } = modifier;
  let total = 0;
  if (splits?.length > 0 && !baseOnly) {
    total = splits.reduce((acc, s) => {
      acc = acc + s.price;
      return acc;
    }, 0);
  }
  if (original > 0) {
    if (quantity === 2 && price2) return total + price2 || 0;
    else if (quantity === 3 && price3) return total + price3 || 0;
    else return total + (original || 0) * quantity;
  }
  return total + 0;
}

export function calculateModifierSums(modifiers) {
  if (!modifiers || !modifiers.length) return 0;

  return modifiers.reduce((acc, modifier) => {
    const modifierPrice = deriveModifierPrice(modifier);
    acc = acc + modifierPrice;
    return acc;
  }, 0);
}

export function calculateItemPrice(item: CartItemModel) {
  const modifierPrice = calculateModifierSums(item.modifiers);
  const original = item.price.original || 0;
  const discount = item.price.discount || 0;
  const delivery = item.price.delivery || 0;
  const subtotal: number = modifierPrice + original;
  const subtotalSum: number = subtotal * item.quantity;
  const tax: number = Math.round(subtotal * (item.price?.taxPercentage || 0)) / 100;
  const taxSum: number = tax * item.quantity;
  const singleTotal = subtotal + tax + delivery - discount;
  const discountSum = discount * item.quantity;
  const deliverySum = delivery * item.quantity;
  const totalSum = subtotalSum + taxSum - discountSum + deliverySum;

  return {
    ...item,
    modifiers: sortModifiers(item.modifiers),
    price: {
      discount,
      delivery,
      original,
      subtotal,
      tax,
      singleTotal,
      subtotalSum,
      taxSum,
      discountSum,
      deliverySum,
      totalSum,
    },
  };
}

export function generateDefaultItem(cartItem): CartItemModel {
  const modifiers = generateDefaultSelection(cartItem);
  return {
    productId: cartItem.productId,
    productName: cartItem.productName,
    categoryId: cartItem.categoryId,
    categoryName: cartItem.categoryName,
    catProd: cartItem.isProduct,
    image: cartItem.display?.image || '',
    quantity: 1,
    instruction: cartItem.instruction || '',
    isAdded: false,
    price: {
      original: cartItem.price,
      taxPercentage: cartItem.tax,
      subtotal: 0,
      tax: 0,
      discount: 0,
      delivery: 0,
      singleTotal: 0,
      subtotalSum: 0,
      taxSum: 0,
      deliverySum: 0,
      discountSum: 0,
      totalSum: 0,
    },
    modifiers,
  };
}

export function calculateCartPrice(cart: CartModel) {
  const prices = cart.products.reduce(
    (acc, item) => {
      if (!item.isAdded) return acc;

      const modifiedItem = calculateItemPrice(item);
      const { discountSum, subtotalSum, taxSum, totalSum, deliverySum } = modifiedItem.price;
      return {
        totalSum: acc.totalSum + totalSum,
        subtotalSum: acc.subtotalSum + subtotalSum,
        taxSum: acc.taxSum + taxSum,
        deliverySum: acc.deliverySum + deliverySum,
        discountSum: acc.discountSum + discountSum,
      };
    },
    { totalSum: 0, subtotalSum: 0, taxSum: 0, discountSum: 0, deliverySum: 0 },
  );

  const rootPrices = {
    subtotal: prices.subtotalSum,
    discount: prices.discountSum + cart.price.discount,
    delivery: prices.deliverySum + cart.price.delivery,
    tax: prices.taxSum,
  };
  const { discount, subtotal, delivery, tax } = rootPrices;
  const tip = cart.price?.tip || 0;

  return {
    ...cart,
    price: {
      ...rootPrices,
      tip,
      total: subtotal + tax + tip + delivery - discount,
    },
  };
}

export function generateDefaultSelection(product: ProductModel | undefined) {
  if (!product) return [];

  return product.modifierSets.reduce((acc: CartModifierModel[], ms, msIndex) => {
    if (!ms.enableDefaults) return acc;

    ms.modifiers?.forEach((m, mIndex) => {
      if (!m.isDefault) return;
      const mselection: CartModifierModel = {
        modifierSetIndex: msIndex,
        modifierIndex: mIndex,
        modifierName: m.modifierName,
        label: ms.modifierSetName,
        quantity: 1,
        price: {
          original: m.price || 0,
          ...(m.price2 && { price2: m.price2 }),
          ...(m.price3 && { price3: m.price3 }),
        },
      };
      acc.push(mselection);
    });

    return acc;
  }, []);
}
