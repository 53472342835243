import ProductModel, { deriveRawToModel as deriveRawToProduct } from './product';

export default interface CategoryModel {
  categoryId: number;
  parentId: number;
  categoryName: string;
  categoryName2?: string;
  description: string;
  description2?: string;
  shouldUpsell: boolean;
  display: {
    hasImage: boolean;
    image?: string;
    shouldHide: boolean;
    shouldCenter: boolean;
    shouldMarkNew: boolean;
    itemsPerRow: number;
  };
  numProduct?: number;
  categories?: Array<CategoryModel>;
  products?: Array<ProductModel>;
}

export const deriveRawToModel = rawCategory => {
  if (!rawCategory) return undefined;

  const {
    groupId: categoryId,
    parentId,
    name: categoryName,
    name2: categoryName2,
    description,
    description2,
    upsell,
    hasImage,
    image,
    hide,
    center,
    isNew,
    size: itemsPerRow,
    numProduct,
    products,
    items,
  } = rawCategory;

  const category: CategoryModel = {
    categoryId,
    parentId,
    categoryName,
    categoryName2,
    description,
    description2,
    shouldUpsell: !!upsell,
    display: {
      hasImage,
      image,
      shouldHide: !!hide,
      shouldMarkNew: !!isNew,
      shouldCenter: !!center,
      itemsPerRow: itemsPerRow || 4,
    },
    numProduct,
    products: products ? products.map(p => deriveRawToProduct(p, rawCategory)) : [],
    categories: items ? items.map(c => deriveRawToModel(c)) : [],
  };

  return category;
};
