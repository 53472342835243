import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuToggle,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { logOutOutline } from 'ionicons/icons';

import { configs } from '$configs';
import { ROUTES } from '$business/enums';
import intl, { Text } from '$intl';

import { Wrapper } from './styles';
import { AppVersion } from '$gcomponents/reusables';
import { FlexY } from '$gstyles/wrapper';
import { Div, SPACE } from '$gstyles';
import UserModel from '$gbusiness/models/user';
import { Divider } from '@material-ui/core';
import StoreModel from '$business/models/store';
import Brand from '../mainHeader/brand';
import RouterModel from '$gbusiness/models/route';

interface MenuProps extends RouteComponentProps {
  isLoggedIn: boolean;
  user: UserModel;
  currentRoute: string;
  onLogin: Function;
  onLogout: Function;
  store: StoreModel;
  defaultRoute: RouterModel;
}

const Menu: React.FC<MenuProps> = React.memo(
  ({ currentRoute, isLoggedIn, user, onLogin, store, onLogout, defaultRoute, ...rest }) => {
    const { firstName, lastName, email } = user;
    return (
      <Wrapper contentId="sidemenu" side="start" isProd={process.env.REACT_APP_ENV === 'prod'} {...rest}>
        {configs.display.layout === 'FIXED_HEADER' && (
          <IonHeader>
            <IonToolbar>
              {store && <Brand store={store} />}
              <AppVersion />
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent class="outer-content">
          <IonList>
            {ROUTES.MENU.map((appPage, index) => {
              if (appPage.isPrivate && !isLoggedIn) return null;
              const route = index === 0 ? defaultRoute : appPage;
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={currentRoute === route.path ? 'selected pointer' : 'pointer'}
                    routerLink={`${route.path}${route.defaultId || ''}`}
                    routerDirection="none"
                    lines="none"
                    detail={false}>
                    <IonIcon slot="start" size="small" icon={route.iosIcon} />
                    <IonLabel>
                      <Text k={route?.title} />
                    </IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>
          <Divider />
          {!isLoggedIn && (
            <FlexY marginTop={SPACE.LARGE}>
              <IonMenuToggle autoHide={false}>
                <IonItem lines="none" class="pointer" onClick={() => onLogin()} detail={false}>
                  <IonIcon slot="start" size="small" icon={logOutOutline} />
                  <IonLabel>
                    <Text k="MENU.LOG_IN" />
                  </IonLabel>
                </IonItem>
              </IonMenuToggle>
            </FlexY>
          )}
          {isLoggedIn && (
            <FlexY marginTop={SPACE.LARGE}>
              <Div className="name">{intl('SCREEN.MENU.GREETING', { firstName, lastName })}</Div>
              <Div className="email">{email}</Div>
              <IonMenuToggle autoHide={false}>
                <IonItem lines="none" class="pointer" onClick={() => onLogout()} detail={false}>
                  <IonIcon slot="start" size="small" icon={logOutOutline} />
                  <IonLabel>
                    <Text k="MENU.LOG_OUT" />
                  </IonLabel>
                </IonItem>
              </IonMenuToggle>
            </FlexY>
          )}
        </IonContent>
      </Wrapper>
    );
  },
  (pp, np) =>
    pp.currentRoute === np.currentRoute && pp.isLoggedIn === np.isLoggedIn && pp.user.email === np.user.email,
);

export default withRouter(Menu);
