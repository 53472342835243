import React from 'react';

import ProductModel from '$business/models/product';
import BoxSizeModel from '$business/models/boxSize';

import ItemBox from '../ItemBox';
import { PRODUCTS } from '$business/enums/routes';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { menuActions } from '$business/redux/menu';
import { loadingActions } from '$gbusiness/redux/loading';

interface ProductBoxProps {
  product: ProductModel;
  size: BoxSizeModel;
  setting: any;
}

const ProductBox: React.FC<ProductBoxProps> = ({ product, size, setting }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    display: { hasImage, image, shouldMarkNew, isSpicy, isGlutenFree, isVegan },
    productId,
    productName,
    productName2,
    description,
    description2,
    price,
  } = product;

  const onClick = async () => {
    dispatch(loadingActions.loading());
    await dispatch(menuActions.fetchProduct(productId));
    history.push(`${PRODUCTS}/${productId}/0`);
  };

  const backgroundColor = 'var(--ion-color-product)';
  const color = 'var(--ion-color-product-contrast-bw)';

  return (
    <ItemBox
      onClick={onClick}
      title={productName}
      title2={productName2 || ''}
      description={description}
      description2={description2 || ''}
      price={price}
      setting={setting}
      display={{
        hasPrice: true,
        hasImage,
        image,
        shouldMarkNew,
        backgroundColor,
        color,
        size,
        isGlutenFree,
        isVegan,
        isSpicy,
      }}
    />
  );
};

export default ProductBox;
