import { fromUnixTime } from 'date-fns';

export default interface OrderModel {
  orderDate: Date;
  orderNumber: string;
  orderId: number;
  items: Array<string>;
  subtotal: number;
  taxTotal: number;
  transactionId: string;
  grandTotal: number;
}

export const deriveItemNames = items => {
  return items.map(item => {
    return item.name + ' x ' + item.quantity;
  });
};

export const deriveRawToOrder = rawOrder => {
  if (!rawOrder) return undefined;

  // const { name: categoryName, groupId: categoryId } = category;
  const {
    createdAt,
    orderNumber,
    id: orderId,
    subtotal,
    TransactionID: transactionId,
    taxtotal: taxTotal,
    grandtotal: grandTotal,
    detailsObj: items,
  } = rawOrder;

  const order: OrderModel = {
    orderDate: fromUnixTime(createdAt),
    orderNumber,
    orderId,
    subtotal,
    taxTotal,
    grandTotal,
    transactionId,
    items: deriveItemNames(items),
  };

  return order;
};

export const deriveRawToOrders = rawOrders => {
  if (!rawOrders) return [];

  return rawOrders.map(o => deriveRawToOrder(o));
};
