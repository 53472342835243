import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';

import intl from '$intl';
import CardModel from '$gbusiness/models/card';
import { Div, SPACE, CardStyle } from '$gstyles';
import { HeaderTitle, Text } from '$gcomponents/primitives';
import { Button, Divider, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Desktop, Flex } from '$gstyles/wrapper';
import { dialog } from '$gcomponents/reusables';

import CardItem from './cardItem';
import CardFormModal from '.';

interface CardListProps {
  title: string;
  cards: Array<CardModel>;
  onCardSelect?: (e) => void;
  cardIndex?: number;
  onDeleteCard: (e) => void;
}

const { CardListWrapper, CardRow, CardCol } = CardStyle;
//
const CardList: React.FC<CardListProps> = ({ cards, title, onCardSelect, onDeleteCard, cardIndex = 0 }) => {
  const [showModal, setShowModal] = useState(false);
  if (!cards) return null;

  const handleDeleteCard = cardId => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.DELETE_WARNING',
      cssClass: 'large',
      onPress: () => {
        onDeleteCard(cardId);
      },
    });
  };

  const handleCardSelect = e => {
    if (onCardSelect) onCardSelect(e.target.value);
  };

  return (
    <CardListWrapper>
      <HeaderTitle>
        <Text k={title} />
      </HeaderTitle>
      <Div className="cards-box">
        <Desktop>
          <CardRow paddingBottom={SPACE.MEDIUM}>
            <CardCol gridSize={1}>{intl('SCREEN.CARDS.COLS.DEFAULT')}</CardCol>
            <CardCol gridSize={6}>{intl('SCREEN.CARDS.COLS.CARD')}</CardCol>
            <CardCol gridSize={3}>{intl('SCREEN.CARDS.COLS.CARDHOLDER')}</CardCol>
            <CardCol gridSize={2}>{intl('SCREEN.CARDS.COLS.EXPIRY')}</CardCol>
          </CardRow>
          <Divider />
        </Desktop>
        <RadioGroup name="cardIndex" value={cardIndex} onChange={handleCardSelect}>
          {(cards || []).map((card, index) => {
            return (
              <CardRow key={index}>
                {onCardSelect && (
                  <CardCol breakpoint="320px" gridSize={1}>
                    <FormControlLabel value={index} control={<Radio />} label="" />
                  </CardCol>
                )}
                <CardCol breakpoint="320px" gridSize={6}>
                  <Flex>
                    <CardItem card={card} />
                  </Flex>
                </CardCol>
                <CardCol breakpoint="320px" gridSize={3}>
                  {card.cardHolder}
                </CardCol>
                <CardCol breakpoint="320px" gridSize={2} className="expiry">
                  <div>{card.expiry}</div>
                  <DeleteIcon className="pointer" onClick={() => handleDeleteCard(card.cardId)} />
                </CardCol>
              </CardRow>
            );
          })}
        </RadioGroup>
        <Button color="default" size="small" variant="contained" onClick={() => setShowModal(true)}>
          {intl('SCREEN.CARDS.ADD_CARD_BUTTON')}
        </Button>
      </Div>
      <CardFormModal onClose={() => setShowModal(false)} show={showModal} />
    </CardListWrapper>
  );
};

export default CardList;
