import React from 'react';
import { connect } from 'react-redux';
import { IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

import { authActions } from '$gbusiness/redux/auth';
import { ROUTES } from '$business/enums';

import { configs, features } from '$configs';
import Menu from '$components/sideMenu';

import AppOutlet from '$product/_init/appOutlet';
import { loadingActions } from '$gbusiness/redux/loading';
import UserModel from '$gbusiness/models/user';
import StoreModel from '$business/models/store';

interface RouterProps {
  isLoggedIn: boolean;
  currentRoute: string;
  store: StoreModel;
  user: UserModel;
  onLogout: Function;
  showLoginModal: Function;
}

const Router: React.FC<RouterProps> = React.memo(
  ({ isLoggedIn, currentRoute, user, onLogout, showLoginModal, store }) => {
    const redirectRoute = ROUTES.DEFAULT_PUBLIC_ROUTE + ROUTES.DEFAULT_PUBLIC.defaultId;
    const {
      setting: { singlePage },
    } = store;
    const defaultRoute = singlePage ? ROUTES.ROUTE_HOME : ROUTES.ROUTE_CATEGORIES;
    return (
      <IonReactRouter basename={process.env.REACT_APP_PUBLIC_URL}>
        {
          <IonSplitPane contentId="main" when={`(min-width: ${configs.display.splitPaneThreshold})`}>
            {features.useMenu && (
              <Menu
                currentRoute={currentRoute}
                defaultRoute={defaultRoute}
                onLogin={showLoginModal}
                onLogout={onLogout}
                isLoggedIn={isLoggedIn}
                store={store}
                user={user}
              />
            )}
            <AppOutlet isLoggedIn={isLoggedIn} defaultRoute={defaultRoute} singlePage={!!singlePage} />
          </IonSplitPane>
        }
        <Route path="/" render={() => <Redirect to={redirectRoute} />} exact />
      </IonReactRouter>
    );
  },
  (pp, np) => pp.isLoggedIn === np.isLoggedIn && pp.currentRoute === np.currentRoute,
);

const mapStateToProps = state => ({
  isLoggedIn: state.localStorage.accessToken !== '',
  user: state.currentUser,
  store: state.store,
  currentRoute: state.routing.currentRoute,
});

const mapDispatchToProps = {
  showLoginModal: loadingActions.showLoginModal,
  onLogout: authActions.logout,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(Router);
