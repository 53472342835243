export const KEYS = {
  PICKUP: 'PICKUP',
  DELIVERY: 'DELIVERY',
  IN_RESTAURANT: 'IN_RESTAURANT',
};

const LIST = Object.keys(KEYS);

export default LIST.map(item => ({
  label: `DELIVERY_METHOD.${item}`,
  value: item,
}));

export type DELIVERY_METHOD_TYPES = typeof LIST[number] | undefined;
