import React, { useState } from 'react';
import intl from '$intl';
import ProductModel from '$business/models/product';

import { Button } from '$gcomponents/primitives';
import { Button as MuiButton, TextField } from '@material-ui/core';
import { addOutline, removeOutline } from 'ionicons/icons';
import ModifierSetSection from '../modifierSetSection';
import { SettingModel } from '$business/models/store';
import CartItemModel from '$business/models/cartItem';
import { InputWrapper, QuantityButton } from '$components/menu/QuantityInput/styles';
import { IonIcon } from '@ionic/react';
import DisplaySecond from '$components/helpers/displaySecond';

import { IonContentWrapper, IonFooterWrapper, ImageWrapper } from './styles';
import { Col, ColAuto, Row } from '$gstyles';

const imageBase = process.env.REACT_APP_IMAGE_BASE;
interface ModifierModalSectionProps {
  product?: ProductModel;
  setting: SettingModel;
  defaultItem?: CartItemModel;
  onClose: () => void;
  onCartAdd: Function;
}

const ModifierModalSection: React.FC<ModifierModalSectionProps> = ({
  product,
  setting,
  onClose,
  onCartAdd,
  defaultItem,
}) => {
  const [selection, setSelection] = useState(defaultItem?.modifiers || []);
  const [quantity, setQuantity] = useState(defaultItem?.quantity || 1);
  const [instruction, setInstruction] = useState(defaultItem?.instruction || '');

  if (!product) return null;

  const {
    modifierSets,
    description,
    description2,
    display: { hasImage, image, isSpicy, isVegan, isGlutenFree, shouldMarkNew },
  } = product;
  const handleSelectionChange = newSelection => {
    setSelection(newSelection);
  };
  const changeQuantity = num => {
    const newValue = quantity + num;
    if (newValue < 1 || newValue > 20) return;
    setQuantity(newValue);
  };
  const onInstructionChange = e => {
    if (!e?.target) return;
    setInstruction(e.target.value);
  };
  const handleSubmit = () => {
    onCartAdd({
      ...defaultItem,
      quantity,
      instruction,
      modifiers: selection,
    });
  };
  const hasDescription = !!(description || description2);
  const hasMarkers = isSpicy || isGlutenFree || isVegan || shouldMarkNew;

  return (
    <>
      <IonContentWrapper>
        {(hasImage || hasDescription || hasMarkers) && (
          <Row className="top-section">
            {hasMarkers && (
              <>
                <div className="flags">
                  {isSpicy && (
                    <div>
                      <span className="hot" />
                      {intl('COMMON.SPICY')}
                    </div>
                  )}
                  {isGlutenFree && (
                    <div>
                      <span className="gf" />
                      {intl('COMMON.GLUTEN')}
                    </div>
                  )}
                  {isVegan && (
                    <div>
                      <span className="vegan" />
                      {intl('COMMON.VEGAN')}
                    </div>
                  )}
                </div>
                {shouldMarkNew && <div className="is-new">{intl('COMMON.NEW')}</div>}
              </>
            )}
            {hasImage && (
              <ImageWrapper source={`${imageBase}/${image}`}>
                <div />
              </ImageWrapper>
            )}
            {hasDescription && (
              <ColAuto className="description" breakpoint="100px">
                <DisplaySecond obj={product} prop="description" display2nd={setting.displaySecond} />
              </ColAuto>
            )}
          </Row>
        )}
        <div className="modifierSets">
          {modifierSets.map((ms, i) => (
            <ModifierSetSection
              key={i}
              index={i}
              modifierSet={ms}
              setting={setting}
              selection={selection}
              onChange={handleSelectionChange}
            />
          ))}
        </div>
        <div className="quantity">
          <h3>{intl('SCREEN.PRODUCT.QUANTITY')}</h3>
          <InputWrapper>
            <QuantityButton
              onClick={() => {
                changeQuantity(-1);
              }}>
              <IonIcon icon={removeOutline} size="large" />
            </QuantityButton>
            <div className="display">{quantity}</div>
            <QuantityButton
              onClick={() => {
                changeQuantity(1);
              }}>
              <IonIcon icon={addOutline} size="large" />
            </QuantityButton>
          </InputWrapper>
          {setting.enableItemInstruction && (
            <div className="instruction">
              <h4>{setting.instructionText || 'Special Instruction'}</h4>
              <TextField
                className="textarea"
                multiline
                rows={4}
                value={instruction}
                onInput={onInstructionChange}
              />
            </div>
          )}
        </div>
      </IonContentWrapper>
      <IonFooterWrapper>
        <MuiButton className="outlined dark" onClick={onClose}>
          {intl('BUTTON.CANCEL')}
        </MuiButton>
        <Button onClick={handleSubmit}>{intl('SCREEN.PRODUCT.ADD')}</Button>
      </IonFooterWrapper>
    </>
  );
};

export default ModifierModalSection;
