import React from 'react';
import { connect } from 'react-redux';
import { IonContent, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

import { authActions } from '$gbusiness/redux/auth';
import { ROUTES } from '$business/enums';

import { configs } from '$configs';

import AppOutlet from '$product/_init/appOutlet';
import SideMenu from '$components/sideMenu';
import Receipt from '$components/receipt';
import StoreStatus from '$components/storeStatus';
import MainHeader from '$components/mainHeader';
import UserModel from '$gbusiness/models/user';
import { loadingActions } from '$gbusiness/redux/loading';
import StoreModel from '$business/models/store';

interface RouterProps {
  isLoggedIn: boolean;
  currentRoute: string;
  store: StoreModel;
  user: UserModel;
  showLoginModal: Function;
  onLogout: Function;
}

const Router: React.FC<RouterProps> = React.memo(
  ({ isLoggedIn, currentRoute, onLogout, user, store, showLoginModal }) => {
    const {
      setting: { singlePage },
    } = store;
    const defaultRoute = singlePage ? ROUTES.ROUTE_HOME : ROUTES.ROUTE_CATEGORIES;
    const redirectRoute = defaultRoute.path + (singlePage ? '' : defaultRoute.defaultId);
    return (
      <IonReactRouter basename={process.env.REACT_APP_PUBLIC_URL}>
        <SideMenu
          currentRoute={currentRoute}
          defaultRoute={defaultRoute}
          onLogout={onLogout}
          onLogin={showLoginModal}
          isLoggedIn={isLoggedIn}
          user={user}
          store={store}
        />
        <MainHeader isLoggedIn={isLoggedIn} store={store} />
        <IonContent>
          <IonSplitPane contentId="main" when={`(min-width: ${configs.display.splitPaneThreshold})`}>
            <Receipt currentRoute={currentRoute} isLoggedIn={isLoggedIn} />
            <AppOutlet isLoggedIn={isLoggedIn} defaultRoute={defaultRoute} singlePage={!!singlePage} />
          </IonSplitPane>
          <Route path="/" render={() => <Redirect to={redirectRoute} />} exact />
        </IonContent>
        <StoreStatus store={store} />
      </IonReactRouter>
    );
  },
  (pp, np) =>
    pp.isLoggedIn === np.isLoggedIn &&
    pp.currentRoute === np.currentRoute &&
    pp.user.userId === np.user.userId,
);

const mapStateToProps = state => ({
  isLoggedIn: state.localStorage.accessToken !== '',
  user: state.currentUser,
  store: state.store,
  currentRoute: state.routing.currentRoute,
});

const mapDispatchToProps = {
  showLoginModal: loadingActions.showLoginModal,
  onLogout: authActions.logout,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(Router);
