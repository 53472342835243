import styled from 'styled-components';
import { IonContent } from '@ionic/react';
import { ITEM_BOX_MARGIN } from '$business/enums/sizes';
import { Div, SPACE, WEIGHT } from '$gstyles';

export const MenuContainerWrapper = styled(Div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: ${ITEM_BOX_MARGIN};
`;

export const FlexIonContent = styled(IonContent)`
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled(Div)`
  max-width: 800px;
  margin: auto;
  padding: ${SPACE.LARGE};
`;

export const PageTitle = styled(Div)`
  padding: ${SPACE.LARGE} ${SPACE.LARGE} 0 ${SPACE.LARGE};
  font-size: 2rem;
  font-weight: ${WEIGHT.SEMI_BOLD};
  ${p => p.center && 'text-align: center'};
`;
