import React from 'react';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gcomponents/utils';
import { HeaderTitle, Text } from '$gcomponents/primitives';
import { Row, SPACE, Space } from '$gstyles';
import { Wrapper, Cell } from './styles';
import { configs } from '$configs';

interface FormSectionProps {
  FORM: Array<InputRowModel>;
  formik: any;
  children?: React.ReactNode;
  inputType?: string;
  title?: string;
  isHidden?: boolean;
  marginBottom?: string;
  callback?: Function;
  [x: string]: any;
}

const FormSection: React.FC<FormSectionProps> = ({
  FORM,
  formik,
  title,
  children,
  inputType,
  isHidden,
  marginBottom = SPACE.XLARGE,
  callback,
  ...rest
}) => {
  if (isHidden) return null;

  return (
    <>
      {title && (
        <HeaderTitle>
          <Text k={title} />
        </HeaderTitle>
      )}
      <Space.Bottom margin={marginBottom}>
        <Wrapper className="form-group" {...rest}>
          {children ||
            FORM.map((row, i) => {
              if (row.isHidden && row.isHidden(formik.values)) return null;
              return (
                <Row key={i} className={`row ${row.className || ''}`}>
                  {row.items.map((col, j) => {
                    if (col.isHidden && col.isHidden(formik.values)) return null;
                    const marginBottom = `${row.lineSpace || configs.display.formSpace || 0}px`;
                    return (
                      <Cell
                        {...(row.style && { customStyle: row.style })}
                        key={j}
                        className="col"
                        gridSize={col.gridSize || 6}
                        padding={SPACE.SMALL}
                        style={{ marginBottom }}>
                        {input.generateInput(col, formik, inputType, callback)}
                      </Cell>
                    );
                  })}
                </Row>
              );
            })}
        </Wrapper>
      </Space.Bottom>
    </>
  );
};

export default FormSection;
