import React from 'react';
import { connect } from 'react-redux';
import { IonPage, useIonViewDidEnter } from '@ionic/react';
import { useHistory } from 'react-router-dom';

import { screen } from '$gcomponents/hoc';
import { FlexIonContent, PageTitle } from '$styles';
import { Row, SPACE, ColAuto } from '$gstyles';
import { cartActions } from '$business/redux/cart';
import { menuActions } from '$business/redux/menu';
import CategoryModel from '$business/models/category';
import { Button } from '$gcomponents/primitives';
import UpsellContainer from '$components/menu/UpsellContainer';
import { KEYS } from '$business/enums/options/deliveryMethods';

import { IonPageWrapper } from './styles';
import intl from '$intl';
import { ROUTES } from '$business/enums';

interface DecisionScreenProps {
  onHydrate: Function;
  upsell: Array<CategoryModel>;
  cleanProducts: Function;
  changeOrder: Function;
  setting: any;
}

const DecisionScreen: React.FC<DecisionScreenProps> = React.memo(
  ({ upsell, cleanProducts, changeOrder, setting }) => {
    const history = useHistory();
    const { PICKUP, DELIVERY, IN_RESTAURANT } = KEYS;

    useIonViewDidEnter(() => {
      cleanProducts(true);
    });

    const { services } = setting;

    const onDeliveryMethod = async method => {
      await changeOrder({ type: method });
      history.push(ROUTES.CHECKOUT);
    };

    if (!upsell) return <IonPage />;

    return (
      <IonPageWrapper>
        <FlexIonContent>
          <UpsellContainer categories={upsell} setting={setting} />
          <div className="delivery-options-container">
            <PageTitle center marginTop={SPACE.XXLARGE}>
              {intl('CHECKOUT.CHOOSE_METHOD')}
            </PageTitle>
            <Row className="heretogo-choices">
              {services.pickup && (
                <ColAuto>
                  <Button fullWidth className="secondary filled" onClick={() => onDeliveryMethod(PICKUP)}>
                    {intl(services.curbside ? 'CHECKOUT.CURBSIDE' : 'CHECKOUT.PICKUP')}
                  </Button>
                </ColAuto>
              )}
              {services.delivery && (
                <ColAuto>
                  <Button fullWidth className="primary filled" onClick={() => onDeliveryMethod(DELIVERY)}>
                    {intl('CHECKOUT.DELIVERY')}
                  </Button>
                </ColAuto>
              )}
              {services.tableSide && (
                <ColAuto>
                  <Button
                    fullWidth
                    className="tertiary filled"
                    onClick={() => onDeliveryMethod(IN_RESTAURANT)}>
                    {intl('CHECKOUT.IN_RESTAURANT')}
                  </Button>
                </ColAuto>
              )}
            </Row>
          </div>
        </FlexIonContent>
      </IonPageWrapper>
    );
  },
  (pp, np) => pp.upsell?.length === np.upsell?.length,
);

const mapStateToProps = state => ({
  setting: state.store.setting,
  upsell: state.menu.upsell,
});

const mapDispatchToProps = {
  changeOrder: cartActions.changeOrder,
  cleanProducts: cartActions.cleanProducts,
  onHydrate: menuActions.fetchUpsell,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(DecisionScreen));
