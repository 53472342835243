import styled from 'styled-components';
import { IonMenu } from '@ionic/react';
import { FONT, SPACE, WEIGHT } from '$gstyles';

export const Wrapper = styled(IonMenu)`
  ion-content::part(background),
  ion-footer {
    background-color: var(--ion-color-tertiary);
  }

  --side-max-width: 320px;
  --side-min-width: 32px;
  --side-width: 320px;

  ion-content {
    color: var(--ion-color-tertiary-contrast-bw);
  }

  .MuiButton-root {
    border-radius: 0px;
  }

  .header {
    padding: ${SPACE.SMALL};
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast-bw);
    text-align: center;
    font-weight: ${WEIGHT.SEMI_BOLD};
    .title {
      font-size: ${FONT.LARGE};
    }
    .delivery {
      text-transform: uppercase;
      font-size: ${FONT.SMALL};
    }
    .delivery.waive {
      letter-spacing: -0.5px;
    }
  }
`;

export const Content = styled.div`
  color: var(--ion-color-tertiary-contrast-bw);
`;
