import { ROUTES } from '$gbusiness/enums';
import { UserScreen } from '$gbox/screens';
import RouteModel from '$gbusiness/models/route';

import {
  // homeOutline,
  // homeSharp,
  storefrontSharp,
  storefrontOutline,
  restaurantSharp,
  restaurantOutline,
  timeOutline,
  timeSharp,
  personCircleOutline,
  personSharp,
} from 'ionicons/icons';
import {
  CheckoutScreen,
  DecisionScreen,
  HomeScreen,
  MenuScreen,
  ProductScreen,
  AboutUsScreen,
  OrderHistoryScreen,
  ProfileScreen,
} from '$product/screens';

// IMPORTS FROM GBOX
const { USERS } = ROUTES;

export const HOME = '/home';
export const MERCHANTS = '/merchants';
export const GROUP = '/group';
export const CATEGORIES = '/categories';
export const PRODUCTS = '/products';
export const DECISION = '/review';
export const CHECKOUT = '/checkout';
export const ABOUT_US = '/aboutus';
export const HISTORY = '/history';
export const PROFILE = '/profile';

export const ROUTE_CATEGORIES = {
  title: 'MENU.MENU',
  path: CATEGORIES,
  defaultId: '/0',
  component: MenuScreen,
  param: ':categoryId',
  iosIcon: restaurantOutline,
  mdIcon: restaurantSharp,
};
export const ROUTE_ABOUT_US = {
  title: 'MENU.ABOUT_US',
  path: ABOUT_US,
  component: AboutUsScreen,
  iosIcon: storefrontOutline,
  mdIcon: storefrontSharp,
};

export const ROUTE_PRODUCTS = {
  path: PRODUCTS,
  component: ProductScreen,
  param: ':productId/:index',
};

export const ROUTE_DECISION = {
  path: DECISION,
  component: DecisionScreen,
};

export const ROUTE_CHECKOUT = {
  path: CHECKOUT,
  component: CheckoutScreen,
};

export const ROUTE_HOME = {
  title: 'MENU.MENU',
  path: HOME,
  defaultId: '',
  component: HomeScreen,
  // iosIcon: homeOutline,
  // mdIcon: homeSharp,
  iosIcon: restaurantOutline,
  mdIcon: restaurantSharp,
};

export const ROUTE_USERS = {
  path: USERS,
  component: UserScreen,
  param: ':userId',
  exact: true,
  isPrivate: true,
};

export const ROUTE_HISTORY = {
  title: 'MENU.ORDER_HISTORY',
  path: HISTORY,
  component: OrderHistoryScreen,
  iosIcon: timeOutline,
  mdIcon: timeSharp,
  isPrivate: true,
};

export const ROUTE_PROFILE = {
  title: 'MENU.PROFILE',
  path: PROFILE,
  component: ProfileScreen,
  iosIcon: personCircleOutline,
  mdIcon: personSharp,
  isPrivate: true,
};

export const PUBLIC_LIST: Array<RouteModel> = [
  ROUTE_CATEGORIES,
  ROUTE_ABOUT_US,
  ROUTE_PRODUCTS,
  ROUTE_DECISION,
  ROUTE_CHECKOUT,
];

export const PRIVATE_LIST: Array<RouteModel> = [ROUTE_HOME, ROUTE_USERS, ROUTE_HISTORY, ROUTE_PROFILE];

export const MENU: Array<RouteModel> = [ROUTE_CATEGORIES, ROUTE_ABOUT_US, ROUTE_HISTORY, ROUTE_PROFILE];

// export const DEFAULT_PUBLIC: RouteModel = ROUTE_HOME;
// export const DEFAULT_PUBLIC_ROUTE = DEFAULT_PUBLIC.path;
// export const DEFAULT_PUBLIC_ROUTE_FULL = DEFAULT_PUBLIC_ROUTE;

export const DEFAULT_PUBLIC = ROUTE_CATEGORIES;
export const DEFAULT_PUBLIC_ROUTE = ROUTE_CATEGORIES.path;
export const DEFAULT_PUBLIC_ROUTE_FULL = ROUTE_CATEGORIES.path + ROUTE_CATEGORIES.defaultId;
