import {
  LOAD_CARDS_SUCCESS,
  RESET_ALL,
  CardReducerType,
  INIT_STATE,
  IS_PROCESSING,
  ADD_CARD_SUCCESS,
  CARD_FAILURE,
  RESET_STATUS,
  DELETE_CARD_SUCCESS,
  SET_DEFAULT_SUCCESS,
} from './types';

export default function customerReducer(state: CardReducerType = INIT_STATE, action): CardReducerType {
  switch (action.type) {
    case IS_PROCESSING:
      return {
        ...state,
        isProcessing: true,
      };
    case ADD_CARD_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isFinished: true,
      };
    case CARD_FAILURE:
      return {
        ...state,
        isProcessing: false,
      };
    case LOAD_CARDS_SUCCESS:
      return {
        ...state,
        cards: action.cards,
      };
    case DELETE_CARD_SUCCESS:
      return {
        ...state,
        cards: state.cards.filter(c => c.cardId !== action.cardId),
      };
    case SET_DEFAULT_SUCCESS:
      return {
        ...state,
        cards: state.cards.map(c => {
          if (action.cardId === c.cardId) return { ...c, isDefault: true };
          else return { ...c, isDefault: false };
        }),
      };
    case RESET_STATUS:
      return {
        ...state,
        isProcessing: false,
        isFinished: false,
      };
    case RESET_ALL:
      return INIT_STATE;
    default:
      return state;
  }
}
