import React from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { useTheme } from '@material-ui/core';

import { getBoxSize } from '$business/helpers/util';
import CategoryModel from '$business/models/category';
import { MenuContainerWrapper, PageTitle } from '$styles';

import CategoryBox from '../CategoryBox';
import intl from '$intl';

interface UpsellContainerProps {
  categories: Array<CategoryModel>;
  setting: any;
}

const UpsellContainer: React.FC<UpsellContainerProps> = ({ categories, setting }) => {
  const theme = useTheme();
  const screenWidth = useWindowWidth();

  if (!categories?.length) return null;

  const size = getBoxSize(4, screenWidth, theme.breakpoints);

  const cateogories = categories?.map(c => {
    return <CategoryBox key={c.categoryId} category={c} size={size} setting={setting} />;
  });

  return (
    <div>
      <PageTitle center>{intl('CHECKOUT.DECISION_TITLE')}</PageTitle>
      <MenuContainerWrapper justifyContent="space-evenly">{cateogories}</MenuContainerWrapper>
    </div>
  );
};

export default UpsellContainer;
