import React from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { useTheme } from '@material-ui/core';

import { getBoxSize } from '$business/helpers/util';
import CategoryModel from '$business/models/category';
import { MenuContainerWrapper } from '$styles';

import CategoryBox from '../CategoryBox';
import ProductBox from '../ProductBox';

interface CategoryContainerProps {
  category: CategoryModel;
  setting: any;
}

const CategoryContainer: React.FC<CategoryContainerProps> = ({ category, setting }) => {
  const theme = useTheme();
  const screenWidth = useWindowWidth();

  if (!category) return null;

  const size = getBoxSize(category?.display?.itemsPerRow, screenWidth, theme.breakpoints);

  const cateogories = category.categories?.map(c => {
    return <CategoryBox key={c.categoryId} category={c} size={size} setting={setting} />;
  });

  const products = category.products?.map(p => {
    return <ProductBox key={p.productId} product={p} size={size} setting={setting} />;
  });

  return (
    <MenuContainerWrapper>
      {cateogories}
      {products}
    </MenuContainerWrapper>
  );
};

export default CategoryContainer;
