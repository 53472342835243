import HeadersModel from '$business/models/headers';
import { configs } from '$configs';

export const LOAD_HEADERS = 'LOAD_HEADERS';

// Action Types
export interface LoadHeadersType {
  type: typeof LOAD_HEADERS;
  headers: HeadersModel;
}

export type InitActionTypes = LoadHeadersType;

export interface InitModel {
  headers: HeadersModel;
  initSuccess: boolean;
  public?: {
    resetRequested?: boolean;
    err?: string | undefined;
  };
}

export const headersInitState: HeadersModel = {
  env: (process.env.REACT_APP_ENV || '').toString(),
  appName: configs.appName,
  mode: 'ONLINE',
  storeNumber: '',
  userId: '',
};

export const INIT_STATE: InitModel = {
  headers: headersInitState,
  initSuccess: false,
  public: undefined,
};
