import React from 'react';
import { IonPage } from '@ionic/react';

import { H2, H3 } from '$gstyles';

import { OrderDisplayWrapper, ThankyouWrapper } from '../styles';
import intl from '$intl';

interface ThankyouSectionProps {
  order: any;
}

const ThankyouSection: React.FC<ThankyouSectionProps> = ({ order }) => {
  if (!order) return <IonPage />;

  const { orderNumber, display } = order;

  return (
    <ThankyouWrapper>
      <H2>{intl('SCREEN.THANK_YOU.SUBTITLE', { orderNumber })}</H2>
      <H3 className="message">{intl('SCREEN.THANK_YOU.MESSAGE')}</H3>
      <OrderDisplayWrapper>
        <div dangerouslySetInnerHTML={{ __html: display }} />
      </OrderDisplayWrapper>
    </ThankyouWrapper>
  );
};

export default ThankyouSection;
