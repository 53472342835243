import React from 'react';

import BoxSizeModel from '$business/models/boxSize';
import SplitModel from '$business/models/split';

import ItemBox from '../ItemBox';

interface SplitBoxProps {
  split: SplitModel;
  size: BoxSizeModel;
  setting: any;
  numChecks: number;
  onClick: Function;
}

const SplitBox: React.FC<SplitBoxProps> = ({ split, size, setting, numChecks = 0, onClick }) => {
  const { hasPrice, splitName, splitName2, price } = split;

  const backgroundColor = 'var(--ion-color-option)';
  const color = 'var(--ion-color-option-contrast-bw)';

  return (
    <>
      <ItemBox
        onClick={onClick}
        title={splitName}
        title2={splitName2}
        price={price}
        setting={setting}
        display={{
          hasPrice,
          hasImage: false,
          backgroundColor,
          color,
          shouldMarkNew: false,
          size,
          numChecks,
        }}
      />
    </>
  );
};

export default SplitBox;
