import _ from 'lodash';
import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addOutline, removeOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

import { Wrapper, InputWrapper, QuantityButton, Content } from './styles';
import { cartActions } from '$business/redux/cart';
import { SettingModel } from '$business/models/store';
import { TextField } from '@material-ui/core';
import intl from '$intl';

interface QuantityInputProps {
  value?: number;
  productIndex: number;
  setting: SettingModel;
  defaultInstruction?: string;
}

const QuantityInput: React.FC<QuantityInputProps> = React.memo(
  ({ value = 1, productIndex, setting, defaultInstruction = '' }) => {
    const dispatch = useDispatch();
    const [instruction, setInstruction] = useState(defaultInstruction);

    const changeQuantity = num => {
      const newValue = value + num;
      if (newValue < 1 || newValue > 20) return;
      dispatch(cartActions.changeProductQuantity(productIndex, newValue));
    };

    const debouncedDispatch = useCallback(
      _.debounce(value => {
        dispatch(cartActions.changeInstruction(productIndex, value));
      }, 500),
      [],
    );

    const onInstructionChange = e => {
      if (!e?.target) return;
      setInstruction(e.target.value);
      debouncedDispatch(e.target.value);
    };

    return (
      <Wrapper>
        <Content>
          <h2>{intl('SCREEN.PRODUCT.QUANTITY')}</h2>
          <InputWrapper>
            <QuantityButton
              onClick={() => {
                changeQuantity(-1);
              }}>
              <IonIcon icon={removeOutline} size="large" />
            </QuantityButton>
            <div className="display">{value}</div>
            <QuantityButton
              onClick={() => {
                changeQuantity(1);
              }}>
              <IonIcon icon={addOutline} size="large" />
            </QuantityButton>
          </InputWrapper>
          {setting.enableItemInstruction && (
            <>
              <h3>{setting.instructionText || 'Special Instruction'}</h3>
              <TextField multiline rows={4} value={instruction} onInput={onInstructionChange} />
            </>
          )}
        </Content>
      </Wrapper>
    );
  },
  (pp, np) => false,
);

export default QuantityInput;
