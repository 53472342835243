import React from 'react';
import intl from '$intl';
import { getBusinessHours } from '$business/helpers/util';
import { Flex } from '$gstyles/wrapper';
import { Div } from '$gstyles';
import { hour } from '$business/models/store';
import { HoursWrapper } from './styles';

interface BusinessHoursProps {
  hours?: Array<hour>;
}

const BusinessHours: React.FC<BusinessHoursProps> = ({ hours }) => {
  if (!hours) return null;

  return (
    <HoursWrapper>
      {hours?.map((day, index) => (
        <Flex key={index}>
          <Div width="120px">{day.label}:</Div>
          <Div width="200px">{getBusinessHours(day) || intl('SCREEN.ABOUT_US.CLOSED')}</Div>
        </Flex>
      ))}
    </HoursWrapper>
  );
};

export default BusinessHours;
