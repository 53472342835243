import { Div, FONT, SPACE, WEIGHT } from '$gstyles';
import { mobileStyle } from '$gstyles/utils';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .MuiFormControl-root input {
    text-align: left;
  }

  .delivery-message {
    color: var(--ion-color-light-contrast-bw);
    margin-bottom: ${SPACE.LARGE};
    font-weight: ${WEIGHT.SEMI_BOLD};

    .valid {
      color: var(--ion-color-success-tint);
    }
    .invalid {
      color: var(--ion-color-danger-tint);
    }
  }

  button.submit {
    margin: ${SPACE.XLARGE} 0;
    font-size: ${FONT.LARGE};
  }

  .pay-label {
    margin-bottom: ${SPACE.MEDIUM};
    .price {
      color: var(--ion-color-light-contrast-bw);
      font-size: ${FONT.LARGE};
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    .details {
      margin-left: ${SPACE.LARGE};
      padding: ${SPACE.MEDIUM};
      color: var(--ion-link-color);
    }
  }

  .col {
    padding: 4px 8px !important;
  }
`;

export const AccordionWrapper = styled.div`
  .MuiAccordion-root {
    background-color: var(--ion-color-light-tint);
    padding: 0 ${SPACE.MEDIUM};
    border: 1px solid var(--ion-border-color);
  }
  .MuiAccordionSummary-content,
  .MuiIconButton-label {
    color: var(--ion-color-light-contrast-bw);
  }
  .MuiButtonGroup-root {
    background-color: #fff;
  }
  .MuiAccordionDetails-root {
    display: block;
  }
`;

export const TipWrapper = styled(Div)`
  margin: -36px 0px 36px 0px;
  padding-right: 23px;

  .MuiButtonGroup-root {
    width: 100%;
    display: flex;
    button {
      flex: 1;
      padding: ${SPACE.XSMALL};

      span {
        text-transform: initial;
        font-size: 1.1rem;
        display: block;
      }
    }
  }

  button.selected {
    background-color: #ffff99;
  }

  button.notnow {
    background-color: #333;
    color: #eee;
  }

  ${mobileStyle(`
    padding-right: 15px;
  `)}
`;

export const Amount = styled.div`
  font-size: 1.2em;
  font-weight: ${WEIGHT.SEMI_BOLD};
`;
export const Percent = styled.div`
  font-size: 1em;
`;

export const ThankyouWrapper = styled(Div)`
  .message {
    color: var(--ion-color-warning);
  }
`;

export const OrderDisplayWrapper = styled(Div)`
  .myreceipt {
    .container {
      background: transparent;
    }
    .total-section {
      color: var(--color) !important;
    }
    .appt .row {
      display: flex;
      justify-content: space-between;
    }
  }
`;
