import React from 'react';

interface DisplaySecondProps {
  obj: any;
  prop: string;
  lineBreak?: boolean;
  display2nd;
}

export const getSecondLan = (obj, prop, display2nd) => {
  if (!obj) return '';
  if (display2nd) return `${obj[prop] || ''} ${obj[prop + '2'] || ''}`;
  else return obj[prop] || '';
};

const DisplaySecond: React.FC<DisplaySecondProps> = ({ obj, prop, lineBreak = false, display2nd = true }) => {
  return lineBreak ? (
    <>
      <div>{obj[prop] || ''}</div>
      {display2nd && <div>{obj[prop + '2'] || ''}</div>}
    </>
  ) : (
    <>{`${obj[prop] || ''} ${obj[prop + '2'] || ''}`}</>
  );
};

export default DisplaySecond;
