import React, { useState } from 'react';
import { useTheme } from '@material-ui/core';

import { getBoxSize } from '$business/helpers/util';
import { MenuContainerWrapper } from '$styles';

import SplitSetModel from '$business/models/splitSet';
import SplitBox from '../SplitBox';
import { IonContent, IonFooter } from '@ionic/react';
import { Button } from '$gcomponents/primitives';

interface SplitContainerProps {
  splitSet?: SplitSetModel;
  setting: any;
  onSetSplits: (e) => void;
}

const setNumberOfChecks = (selection, maxChecks: number) => {
  if (!selection) return 1;
  const { quantity } = selection;
  return quantity >= maxChecks ? 0 : quantity + 1;
};

const getPrice = (obj, quantity) => {
  const { price, price2, price3 } = obj;
  if (price3 && quantity === 3) return price3;
  if (price2 && quantity === 2) return price2;
  if (price && quantity === 1) return price;
  return 0;
};

const calculatePrice = splits => {
  return splits.reduce((acc, split) => {
    acc = acc + (split.price || 0);
    return acc;
  }, 0);
};

const SplitContainer: React.FC<SplitContainerProps> = ({ splitSet, setting, onSetSplits }) => {
  const theme = useTheme();
  const [splits, setSplits] = useState<Array<any>>([]);
  if (!splitSet) return null;

  const { isMultiple, maxChecks, maxSplits, isOptional } = splitSet;

  const handleClick = (split, index) => {
    const selection = splits.find(s => s.splitIndex === split.index);
    const { splitName, label, price } = split;
    const splitItem = {
      splitIndex: index,
      splitName,
      label,
      quantity: 1,
      price: price || 0,
    };

    if (isMultiple) {
      const newChecks = setNumberOfChecks(selection, maxChecks || 1);
      if (maxSplits && newChecks > maxSplits) {
        alert('Maximum has reached');
        return;
      }
      if (!newChecks) setSplits(splits.filter(s => s.splitIndex !== index));
      else if (!selection) setSplits([...splits, splitItem]);
      else if (selection)
        setSplits(
          splits.map((s, i) => {
            if (s.splitIndex !== index) return s;
            return {
              ...s,
              quantity: newChecks,
              price: getPrice(split, newChecks),
            };
          }),
        );
    } else {
      if (!selection || !selection.quantity) {
        const newSplits = [...splits, splitItem];
        if (!isOptional) {
          finishThis(newSplits);
          return;
        }
        setSplits(newSplits);
      } else {
        setSplits(splits.filter(s => s.splitIndex !== index));
      }
    }
  };

  const finishThis = (newSplits = splits) => {
    onSetSplits({
      price: calculatePrice(newSplits),
      splits: newSplits,
    });
  };

  const size = getBoxSize(5, 600, theme.breakpoints);
  const content = splitSet.splits?.map((split, index) => {
    const splitItem = splits.find(s => s.splitIndex === index);
    return (
      <SplitBox
        key={index}
        split={split}
        size={size}
        setting={setting}
        numChecks={splitItem?.quantity || 0}
        onClick={() => handleClick(split, index)}
      />
    );
  });

  const shouldHideContinue = splitSet && !splitSet.isMultiple && !splitSet.isOptional;

  return (
    <>
      <IonContent>
        <MenuContainerWrapper>{content}</MenuContainerWrapper>
      </IonContent>
      {!shouldHideContinue && (
        <IonFooter>
          <Button fullWidth onClick={() => finishThis(splits)}>
            OK
          </Button>
        </IonFooter>
      )}
    </>
  );
};

export default SplitContainer;
