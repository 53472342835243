import ModifierModel, { deriveModifiers } from './modifier';

export default interface ModifierSetModel {
  modifierSetId: number;
  modifierSetName: string;
  modifierSetName2?: string;
  description: string;
  description2?: string;
  hasImage: boolean;
  isOptional: boolean;
  isMultiple: boolean;
  maxModifiers: number;
  maxChecks: number;
  isMain: boolean;
  enableDefaults: boolean;
  skipDefaults: boolean;
  itemsPerRow: number;
  hasSplits: boolean;
  modifiers?: Array<ModifierModel>;
}

export const deriveRawToModel = rawModiferSet => {
  if (!rawModiferSet) return undefined;

  const {
    id: modifierSetId,
    checks: maxChecks,
    defen: enableDefaults,
    desc: description,
    desc2: description2,
    hide: skipDefaults,
    size: itemsPerRow,
    image: hasImage,
    main: isMain,
    max: maxModifiers,
    multi: isMultiple,
    name: modifierSetName,
    name2: modifierSetName2,
    optional: isOptional,
    subopt: hasSplits,
    options: modifiers,
  } = rawModiferSet;

  const modifierSet: ModifierSetModel = {
    modifierSetId,
    modifierSetName,
    modifierSetName2,
    description,
    description2,
    hasImage: !!hasImage,
    isOptional: !!isOptional,
    isMultiple: !!isMultiple,
    maxModifiers,
    maxChecks,
    isMain: !!isMain,
    enableDefaults: !!enableDefaults,
    skipDefaults: skipDefaults,
    itemsPerRow,
    hasSplits: !!hasSplits,
    modifiers: deriveModifiers(modifiers, rawModiferSet),
  };

  return modifierSet;
};
