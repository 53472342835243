import styled from 'styled-components';
import { SPACE } from '$gstyles';
import { IonContent, IonFooter } from '@ionic/react';

export const IonContentWrapper = styled(IonContent)`
  &.hydrated {
    --background: var(--ion-background-color);
    --color: var(--ion-text-color);
  }
  .modifierSets {
    padding: ${SPACE.LARGE};
    font-size: 1.1em;
  }
  .quantity {
    text-align: center;
  }
  .instruction {
    margin: 40px 20px;
  }
  .textarea {
    width: 100%;
    max-width: 600px;
    textarea {
      padding: ${SPACE.MEDIUM};
    }
  }
  .top-section {
    padding: ${SPACE.MEDIUM};
    justify-content: space-around;
    position: relative;
    flex-direction: column;
    min-height: 30px;
    align-items: center;
    .description {
      margin: ${SPACE.MEDIUM};
    }
  }

  .is-new {
    position: absolute;
    color: #ffff00;
    font-weight: 700;
    font-size: 0.9em;
    text-transform: uppercase;
    background-color: #ff1114;
    padding: 5px 36px;
    left: -34px;
    top: 5px;
    border-radius: 5px;
    transform: rotate(-45deg);
  }
  .flags {
    display: flex;
    z-index: 1;
    margin: ${SPACE.MEDIUM};
  }
  .flags > div {
    display: flex;
    align-items: center;
    font-size: 13px;
    text-transform: uppercase;
    margin: 0px 8px;
  }
  .flags span {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: inline-block;
    width: 20px;
    background-color: #fff;
    margin-right: 5px;
    height: 20px;
  }
  .flags .vegan {
    background-image: url(/assets/img/vegan.png);
  }
  .flags .gf {
    background-image: url(/assets/img/gf.png);
  }
  .flags .hot {
    background-image: url(/assets/img/hot.png);
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  margin: ${SPACE.MEDIUM};
  width: 180px;
  height: 120px;
  /* height: ${p => p.size}; */
  > div {
    background-image: url(${props => props.source});
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 7px;
  }
`;

export const IonFooterWrapper = styled(IonFooter)`
  padding: ${SPACE.MEDIUM};
  display: flex;
  justify-content: space-around;
`;
