import {
  AnyMenuActionTypes,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  CLEAN_CATEGORIES,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  CLEAN_PRODUCT,
  // FETCH_OPTIONS_SUCCESS,
  // FETCH_OPTIONS_FAILURE,
  // CLEAN_OPTIONS,
  ReducerModel,
  MENU_INIT_STATE,
  FETCH_UPSELL_FAILURE,
  FETCH_UPSELL_SUCCESS,
  ORDER_SUCCESS,
  CLEAN_ORDER,
  ORDER_DISPLAY_SUCCESS,
} from './types';

export default function(state: ReducerModel = MENU_INIT_STATE, action: AnyMenuActionTypes): ReducerModel {
  switch (action.type) {
    // Categories
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        category: action.category,
      };
    case FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        category: MENU_INIT_STATE.category,
      };
    case CLEAN_CATEGORIES:
      return {
        ...state,
        category: MENU_INIT_STATE.category,
      };
    // Products
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        product: {
          ...action.product,
          modifierSets: action.modifierSets,
        },
      };
    case FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        product: MENU_INIT_STATE.product,
      };
    case FETCH_UPSELL_SUCCESS:
      return {
        ...state,
        upsell: action.upsell,
      };
    case FETCH_UPSELL_FAILURE:
      return {
        ...state,
        upsell: [],
      };
    case CLEAN_PRODUCT:
      return {
        ...state,
        product: MENU_INIT_STATE.product,
      };
    case ORDER_SUCCESS:
      return {
        ...state,
        order: action.order,
      };
    case ORDER_DISPLAY_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          display: action.display,
        },
      };
    case CLEAN_ORDER:
      const { order, ...newState } = state;
      return newState;
    // Options
    // case FETCH_OPTIONS_SUCCESS:
    //   return {
    //     ...state,
    //     options: action.options,
    //   };
    // case FETCH_OPTIONS_FAILURE:
    //   return {
    //     ...state,
    //     options: MENU_INIT_STATE.options,
    //   }
    // case CLEAN_OPTIONS:
    //   return {
    //     ...state,
    //     options: MENU_INIT_STATE.options,
    //   }
    default:
      return state;
  }
}
