import React from 'react';

import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { deriveRawToOrder } from '$business/models/order';
import { dateToString } from '$ghelpers/date';
import { currency } from '$ghelpers/util';
import { Div } from '$gstyles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export const TABLE_CONFIG = {
  endpoint: configs.api.customer.history,
  isPublic: false,
  pageSize: 10,
  color: COLORS.PRIMARY,
  fontColor: 'var(--ion-color)',
  headerColor: 'var(--ion-color-primary)',
  cellPadding: '16px',
  isStripped: true,
  minWidth: 500,
  maxWidth: 1000,
  shouldVirtualize: false,
  deriveToModel: deriveRawToOrder,
};

export const generateConfig = onClickRow => {
  return {
    ...TABLE_CONFIG,
    onClickRow,
  };
};

const TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.HISTORY.COL.DATE',
    value: 'orderDate',
    component: row => (
      <div>
        <Div>{dateToString(row.orderDate)}</Div>
        <Div>Order #{row.orderNumber}</Div>
      </div>
    ),
    sortable: SORT.DSC,
    sortKey: 'createdAt',
    alwaysShow: true,
    width: 200,
  },
  {
    label: 'SCREEN.HISTORY.COL.ORDER_INFO',
    value: 'items',
    component: row => (
      <div>
        {row.items.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
    ),
    alwaysShow: true,
    width: 400,
  },
  {
    label: 'SCREEN.HISTORY.COL.GRAND_TOTAL',
    value: 'grandTotal',
    component: row => currency(row.grandTotal),
    sortable: SORT.ASC,
    sortKey: 'grandtotal',
    alwaysShow: true,
    width: 120,
  },
  {
    label: 'SCREEN.HISTORY.COL.ACTION',
    value: '',
    component: () => <NavigateNextIcon style={{ color: 'var(--ion-color)' }} />,
    width: 60,
  },
];

export default TABLE;
