import { INPUT_TYPES } from '$gbusiness/enums';
import { input } from '$ghelpers';

const style = {
  label: 'var(--ion-color)',
  error: '#ffaaaa',
  focus: '#99ccff',
  background: '#fff',
};

export const PROFILE_FORM = values => {
  return [
    {
      style,
      items: [
        {
          ...INPUT_TYPES.FIRST_NAME,
          placeholder: 'INPUT.PLACEHOLDER.FIRST_NAME',
          gridSize: 6,
          validation: [input.requiredValidation],
        },
        {
          ...INPUT_TYPES.LAST_NAME,
          placeholder: 'INPUT.PLACEHOLDER.LAST_NAME',
          gridSize: 6,
        },
        {
          ...INPUT_TYPES.EMAIL,
          placeholder: 'INPUT.PLACEHOLDER.EMAIL',
          validation: [
            input.requiredValidation,
            {
              condition: v => !input.validateEmail(v),
              message: 'INVALID_EMAIL',
            },
          ],
          gridSize: 6,
        },
        {
          ...INPUT_TYPES.MOBILE,
          placeholder: 'INPUT.PLACEHOLDER.PHONE',
          gridSize: 6,
        },
        {
          ...INPUT_TYPES.PASSWORD,
          label: 'INPUT.LABEL.NEW_PASSWORD',
          value: 'newPassword',
          placeholder: 'INPUT.PLACEHOLDER.PASSWORD',
          gridSize: 6,
        },
        {
          ...INPUT_TYPES.PASSWORD,
          label: 'INPUT.LABEL.CONFIRM_PASSWORD',
          value: 'confirmPassword',
          placeholder: 'INPUT.PLACEHOLDER.PASSWORD',
          gridSize: 6,
          ...(values?.newPassword?.length > 0 && {
            validation: [
              input.requiredValidation,
              {
                condition: (value, values) => values.newPassword !== value,
                message: 'PASSWORD_MISMATCH',
              },
            ],
          }),
        },
      ],
    },
  ];
};
