import { Dispatch } from 'redux';

import { configs } from '$configs';
import { fetchApi } from '$gbusiness/services/api';
import {
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  CLEAN_CATEGORIES,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  CLEAN_PRODUCT,
  FETCH_UPSELL_SUCCESS,
  FETCH_UPSELL_FAILURE,
  MENU_FAILURE,
  ORDER_SUCCESS,
  CLEAN_ORDER,
  ORDER_DISPLAY_SUCCESS,
  // FETCH_OPTIONS_SUCCESS,
  // FETCH_OPTIONS_FAILURE,
  // CLEAN_OPTIONS,
} from './types';
import {
  rawDataToCategoryModel,
  rawDataToModifierSetModel,
  rawDataToProductModel,
  rawDataToUpsellModel,
} from './utils';
import { LOADING } from '$gbusiness/redux/loading/types';
import { toast } from '$gcomponents/reusables';
import { COLORS } from '$gbusiness/enums';
import { getCardType, getLast4 } from '$ghelpers/util';
// import saveOrderMock from '$business/mocks/saveOrder.json';

export function handleFail(dispatch, err, key, toastStyle = '') {
  const errorKey = key || 'ERROR.SERVER';
  dispatch({
    type: MENU_FAILURE,
    err: errorKey,
  });

  if (toastStyle) toast({ text: err, message: errorKey, color: COLORS.DANGER, cssClass: toastStyle });
}

export function fetchMenu(groupId: number) {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.menu.all,
      param: { id: groupId },
      isPublic: true,
    });

    if (!response?.tree) {
      dispatch({
        type: FETCH_CATEGORIES_FAILURE,
        err: 'ERROR.FETCH_CATEGORY',
      });
    }

    const category = rawDataToCategoryModel(response);

    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      category,
    });
  };
}

export function fetchCategories(groupId: number) {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.menu.categories,
      param: { id: groupId },
      isPublic: true,
    });

    if (!response?.tree) {
      dispatch({
        type: FETCH_CATEGORIES_FAILURE,
        err: 'ERROR.FETCH_CATEGORY',
      });
    }

    const category = rawDataToCategoryModel(response);

    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      category,
    });
  };
}

export function fetchUpsell() {
  return async (dispatch: Dispatch) => {
    // dispatch({
    //   type: LOADING,
    // });

    const response = await fetchApi({
      url: configs.api.menu.upsell,
      isPublic: true,
    });

    if (!response?.success) {
      dispatch({
        type: FETCH_UPSELL_FAILURE,
        err: 'ERROR.FETCH_CATEGORY',
      });
    }

    const upsell = rawDataToUpsellModel(response);

    dispatch({
      type: FETCH_UPSELL_SUCCESS,
      upsell,
    });
  };
}

export function getProduct(product) {
  return { type: FETCH_PRODUCT_SUCCESS, product, modifierSets: [] };
}

export function fetchProduct(productId: number, forceFetch = false, showLoading = false) {
  return async (dispatch: Dispatch, getState) => {
    const existingProduct = getState().menu.product;
    const isSameItem = existingProduct?.productId === productId;
    if (existingProduct && isSameItem && !forceFetch) return;

    if (showLoading) dispatch({ type: LOADING });

    const response = await fetchApi({
      url: configs.api.menu.product,
      param: { id: productId },
      isPublic: true,
    });

    if (!response?.product) {
      dispatch({
        type: FETCH_PRODUCT_FAILURE,
        err: 'ERROR.FETCH_CATEGORY',
      });
    }

    const product = rawDataToProductModel(response.product, response.group);
    const modifierSets = rawDataToModifierSetModel(response.product.options);

    dispatch({
      type: FETCH_PRODUCT_SUCCESS,
      product,
      modifierSets,
    });
  };
}

export function submitOrder(param) {
  return async (dispatch: Dispatch) => {
    const { paymentMethod } = param;
    const isOnline = paymentMethod === 'ONLINE';
    let authId = '';
    let tid = '';

    dispatch({
      type: LOADING,
      loadingText: paymentMethod === 'ONLINE' ? 'PROGRESS.PAYMENT' : 'PROGRESS.SUBMITTING',
    });

    if (isOnline) {
      const response = await fetchApi({
        url: configs.api.order.pay,
        isPublic: true,
        param,
      });
      if (!response?.success) {
        handleFail(dispatch, response?.message, 'ERROR.PAY_ORDER', 'medium');
        return;
      }
      dispatch({
        type: LOADING,
        loadingText: 'PROGRESS.SAVE_ORDER',
      });
      tid = response.tid;
      authId = response.authId;
    }

    const saveResponse = await fetchApi({
      url: configs.api.order.save,
      isPublic: true,
      param: {
        ...param,
        authId,
        tid,
        ...(isOnline && {
          cardType: getCardType(param.cardNumber),
          last4: getLast4(param.cardNumber),
        }),
      },
      // mockData: saveOrderMock,
    });

    if (!saveResponse?.success) {
      handleFail(dispatch, saveResponse?.message, 'ERROR.SAVE_ORDER', 'medium');
      return;
    }

    const { orderId, orderNumber, display } = saveResponse;

    dispatch({
      type: ORDER_SUCCESS,
      order: {
        orderId,
        orderNumber,
        transactionId: saveResponse.transactionId,
        display,
      },
    });
  };
}

export function getOrderDisplay(orderId) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.LOADING',
    });
    const response = await fetchApi({
      url: configs.api.order.display,
      isPublic: true,
      param: {
        orderId,
      },
    });
    if (!response?.success) {
      handleFail(dispatch, response?.message, 'ERROR.ERROR', 'medium');
      return;
    }
    dispatch({
      type: ORDER_DISPLAY_SUCCESS,
      display: response.display,
    });
  };
}

export function cleanOrder() {
  return { type: CLEAN_ORDER };
}

export function dehydrateCategories() {
  return { type: CLEAN_CATEGORIES };
}

export function dehydrateProduct() {
  return { type: CLEAN_PRODUCT };
}
