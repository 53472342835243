import styled from 'styled-components';

import { Col, ItemWrapper, SPACE } from '$gstyles';

export const Wrapper = styled(ItemWrapper)`
  ion-item.item ion-label {
    &.sc-ion-label-md-h,
    &.sc-ion-label-md-s {
      --color: var(--ion-label-color);
    }
  }

  margin-left: -${SPACE.MEDIUM};
  margin-right: -${SPACE.MEDIUM};

  .MuiFormControl-root {
    margin-top: ${SPACE.MEDIUM};
  }
  .MuiInputBase-root {
    font-size: 1.5rem;
  }

  /* .MuiInputBase-input {
    padding: ${SPACE.LARGE} ${SPACE.LARGE};
  } */
`;

export const Cell = styled(Col)`
  ${p =>
    p.customStyle?.background &&
    `
    .MuiInputBase-root {
      background: ${p.customStyle.background};
    }
  `}
  ${p =>
    p.customStyle?.label &&
    `
    label.MuiFormLabel-root {
      color: ${p.customStyle.label};
    }
    .MuiCheckbox-root,
    .MuiFormControlLabel-label {
      color: ${p.customStyle.label};
    }

  `}
  ${p =>
    p.customStyle?.color &&
    `
    fieldset {
      border-color: ${p.customStyle.color};
    }
    input {
      color: ${p.customStyle.color};
    }
  `}
  ${p =>
    p.customStyle?.focus &&
    `
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${p.customStyle.focus};
    }
    .MuiFormLabel-root.Mui-focused {
      color: ${p.customStyle.focus};
    }
  `}
   ${p =>
     p.customStyle?.hover &&
     `
    .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${p.customStyle.focus};
    }
    .MuiFormControl-root:hover label {
      color: ${p.customStyle.focus};
    }
  `}
  ${p =>
    p.customStyle?.error &&
    `
    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: ${p.customStyle.error};
    }
    .MuiFormLabel-root.Mui-error,
    .MuiFormHelperText-root.Mui-error {
      color: ${p.customStyle.error} !important;
    }
  `}
`;
