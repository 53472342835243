import React from 'react';
import ModifierSetModel from '$business/models/modifierSet';

import { Wrapper } from './styles';
import ModifierSection from '../modifierSection';
import { SettingModel } from '$business/models/store';
import { CartModifierModel } from '$business/models/cartItem';
import DisplaySecond from '$components/helpers/displaySecond';

interface ModifierSetSectionProps {
  index: number;
  modifierSet: ModifierSetModel;
  setting: SettingModel;
  selection: Array<CartModifierModel>;
  onChange: Function;
}

const ModifierSetSection: React.FC<ModifierSetSectionProps> = ({
  modifierSet,
  setting,
  index: modifierSetIndex,
  selection = [],
  onChange,
}) => {
  if (!modifierSet) return null;
  const { modifiers } = modifierSet;
  return (
    <Wrapper>
      <div className="description">
        <DisplaySecond obj={modifierSet} prop="description" display2nd={setting.displaySecond} />
      </div>
      {modifiers?.map((modifier, i) => (
        <ModifierSection
          key={i}
          index={i}
          msIndex={modifierSetIndex}
          modifierSet={modifierSet}
          modifier={modifier}
          setting={setting}
          selection={selection}
          onChange={onChange}
        />
      ))}
    </Wrapper>
  );
};

export default ModifierSetSection;
