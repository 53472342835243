import CategoryModel from '$business/models/category';
import ProductModel from '$business/models/product';
import ModifierSetModel from '$business/models/modifierSet';

export const MENU_FAILURE = 'MENU_FAILURE';
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const ORDER_DISPLAY_SUCCESS = 'ORDER_DISPLAY_SUCCESS';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const CLEAN_CATEGORIES = 'CLEAN_CATEGORIES';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';
export const FETCH_UPSELL_SUCCESS = 'FETCH_UPSELL_SUCCESS';
export const FETCH_UPSELL_FAILURE = 'FETCH_UPSELL_FAILURE';
export const CLEAN_PRODUCT = 'CLEAN_PRODUCT';
export const CLEAN_ORDER = 'CLEAN_ORDER';
// export const FETCH_OPTIONS_SUCCESS = 'FETCH_OPTIONS_SUCCESS';
// export const FETCH_OPTIONS_FAILURE = 'FETCH_OPTIONS_FAILURE';
// export const CLEAN_OPTIONS = '';

export type ReducerModel = {
  category: CategoryModel | undefined;
  product: ProductModel | undefined;
  upsell?: Array<CategoryModel>;
  order?: any;
};

// Category
export interface FetchCategoriesSuccessType {
  type: typeof FETCH_CATEGORIES_SUCCESS;
  category: CategoryModel;
}

// Category
export interface FetchUpsellSuccessType {
  type: typeof FETCH_UPSELL_SUCCESS;
  upsell: Array<CategoryModel>;
}

export interface OrderSuccessType {
  type: typeof ORDER_SUCCESS;
  order: any;
}

export interface GetOrderDisplayType {
  type: typeof ORDER_DISPLAY_SUCCESS;
  display: string;
}

export interface FetchCategoriesFailureType {
  type:
    | typeof FETCH_CATEGORIES_FAILURE
    | typeof FETCH_UPSELL_FAILURE
    | typeof FETCH_PRODUCT_FAILURE
    | typeof MENU_FAILURE;
  err: string;
}

export interface CleanCategoriesType {
  type: typeof CLEAN_CATEGORIES | typeof CLEAN_ORDER;
}

// Product
export interface FetchProductSuccessType {
  type: typeof FETCH_PRODUCT_SUCCESS;
  product: ProductModel;
  modifierSets: Array<ModifierSetModel>;
}

export interface CleanProductType {
  type: typeof CLEAN_PRODUCT;
}

// Option
// export interface FetchOptionSuccessType {
//   type: typeof FETCH_OPTIONS_SUCCESS;
//   options: Array<OptionModel>;
// }

// export interface FetchOptionsFailureType {
//   type: typeof FETCH_OPTIONS_FAILURE;
//   err: string;
// }

// export interface CleanOptionsType {
//   type: typeof CLEAN_OPTIONS;
// }

export type CategoryActionTypes =
  | FetchCategoriesSuccessType
  | OrderSuccessType
  | FetchUpsellSuccessType
  | FetchCategoriesFailureType
  | CleanCategoriesType;
export type ProductActionTypes = FetchProductSuccessType | CleanProductType;
// export type OptionActionTypes = FetchOptionSuccessType | FetchOptionsFailureType | CleanOptionsType;
export type AnyMenuActionTypes = CategoryActionTypes | ProductActionTypes | GetOrderDisplayType;

export const MENU_INIT_STATE: ReducerModel = {
  category: undefined,
  product: undefined,
};
