import { IonToolbar, IonButtons, IonMenuToggle, IonButton } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import { Badge, IconButton } from '@material-ui/core';
import StoreModel from '$business/models/store';
import { IonHeaderWrapper } from './styles';
import Brand from './brand';

interface MainHeaderProps {
  isLoggedIn: boolean;
  store: StoreModel;
}

const MainHeader: React.FC<MainHeaderProps> = ({ isLoggedIn, store }) => {
  const cartCount = useSelector(
    (state: any) => state.localStorage.cart?.products.filter(p => p.isAdded).length,
  );
  const MenuToggleIcon = isLoggedIn ? <AccountCircleIcon fontSize="large" /> : <MenuIcon />;

  return (
    <IonHeaderWrapper id="sidemenu">
      <IonToolbar color="secondary">
        <IonButtons slot="start">
          <IonMenuToggle menu="start">
            <IconButton>{MenuToggleIcon}</IconButton>
          </IonMenuToggle>
        </IonButtons>
        <Brand store={store} />
        <IonButtons slot="end">
          {/* {!isLoggedIn && <Button onClick={() => showLoginModal()}>Login</Button>} */}
          <IonMenuToggle autoHide menu="end">
            <IonButton className="receipt">
              <Badge badgeContent={cartCount || 0} invisible={!cartCount} color="error">
                <ShoppingCartIcon />
              </Badge>
            </IonButton>
          </IonMenuToggle>
        </IonButtons>
      </IonToolbar>
    </IonHeaderWrapper>
  );
};

export default MainHeader;
