import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import MuiModal from '$gcomponents/reusables/modal/material';

import { addCard, fetchCards, resetStatus } from '$gbusiness/redux/card/actions';
import intl from '$intl';
import { Button } from '$gcomponents/primitives';
import { FormSection } from '$gcomponents/reusables';
import { input } from '$ghelpers';

import CardFormModel, { defaultCardForm } from '$gbusiness/models/cardForm';
import { CARD_FORM } from './CardForm';

interface CardFormModalProps {
  initialValues?: CardFormModel;
  onClose: () => void;
  show: boolean;
  fullScreen?: boolean;
}

const CardFormModal: React.FC<CardFormModalProps> = ({
  onClose,
  initialValues = defaultCardForm,
  fullScreen = false,
  show,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef<any>();
  const userId = useSelector((state: any) => state.currentUser.userId);
  const card = useSelector((state: any) => state.card);

  const derivedInitialValues = input.deriveFormInitialValues(CARD_FORM, initialValues);
  const validateForm = values => {
    return input.validateError(CARD_FORM, values);
  };
  const onSubmit = values => {
    dispatch(addCard(values));
  };

  const closeModal = () => {
    dispatch(resetStatus());
    formRef.current.resetForm();
    onClose();
  };

  useEffect(() => {
    if (show) {
      formRef.current.resetForm();
      // dispatch(loadingModal()); // Just for testing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!card.isFinished) return;
    dispatch(fetchCards(userId));
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card.isFinished]);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={{
        ...derivedInitialValues,
      }}
      validate={validateForm}
      onSubmit={onSubmit}>
      {formik => {
        return (
          <MuiModal
            open={show}
            width="400px"
            onClose={closeModal}
            fullScreen={fullScreen}
            title={intl('SCREEN.CARDS.CARD_TITLE')}
            footer={
              <Button
                disabled={!formik.isValid && !formik.dirty}
                color="primary"
                isLoading={card.isProcessing}
                loadingText={intl('SCREEN.CARDS.SUBMIT_BUTTON_LOADING')}
                fullWidth
                variant="contained"
                onClick={formik.handleSubmit}>
                {intl('SCREEN.CARDS.SUBMIT_BUTTON')}
              </Button>
            }>
            <Form>
              <FormSection FORM={CARD_FORM} formik={formik} />
            </Form>
          </MuiModal>
        );
      }}
    </Formik>
  );
};

export default CardFormModal;
