export default interface TableModel {
  tableId: string;
  tableName: string;
}

export const deriveRawToTable = rawTable => {
  if (!rawTable) return undefined;

  const table: TableModel = {
    tableId: rawTable.id,
    tableName: rawTable.tableName,
  };

  return table;
};
