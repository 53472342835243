import { format as dateFormat, parseISO } from 'date-fns';
import { DATE_FORMATS } from '../enums';

export const toLocal = dateStr => {
  if (!dateStr) return '';
  if (dateStr[dateStr.length - 1] === 'Z') return dateStr;
  return dateStr + 'Z';
};

export const today = () => {
  return dateFormat(new Date(), DATE_FORMATS.DATE);
};

export const format = (dateStr, formatStr = DATE_FORMATS.DATE) => {
  if (!dateStr) return '';
  return dateFormat(parseISO(dateStr), formatStr);
};

export const formatTime = (dateStr, formatStr = DATE_FORMATS.TIME) => {
  if (!dateStr) return '';
  return dateFormat(parseISO(dateStr), formatStr);
};

export const formatDatetime = (dateStr, formatStr = DATE_FORMATS.DATETIME) => {
  if (!dateStr) return '';
  return dateFormat(parseISO(dateStr), formatStr);
};

export const dateToString = (dateObj, formatStr = DATE_FORMATS.DATE) => {
  if (!dateObj) return '';
  return dateFormat(dateObj, formatStr);
};

export const datetimeToString = (dateObj, formatStr = DATE_FORMATS.DATETIME) => {
  if (!dateObj) return '';
  return dateFormat(dateObj, formatStr);
};
