import StoreModel from '$business/models/store';
import { INIT_STATE } from './types';

import { FETCH_STORE_SUCCESS } from './types';

export function storeReducer(state: StoreModel = INIT_STATE, action): StoreModel {
  switch (action.type) {
    case FETCH_STORE_SUCCESS:
      return {
        ...action.store,
      };
    default:
      return state;
  }
}
