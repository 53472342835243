import _ from 'lodash';
import React, { Component } from 'react';
import { IonIcon } from '@ionic/react';
import { searchSharp, closeSharp } from 'ionicons/icons';
import Autosuggest from 'react-autosuggest';

import { fetchApi } from '$gbusiness/services/api';
import { Wrapper } from './styles';
import { clog } from '$ghelpers/util';

interface AutoCompleteProps {
  AC: any;
  endpoint: string;
  history?: any;
  width?: string;
  height?: string;
  placeholder?: string;
  throttle?: number;
}

class Autocomplete extends Component<AutoCompleteProps> {
  state = {
    value: '',
    suggestions: [],
  };

  static defaultProps: AutoCompleteProps = {
    AC: {},
    endpoint: '',
    width: 'auto',
    height: '42px',
    placeholder: '',
    throttle: 300,
  };

  handleKeyPress = (event, suggestion) => {
    clog(event, suggestion);
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSelect = suggestion => {
    const { AC, history: h } = this.props;
    if (AC.onSelect) AC.onSelect(suggestion, h);
  };

  renderSuggestion = suggestion => (
    <div
      onClick={() => {
        this.onSelect(suggestion);
      }}
      onKeyPress={e => {
        this.handleKeyPress(e, suggestion);
      }}>
      {this.props.AC.renderSuggestion(suggestion)}
    </div>
  );

  getSuggestionValue = e => {
    const { AC } = this.props;
    if (AC.selectedValue) return AC.selectedValue(e);
    return '';
  };

  loadSuggestions = async value => {
    const response = await fetchApi({
      url: this.props.endpoint,
      param: {
        q: value,
      },
      isPublic: true,
      mockData: false,
    });

    this.setState({
      suggestions: response.list || [],
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.debouncedLoadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  onClearInput = () => {
    this.setState({ value: '' });
  };

  onBlurSelect = (e, { highlightedSuggestion: item }) => {
    if (item && this.onSelect) this.onSelect(item);
  };

  debouncedLoadSuggestions = _.debounce(this.loadSuggestions, this.props.throttle || 300);

  render() {
    const { value, suggestions } = this.state;
    const { height, width, placeholder } = this.props;

    const inputProps = {
      placeholder,
      value,
      onChange: this.onChange,
      onBlur: this.onBlurSelect,
    };

    return (
      <>
        <Wrapper height={height} width={width} className="ac-wrapper">
          <IonIcon icon={searchSharp} slot="start" />
          <Autosuggest
            placeholder={placeholder}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
          />
          {inputProps.value !== '' && (
            <button onClick={this.onClearInput}>
              <IonIcon icon={closeSharp} className="clear-button" />
            </button>
          )}
        </Wrapper>
      </>
    );
  }
}

export default Autocomplete;
