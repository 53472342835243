import { dialog } from '$gcomponents/reusables';
import intl from '$gintl';
import { AMEX, DISCOVER, MASTERCARD, VISA } from '../enums/cardTypes';
import { ASC, DSC } from '../enums/sort';

export const clog = (...items) => {
  if (process.env.REACT_APP_ENV !== 'prod') {
    console.log(...items);
  }
};

export const sleep = m => new Promise(r => setTimeout(r, m));

export const changeSort = order => (order === DSC ? ASC : DSC);

export const intToArray = number =>
  Array(number)
    .fill(1)
    .map((v, i) => i);

export const flattenObject = obj => {
  return Object.assign(
    {},
    ...(function _flatten(o) {
      return [].concat(
        ...Object.keys(o).map(k => (typeof o[k] === 'object' ? _flatten(o[k]) : { [k]: o[k] })),
      );
    })(obj),
  );
};

export const popPlaceholder = (text = '') => {
  dialog.alert({
    title: 'MESSAGE.SORRY',
    message: 'MESSAGE.FEATURE_NOT_READY',
    ...(text && { text }),
    key: { name: 'Supercalifrgerlisticexplialidocous' },
  });
};

export const mirrorObjKeys = obj => {
  const newObj = {};
  for (let i in obj) {
    newObj[i] = i;
  }
  return newObj;
};

export const getStyleColor = (color, colorType = '') => {
  return `var(--ion-color-${color}${colorType ? `-${colorType}` : ''})`;
};

export const addMinutesToTimestamp = (seconds, timestamp = 0) => {
  const now = timestamp || +new Date();

  return now + seconds * 1000;
};

export const getLast4 = str => {
  if (!str) return '';
  if (str.length > 4) return str.toString().substr(-4);
  return str;
};

export const getCardType = str => {
  if (!str) return '';
  switch (str[0] || '') {
    case '3':
      return AMEX;
    case '4':
      return VISA;
    case '5':
      return MASTERCARD;
    case '6':
      return DISCOVER;
    default:
      return '';
  }
};

export const randomInt = (min, max) => {
  return parseInt(Math.floor(Math.random() * (max - min + 1)) + min);
};

export const currency = amount => intl('PRICE', { amount });

export const dollar = (amount, useDecimal = true) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...(!useDecimal && { maximumFractionDigits: 0 }),
  });

  return formatter.format(amount);
};

export const getCardPathFromType = cardType => {
  return `/assets/img/${cardType.toLowerCase()}_icon.png`;
};

export const getDefaultCardId = cards => {
  const card = cards.find(c => c.isDefault);
  if (!card) return 0;
  return card.cardId;
};

export const getCardPath = cardNumber => {
  let fileName;
  switch (cardNumber[0] || '') {
    case '3':
      fileName = 'amex';
      break;
    case '4':
      fileName = 'visa';
      break;
    case '5':
      fileName = 'mastercard';
      break;
    case '6':
      fileName = 'discover';
      break;
    default:
      fileName = 'card';
      break;
  }
  return `/assets/img/${fileName}_icon.png`;
};
