import { Dispatch } from 'redux';
import {
  changeField,
  changeModifierField,
  calculateItemPrice,
  calculateItemPriceFromList,
  mergeObject,
  calculateCartPrice,
  getModifiersFromCart,
  generateDefaultItem,
} from './utils';

import { setStorageObj } from '$gbusiness/services/local.storage';
import { defaultCart } from '$business/models/cart';

// PRODUCTS
export function addProduct(cartItem, defaultItem) {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const products = cart.products.filter(p => p.isAdded);

    const newItem = defaultItem ? { ...defaultItem, isAdded: true } : generateDefaultItem(cartItem);
    const pricedItem = calculateItemPrice(newItem);

    const newCart = {
      ...cart,
      pointer: {
        productIndex: defaultItem ? products.length + 1 : products.length,
        modifierIndex: 0,
      },
      products: [...products, pricedItem],
    };
    await setStorageObj('cart', defaultItem !== null ? calculateCartPrice(newCart) : newCart, 'ADD_PRODUCT');
  };
}

export function modifyProduct(productIndex, cartItem) {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newProducts = cart.products.map((p, i) => {
      if (i !== productIndex) return p;
      return cartItem;
    });
    const newCart = {
      ...cart,
      products: newProducts,
    };
    setStorageObj('cart', calculateCartPrice(newCart), 'MODIFY_PRODUCT');
  };
}

export function removeProduct(productIndex) {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newProducts = cart.products.filter((_, i) => i !== productIndex);

    // If pointer is greater than the product index then adjust the pointer
    const {
      pointer: { productIndex: pIndex },
    } = cart;
    const newPointer =
      pIndex > productIndex && pIndex > 0 ? { ...cart.pointer, productIndex: pIndex - 1 } : cart.pointer;

    const newCart = {
      ...cart,
      products: newProducts,
      pointer: newPointer,
    };

    setStorageObj('cart', calculateCartPrice(newCart), 'REMOVE_PRODUCT');
  };
}

export function finishProduct(productIndex = -1, instruction) {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const pricedItem = calculateItemPriceFromList(cart.products);

    const newCart = {
      ...cart,
      products: mergeObject(
        cart.products,
        {
          ...pricedItem,
          isAdded: true,
        },
        productIndex,
      ),
    };

    await setStorageObj('cart', calculateCartPrice(newCart), 'FINISH_PRODUCT');
  };
}

export function changeProductQuantity(index, quantity) {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const products = changeField(cart.products, index, 'quantity', quantity);
    const newCart = {
      ...cart,
      products,
    };
    setStorageObj('cart', newCart, 'CHANGE_PRODUCT_QUANTITY');
  };
}

export function changeInstruction(index, text) {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const products = changeField(cart.products, index, 'instruction', text);
    const newCart = {
      ...cart,
      products,
    };
    setStorageObj('cart', newCart, 'CHANGE_PRODUCT_INSTRUCTION');
  };
}

export function cleanProducts(changePointer = false) {
  return (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const products = cart.products.filter(p => p.isAdded);
    const newCart = {
      ...cart,
      products,
      ...(changePointer && { pointer: { ...cart.pointer, productIndex: products.length } }),
    };
    setStorageObj('cart', newCart, 'CLEAN_PRODUCT');
  };
}

// MODIFIER
export function addModifiers(
  modifiers,
  productIndex,
  modifierSetIndex,
  clearOthers = false,
  split: any = {},
) {
  return async (dispatch: Dispatch, getState) => {
    const hasSplit = split.splits?.length;
    const cart = getState().localStorage.cart;
    const pindex = productIndex >= 0 ? productIndex : cart.products.length - 1;
    const newModifiers = modifiers.map(m => ({
      modifierSetIndex,
      modifierIndex: m.index,
      modifierName: m.modifierName,
      label: m.label,
      quantity: 1,
      ...(hasSplit > 0 && { splits: split.splits }),
      price: {
        original: m.price,
        ...(m.price2 && { price2: m.price2 }),
        ...(m.price2 && { price3: m.price3 }),
      },
    }));
    const products = cart.products.map((p, i) => {
      if (i !== pindex) return p;
      const existingModifiers = clearOthers
        ? p.modifiers.filter(m => !(m.modifierSetIndex === modifierSetIndex))
        : p.modifiers;
      return {
        ...p,
        modifiers: [...existingModifiers, ...newModifiers],
      };
    });
    const newCart = {
      ...cart,
      products,
    };
    setStorageObj('cart', newCart, 'ADD_MODIIFER');
  };
}

export function removeModifierSet(productIndex, modifierSetIndex) {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const modifiers = getModifiersFromCart(cart, productIndex);
    const newModifiers = modifiers.filter(m => !(m.modifierSetIndex === modifierSetIndex));
    const products = changeField(cart.products, productIndex, 'modifiers', newModifiers);
    const newCart = {
      ...cart,
      products,
    };
    setStorageObj('cart', newCart, 'REMOVE_MODIFIER_SET');
  };
}

export function removeModifier(productIndex, modifierSetIndex, modifierIndex) {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const modifiers = getModifiersFromCart(cart, productIndex);
    const newModifiers = modifiers.filter(
      m => !(m.modifierSetIndex === modifierSetIndex && m.modifierIndex === modifierIndex),
    );
    const products = changeField(cart.products, productIndex, 'modifiers', newModifiers);
    const newCart = {
      ...cart,
      products,
    };
    setStorageObj('cart', newCart, 'REMOVE_MODIFIER');
  };
}

export function changeModifierQuantity(quantity, productIndex, modifierSetIndex, modifierIndex) {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const modifiers = getModifiersFromCart(cart, productIndex);
    const newModifiers = changeModifierField(
      modifiers,
      modifierSetIndex,
      modifierIndex,
      'quantity',
      quantity,
    );
    const products = changeField(cart.products, productIndex, 'modifiers', newModifiers);
    const newCart = {
      ...cart,
      products,
    };
    setStorageObj('cart', newCart, 'CHANGE_MODIFIER_QUANTITY');
  };
}

// POINTER
export function changePointer(productId, productIndex, modifierIndex) {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const pointer = {
      ...cart.pointer,
      ...(productIndex !== null && { productIndex }),
      ...(modifierIndex !== null && { modifierIndex }),
    };
    const newCart = {
      ...cart,
      pointer,
    };
    await setStorageObj('cart', newCart, 'CHANGE_POINTER');
  };
}

export function resetPointer() {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newCart = {
      ...cart,
      pointer: defaultCart.pointer,
    };

    await setStorageObj('cart', newCart, 'RESET_POINTER');
  };
}

export function changeOrder(param) {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newCart = {
      ...cart,
      order: {
        ...cart.order,
        ...param,
      },
    };

    await setStorageObj('cart', newCart, 'CHANGE_ORDER');
  };
}

export function changeTip(tip) {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newCart = {
      ...cart,
      price: {
        ...cart.price,
        tip,
      },
    };
    setStorageObj('cart', calculateCartPrice(newCart), 'REMOVE_PRODUCT');
  };
}

export function changeDelivery(delivery) {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newCart = {
      ...cart,
      price: {
        ...cart.price,
        delivery,
      },
    };
    setStorageObj('cart', calculateCartPrice(newCart), 'REMOVE_PRODUCT');
  };
}

export function resetCart() {
  return async (dispatch: Dispatch, getState) => {
    await setStorageObj('cart', defaultCart, 'RESET_CART');
  };
}
