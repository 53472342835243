export default interface CardModel {
  cardId?: number;
  cardType: string;
  last4: string;
  expiry: string;
  cvv: string;
  cardHolder?: string;
  token?: string;
  isDefault?: boolean;
}

export const deriveRawToCard = raw => {
  return {
    cardId: raw.id,
    cardType: raw.cardType,
    last4: raw.cardNumber || raw.last4,
    expiry: raw.expiry,
    cvv: raw.cvc || '',
    cardHolder: raw.cardHolder,
    isDefault: !!raw.isDefault,
  };
};
