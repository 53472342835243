import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { Div, SPACE } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .title {
    display: flex;

    button {
      padding: 0;
      margin-right: ${SPACE.MEDIUM};
      color: var(--ion-text-color);
    }
  }
`;

export const MenuWrapper = styled(Div)`
  margin: ${SPACE.DEF};
`;
