import _ from 'lodash';

export const validateEmail = value => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);
};

export const validatePassword = value => {
  if (!value) return false;
  if (value.length < 6) return false;
  if (value.search(/[a-z]/i) < 0) return false;
  if (value.search(/[0-9]/) < 0) return false;
  return true;
};

export const generateInitialValues = (FORM, skipUndefined = false) => {
  const arr = {};
  FORM.forEach(row => {
    row.items.forEach(f => {
      if (f.defaultValue) arr[f.value] = f.defaultValue;
      else if (!skipUndefined) arr[f.value] = undefined;
    });
  });
  return arr;
};

export const deriveFormInitialValues = (FORM, item) => {
  if (!FORM || !item) return {};

  const fields = flattenForm(FORM);
  return fields.reduce((acc, input) => {
    const key = input.value;
    const fieldValue = _.get(item, key, null);
    _.set(acc, key, fieldValue);
    return acc;
  }, {});
};

export const flattenForm = (FORM, values: any = false) => {
  const arr: Array<any> = [];
  FORM.forEach(row => {
    if (values && row.isHidden && row.isHidden(values)) return;
    row.items.forEach(f => {
      if (values && f.isHidden && f.isHidden(values)) return;
      arr.push(f);
    });
  });
  return arr;
};

export const requiredValidation = {
  condition: v => v === '' || v === null || v === undefined,
  message: 'REQUIRED',
};

export const validateError = (FORM, values) => {
  const fields = flattenForm(FORM, values).filter(
    f => f.validation && (!f.validationCondition || f.validationCondition(values)),
  );

  return fields.reduce((acc, input) => {
    const key = input.value;
    const fieldValue = _.get(values, key, null);
    const failedValidation = input.validation.find(v => v.condition(fieldValue, values));
    if (failedValidation) {
      _.set(acc, key, failedValidation.message);
    }
    return acc;
  }, {});
};

export const getDiff = (object, base) => {
  function changes(object, base) {
    return _.transform(object, function(result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
};
