import React from 'react';
import { connect } from 'react-redux';
import { useIonViewDidEnter } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import { ContentWrapper, FlexIonContent, PageTitle } from '$styles';
import { Box, SPACE, Space } from '$gstyles';
import { cartActions } from '$business/redux/cart';

import FormComponent from './FormComponent';
import CartModel from '$business/models/cart';

import { IonPageWrapper } from './styles';
import StoreModel from '$business/models/store';
import { loadingActions } from '$gbusiness/redux/loading';
import intl from '$intl';
import UserModel from '$gbusiness/models/user';
import { menuActions } from '$business/redux/menu';
import { Modal } from '$gcomponents/reusables';
import ThankyouSection from './thankyouSection';
import { ROUTES } from '$business/enums';
import CardModel from '$gbusiness/models/card';
import { deriveStoreOpening } from '$business/helpers/util';
import BusinessHours from '$components/storeStatus/businessHours';
import { Button } from '$gcomponents/primitives';

interface CheckoutScreenProps {
  history: any;
  cleanProducts: Function;
  currentRoute: string;
  store: StoreModel;
  cart: CartModel;
  order: any;
  user: UserModel;
  cards: Array<CardModel>;
  changeTip: Function;
  changeDelivery: Function;
  submitOrder: (v) => void;
  cleanOrder: Function;
  resetCart: Function;
  showLoginModal: () => void;
}

const CheckoutScreen: React.FC<CheckoutScreenProps> = ({
  history,
  currentRoute,
  cleanProducts,
  changeTip,
  changeDelivery,
  submitOrder,
  cleanOrder,
  resetCart,
  showLoginModal,
  cart,
  cards,
  order,
  user,
  store,
}) => {
  useIonViewDidEnter(() => {
    cleanProducts();
  });

  const onCloseThankyou = () => {
    cleanOrder();
    resetCart();
    history.push(ROUTES.DEFAULT_PUBLIC_ROUTE_FULL);
  };

  const isStoreOpenNow = deriveStoreOpening(store);

  return (
    <IonPageWrapper>
      <FlexIonContent>
        <ContentWrapper>
          <PageTitle center>{intl('CHECKOUT.TITLE')}</PageTitle>
          <Space.Ver margin={SPACE.LARGE}>
            {isStoreOpenNow && (
              <FormComponent
                store={store}
                user={user}
                cards={cards}
                cart={cart}
                changeTip={changeTip}
                changeDelivery={changeDelivery}
                showLoginModal={showLoginModal}
                submitOrder={submitOrder}
              />
            )}
            {!isStoreOpenNow && (
              <>
                <h4>{intl('MESSAGE.STORE_CLOSED')}</h4>
                <Box.MEDIUM>
                  <h3>{intl('SCREEN.ABOUT_US.ONLINE_HOURS')}</h3>
                  <BusinessHours hours={store?.setting?.ohours} />
                  <br />
                  <br />
                  <Button onClick={() => history.goBack()} color="secondary">
                    {intl('COMMON.BACK')}
                  </Button>
                </Box.MEDIUM>
              </>
            )}
          </Space.Ver>
        </ContentWrapper>
      </FlexIonContent>
      <Modal
        show={!!order && currentRoute === ROUTES.CHECKOUT}
        title="SCREEN.THANK_YOU.TITLE"
        onClose={onCloseThankyou}>
        <ThankyouSection order={order} />
      </Modal>
    </IonPageWrapper>
  );
};

const mapStateToProps = state => ({
  currentRoute: state.routing.currentRoute,
  categories: state.menu.category,
  order: state.menu.order,
  store: state.store,
  user: state.currentUser,
  cart: state.localStorage.cart,
  cards: state.card.cards,
});

const mapDispatchToProps = {
  cleanProducts: cartActions.cleanProducts,
  changeTip: cartActions.changeTip,
  changeDelivery: cartActions.changeDelivery,
  resetCart: cartActions.resetCart,
  showLoginModal: loadingActions.showLoginModal,
  submitOrder: menuActions.submitOrder,
  cleanOrder: menuActions.cleanOrder,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(CheckoutScreen));
