import { KEYS as DELIVERY_METHOD } from '$business/enums/options/deliveryMethods';
import { intToSlot } from '$business/helpers/util';
import { DATE_FORMATS } from '$gbusiness/enums';
import { format } from '$ghelpers/date';

export const getDefaultPaymentMethod = (deliveryMethod, setting) => {
  return setting?.methodOptions?.forceAtCounter && deliveryMethod === DELIVERY_METHOD.IN_RESTAURANT
    ? 'OFFLINE'
    : 'ONLINE';
};

export const shouldHidePaymentOptions = (deliveryMethod, setting) => {
  const { payAtLocation, payAtCounter, payAtDelivery, forceAtCounter } = setting.methodOptions;
  const shouldPayAtLocation = payAtLocation && deliveryMethod === DELIVERY_METHOD.PICKUP;
  const shouldPayAtDelivery = payAtDelivery && deliveryMethod === DELIVERY_METHOD.DELIVERY;
  const shouldPayAtCounter = payAtCounter && deliveryMethod === DELIVERY_METHOD.IN_RESTAURANT;
  const shouldForceAtCounter = forceAtCounter && deliveryMethod === DELIVERY_METHOD.IN_RESTAURANT;
  return shouldForceAtCounter && !(shouldPayAtLocation || shouldPayAtCounter || shouldPayAtDelivery);
};

export const deriveVariables = (deliveryMethod, setting) => {
  const { pickupFutureDays, deliveryFutureDays, phours, dhours, ohours } = setting;

  let futureDays, hours;
  let dayLabel = 'INPUT.LABEL.PICKUP_DATE';
  let timeLabel = 'INPUT.LABEL.PICKUP_TIME';
  switch (deliveryMethod) {
    case DELIVERY_METHOD.PICKUP:
      futureDays = pickupFutureDays;
      hours = phours;
      break;
    case DELIVERY_METHOD.DELIVERY:
      futureDays = deliveryFutureDays;
      hours = dhours;
      dayLabel = 'INPUT.LABEL.DELIVERY_DATE';
      timeLabel = 'INPUT.LABEL.DELIVERY_TIME';
      break;
    default:
      futureDays = 0;
      hours = ohours;
      break;
  }
  return [futureDays, hours, dayLabel, timeLabel];
};

export const getMaxDeliveryRange = setting => {
  return setting.deliveryRadius3 || setting.deliveryRadius2 || setting.deliveryRadius1 || 0;
};

export const getDeliveryPrice = ({ miles, subtotal, setting }) => {
  const {
    freeDelivery,
    waiveDeliveryMin,
    deliveryRadius1,
    deliveryRadius2,
    deliveryRadius3,
    deliveryFee1,
    deliveryFee2,
    deliveryFee3,
  } = setting;
  if (freeDelivery) return 0;
  if (waiveDeliveryMin && waiveDeliveryMin <= subtotal) return 0;
  if (deliveryRadius3 && miles > deliveryRadius3) return -1;
  if (deliveryRadius2 && miles > deliveryRadius2) return deliveryFee3;
  if (deliveryRadius1 && miles > deliveryRadius1) return deliveryFee2;
  return deliveryFee1 ? deliveryFee1 : 0;
};

export const getStoreLocation = store => {
  const { place } = store;
  return {
    lat: place?.lat,
    lng: place?.lng,
  };
};

export const getDrivingDistance = (fromLocation, toLocation, cb) => {
  const googleMaps = new (window as any).google.maps();
  const service = googleMaps.DistanceMatrixService();

  service.getDistanceMatrix(
    {
      origins: [fromLocation],
      destinations: [toLocation],
      travelMode: googleMaps.TravelMode.DRIVING,
      unitSystem: googleMaps.UnitSystem.IMPERIAL,
      avoidHighways: true,
      avoidTolls: true,
    },
    function(response, status) {
      if (status === 'OK') {
        if (response.rows.length && response.rows[0].elements[0]) {
          cb({
            distance: response.rows[0].elements[0].distance,
            duration: response.rows[0].elements[0].duration,
          });
          return;
        }
      }
      cb(false);
    },
  );
};

export const deriveDeliveryTime = values => {
  const { deliveryDate, deliveryTime } = values;
  const time = deliveryTime >= 0 ? intToSlot(deliveryTime) : 'ASAP';
  const date = deliveryDate ? ' ' + format(deliveryDate, DATE_FORMATS.MONTH_DAY) : '';
  return time + date;
};
