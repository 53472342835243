export default interface SplitModel {
  index: number;
  splitId: number;
  splitName: string;
  splitName2?: string;
  label?: string;
  hasPrice: boolean;
  price?: number;
  price2?: number;
  price3?: number;
}

export const deriveSplits = (raw, rawSplitSet) => {
  if (!raw) return [];

  return raw.reduce((acc, split, i) => {
    if (split.hide) return acc;

    acc.push(deriveRawToSplit(split, i, rawSplitSet));

    return acc;
  }, []);
};

export const deriveRawToSplit = (rawSplit, index, rawSplitSet) => {
  if (!rawSplit) return undefined;

  const { id: splitId, n: splitName, n2: splitName2, p: price, p2: price2, p3: price3 } = rawSplit;

  const split: SplitModel = {
    index,
    splitId,
    splitName,
    splitName2,
    label: rawSplitSet?.name || '',
    hasPrice: price !== undefined || price2 !== undefined || price3 !== undefined,
    price,
    price2,
    price3,
  };

  return split;
};
