import React from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { useTheme } from '@material-ui/core';

import { getBoxSize } from '$business/helpers/util';
import ModifierSetModel from '$business/models/modifierSet';
import { PointerModel } from '$business/models/cart';
import CartItemModel, { CartModifierModel } from '$business/models/cartItem';
import { MenuContainerWrapper } from '$styles';

import ModifierBox from '../ModifierBox';
import QuantityInput from '../QuantityInput';

interface ProductContainerProps {
  currentItem: CartItemModel;
  modifierSet?: ModifierSetModel;
  pointer: PointerModel;
  selections: Array<CartModifierModel>;
  setting: any;
}

const ProductContainer: React.FC<ProductContainerProps> = ({
  modifierSet,
  currentItem,
  pointer,
  selections,
  setting,
}) => {
  const theme = useTheme();
  const screenWidth = useWindowWidth();

  if (!modifierSet) {
    const defaultQuantity = currentItem?.quantity || 1;
    const defaultInstructionText = currentItem?.instruction || '';
    return (
      <>
        <QuantityInput
          value={defaultQuantity}
          defaultInstruction={defaultInstructionText}
          productIndex={pointer.productIndex}
          setting={setting}
        />
      </>
    );
  }

  if (!currentItem) return null;

  const size = getBoxSize(modifierSet?.itemsPerRow, screenWidth, theme.breakpoints);
  const content = modifierSet.modifiers?.map((m, index) => {
    return (
      <ModifierBox
        productId={currentItem.productId}
        key={m.modifierId}
        modifierSet={modifierSet}
        pointer={pointer}
        index={index}
        selections={selections}
        modifier={m}
        size={size}
        setting={setting}
      />
    );
  });

  return <MenuContainerWrapper>{content}</MenuContainerWrapper>;
};

export default ProductContainer;
