import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useIonViewDidEnter } from '@ionic/react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { screen } from '$gcomponents/hoc';
import { menuActions, cartActions } from '$business/redux/actions';
import { Font } from '$gstyles';
import { ContentWrapper, FlexIonContent, PageTitle } from '$styles';
import CategoryModel from '$business/models/category';
import DisplaySecond, { getSecondLan } from '$components/helpers/displaySecond';
import { ROUTES } from '$business/enums';
import { IconButton } from '@material-ui/core';
import { Flex } from '$gstyles/wrapper';

import { IonPageWrapper, MenuWrapper } from './styles';
import CategorySection from './categorySection';
import { Modal } from '$gcomponents/reusables';
import ProductModel from '$business/models/product';
import ModifierModalSection from './modifierModalSection';
import { generateDefaultItem } from '$business/redux/cart/utils';
import CartModel from '$business/models/cart';

interface HomeScreenProps {
  onHydrate;
  onDehydrate;
  setting;
  product?: ProductModel;
  category: CategoryModel;
  addToCart: Function;
  modifyProduct: Function;
  cleanProducts: Function;
  dehydrateProduct: Function;
  cart: CartModel;
}

const HomeScreen: React.FC<HomeScreenProps> = ({
  category,
  product,
  setting,
  addToCart,
  modifyProduct,
  cleanProducts,
  dehydrateProduct,
  cart,
}) => {
  useIonViewDidEnter(() => {
    // cleanProducts(true);
  });

  const history = useHistory();
  if (!category) return <IonPageWrapper />;
  const { categories } = category;
  const hasParent = !!category.parentId;
  const {
    pointer: { productIndex },
    products: cartItems,
  } = cart;

  const handleBack = () => {
    if (!category?.parentId) history.goBack();
    else history.push(`${ROUTES.CATEGORIES}/${category.parentId}`);
  };
  const onCloseModal = () => {
    dehydrateProduct();
    cleanProducts(true);
  };
  const handleCartAdd = async cartItem => {
    if (!product) return;

    if (productIndex < cartItems.length) {
      await modifyProduct(productIndex, cartItem);
    } else {
      await addToCart(product, cartItem);
    }
    cleanProducts(true);
    onCloseModal();
  };

  const renderBackButton = () => {
    if (!hasParent) return null;
    return (
      <IconButton onClick={handleBack}>
        <ArrowBackIcon />
      </IconButton>
    );
  };

  const defaultItem = !product
    ? undefined
    : productIndex < cartItems.length
    ? cartItems[productIndex]
    : generateDefaultItem(product);

  return (
    <IonPageWrapper>
      <FlexIonContent>
        <ContentWrapper maxWidth="1000px">
          <PageTitle>
            <Flex className="title">
              {renderBackButton()}
              <Font.LARGE weight>
                <DisplaySecond obj={category} prop="categoryName" display2nd={setting.displaySecond} />
              </Font.LARGE>
            </Flex>
          </PageTitle>
          <MenuWrapper>
            {categories?.length &&
              categories.map(category => (
                <CategorySection key={category.categoryId} category={category} setting={setting} />
              ))}
          </MenuWrapper>
        </ContentWrapper>
      </FlexIonContent>
      <Modal
        show={!!product}
        onClose={onCloseModal}
        titleText={getSecondLan(product, 'productName', setting.displaySecond)}
        useCustom>
        <ModifierModalSection
          defaultItem={defaultItem}
          product={product}
          setting={setting}
          onClose={onCloseModal}
          onCartAdd={handleCartAdd}
        />
      </Modal>
    </IonPageWrapper>
  );
};

const mapStateToProps = state => ({
  resetOnRoute: true,
  product: state.menu.product,
  category: state.menu.category,
  setting: state.store.setting,
  cart: state.localStorage.cart,
});

const mapDispatchToProps = {
  cleanProducts: cartActions.cleanProducts,
  addToCart: cartActions.addProduct,
  modifyProduct: cartActions.modifyProduct,
  dehydrateProduct: menuActions.dehydrateProduct,
  onHydrate: params => menuActions.fetchMenu(params.categoryId),
  onDehydrate: menuActions.dehydrateCategories,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(HomeScreen));
