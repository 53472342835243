import React from 'react';
import { useDispatch } from 'react-redux';
import ProductModel from '$business/models/product';

import { Wrapper } from './styles';
import ImageBox from './imageBox';
import { configs } from '$configs';
import { menuActions } from '$business/redux/menu';
import { currency } from '$ghelpers/util';
import DisplaySecond from '$components/helpers/displaySecond';
import { SettingModel } from '$business/models/store';
import intl from '$intl';

const productHeight = configs.display.productHeight;

interface ProductSectionProps {
  product: ProductModel;
  setting: SettingModel;
}

const ProductSection: React.FC<ProductSectionProps> = ({ product, setting }) => {
  const dispatch = useDispatch();
  if (!product) return null;

  const {
    display: { hasImage, image, isGlutenFree, isSpicy, isVegan, shouldMarkNew },
    description,
    productId,
    price,
  } = product;

  const handleClick = () => {
    if (product?.hasModifiers) dispatch(menuActions.fetchProduct(productId, false, true));
    else dispatch(menuActions.getProduct(product));
  };

  return (
    <>
      <Wrapper height={productHeight} className={`pointer ${hasImage && 'has-image'}`} onClick={handleClick}>
        <div className="text">
          <div className="flex">
            <div className="product-name">
              <DisplaySecond obj={product} prop="productName" display2nd={setting.displaySecond} />
            </div>
            <div className="price">{currency(price)}</div>
            <div className="flags">
              {isSpicy && <span className="hot" />}
              {isGlutenFree && <span className="gf" />}
              {isVegan && <span className="vegan" />}
            </div>
          </div>
          <div className="description">{description}</div>
        </div>
        {hasImage && <ImageBox src={image} size={productHeight} />}
      </Wrapper>
      {shouldMarkNew && <div className="is-new">{intl('COMMON.NEW')}</div>}
    </>
  );
};

export default ProductSection;
