import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';

import { IonPageWrapper } from './styles';
import { ContentWrapper, PageTitle } from '$styles';
import { Div } from '$gstyles';
import { phone as formatPhone } from '$ghelpers/mask';
import intl from '$intl';
import UserModel from '$gbusiness/models/user';
import { Form, Formik } from 'formik';
import { FormSection } from '$gcomponents/reusables';
import { Button, Text } from '$gcomponents/primitives';
import { input } from '$ghelpers';

import { PROFILE_FORM } from './profileForm';
import { cuserActions } from '$gbusiness/redux/currentUser';
import CardList from '$gcomponents/widgets/cardFormModal/cardList';
import CardModel from '$gbusiness/models/card';
import { cardActions } from '$gbusiness/redux/card';
import { Divider } from '@material-ui/core';

interface ProfileScreenProps {
  user: UserModel;
  cards: Array<CardModel>;
  editProfile: Function;
  deleteCard: Function;
  setCardDefault: Function;
}

const ProfileScreen: React.FC<ProfileScreenProps> = ({
  user,
  cards,
  editProfile,
  deleteCard,
  setCardDefault,
}) => {
  const formRef = useRef<any>();
  const cardIndex = cards.findIndex(c => c.isDefault);

  const { userId, firstName, lastName, email, other } = user;

  const onSubmit = values => {
    editProfile(values);
  };
  const inititalValues = {
    userId,
    firstName,
    lastName,
    email,
    mobile: formatPhone((other?.mobile || '').toString()),
  };
  const handleCardSelect = index => {
    setCardDefault(cards[index]?.cardId);
  };

  const validateForm = values => input.validateError(PROFILE_FORM(formRef.current.values), values);

  return (
    <IonPageWrapper>
      <IonContent>
        <ContentWrapper>
          <PageTitle center>{intl('SCREEN.PROFILE.TITLE')}</PageTitle>
          <Formik
            innerRef={formRef}
            enableReinitialize={true}
            initialValues={inititalValues}
            validate={validateForm}
            onSubmit={values => {
              onSubmit(values);
            }}>
            {formik => (
              <Div>
                <Form>
                  <FormSection
                    title="SCREEN.FORGOT.SUBTITLE"
                    FORM={PROFILE_FORM(formik.values)}
                    formik={formik}
                  />
                  <Button
                    className="filled primary submit"
                    onClick={formik.handleSubmit}
                    disabled={!(formik.isValid && formik.dirty)}>
                    <Text k="SCREEN.PROFILE.SUBMIT_BUTTON" />
                  </Button>
                </Form>
              </Div>
            )}
          </Formik>
          <Divider />
          <CardList
            title="SCREEN.PROFILE.CARDS_TITLE"
            cardIndex={cardIndex}
            cards={cards}
            onDeleteCard={cardId => deleteCard(cardId)}
            onCardSelect={handleCardSelect}
          />
        </ContentWrapper>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = state => ({
  user: state.currentUser,
  cards: state.card.cards,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  editProfile: cuserActions.editProfile,
  deleteCard: cardActions.deleteCard,
  setCardDefault: cardActions.setCardDefault,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ProfileScreen));
