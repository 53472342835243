import React from 'react';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { checkmarkOutline } from 'ionicons/icons';

import ModifierModel from '$business/models/modifier';
import { SettingModel } from '$business/models/store';
import { Wrapper, IconWrapper } from './styles';
import { currency } from '$ghelpers/util';
import ModifierSetModel from '$business/models/modifierSet';
import { IonIcon } from '@ionic/react';
import { CartModifierModel } from '$business/models/cartItem';
import DisplaySecond from '$components/helpers/displaySecond';
import { deriveModifierPrice } from '$business/redux/cart/utils';

interface ModifierSectionProps {
  index: number;
  msIndex: number;
  modifier: ModifierModel;
  modifierSet: ModifierSetModel;
  setting: SettingModel;
  selection?: Array<CartModifierModel>;
  onChange: Function;
}

const setNumberOfChecks = (selection: CartModifierModel | undefined, maxChecks: number) => {
  if (!selection) return 1;
  const { quantity } = selection;
  return quantity >= maxChecks ? 0 : quantity + 1;
};

const ModifierSection: React.FC<ModifierSectionProps> = ({
  index: modifierIndex,
  msIndex: modifierSetIndex,
  modifier,
  modifierSet,
  setting,
  selection: selections = [],
  onChange,
}) => {
  if (!modifier) return null;

  const { isMultiple, isOptional, maxChecks, maxModifiers, modifierSetName: label } = modifierSet;
  const {
    hasImage,
    hasPrice,
    image,
    modifierName: title,
    modifierName2: title2,
    price,
    price2,
    price3,
  } = modifier;
  const selection = selections.find(
    s => s.modifierIndex === modifierIndex && s.modifierSetIndex === modifierSetIndex,
  );
  const numChecks = selection?.quantity || 0;

  const generateNewModifer = () => ({
    modifierSetIndex,
    modifierIndex,
    modifierName: title,
    label,
    quantity: 1,
    price: {
      original: price,
      ...(price2 && { price2 }),
      ...(price2 && { price3 }),
    },
  });

  const generateNewSelections = (obj, isNew = false, isRadio = false) => {
    if (isRadio && obj) return [...selections.filter(s => s.modifierSetIndex !== modifierSetIndex), obj];
    if (isRadio && !obj) return selections;
    if (!obj)
      return selections.filter(
        s => s.modifierIndex !== modifierIndex || s.modifierSetIndex !== modifierSetIndex,
      );

    if (isNew) return [...selections, obj];
    return selections.map(s => {
      if (s.modifierIndex !== modifierIndex || s.modifierSetIndex !== modifierSetIndex) return s;
      return obj;
    });
  };

  const onClick = () => {
    // Not doing split yet
    if (isMultiple) {
      const quantity = setNumberOfChecks(selection, maxChecks || 1);
      if (maxModifiers && quantity > maxModifiers) {
        alert('Maximum has reached');
        return;
      }
      if (!quantity) onChange(generateNewSelections(null));
      else if (!selection) onChange(generateNewSelections(generateNewModifer(), true));
      else if (selection) onChange(generateNewSelections({ ...selection, quantity }, false));
    } else {
      if (!selection || !selection.quantity) {
        onChange(generateNewSelections(generateNewModifer(), true, !isOptional));
      } else {
        onChange(generateNewSelections(null, false, !isOptional));
      }
    }
  };

  const checks = Array(numChecks)
    .fill(1)
    .map((m, i) => (
      <IconWrapper margin={`${i * 2}px ${i * 7}px`} key={i} className="pointer" onClick={onClick}>
        <IonIcon icon={checkmarkOutline} />
      </IconWrapper>
    ));

  const calculatedPrice = deriveModifierPrice(selection);
  const CheckboxIcon = isMultiple ? CheckBoxOutlineBlankIcon : RadioButtonUncheckedIcon;

  return (
    <Wrapper className="flex modifier">
      <div className="check">
        <CheckboxIcon className="check-icon pointer" onClick={onClick} />
        {checks}
      </div>
      <div className="name">
        <DisplaySecond obj={{ title, title2 }} prop="title" display2nd={setting.displaySecond} />
      </div>
      <div className="price">
        {calculatedPrice > 0 ? currency(calculatedPrice) : hasPrice ? currency(price) : ''}
      </div>
    </Wrapper>
  );
};

export default ModifierSection;
