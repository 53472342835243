export default interface UserModel {
  userId?: number;
  firstName: string;
  lastName: string;
  email: string;
  other?: any;
}

export const emptyUser: UserModel = {
  userId: 0,
  firstName: '',
  lastName: '',
  email: '',
};

export interface LoginModel {
  email: string;
  password: string;
  remember: boolean;
}

export interface RegisterModel {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export const defaultUser = {
  userId: 0,
  firstName: '',
  lastName: '',
  email: '',
};

export const deriveRawToUser = raw => {
  if (!raw) return defaultUser;

  const { firstName, lastName, email, userId, id, phone, mobile, token, other } = raw;

  return {
    userId: userId || id,
    firstName,
    lastName,
    email,
    other: {
      ...other,
      mobile: phone || mobile || '',
      token: token || '',
    },
  };
};
