import { IonContent, IonFooter } from '@ionic/react';
import { connect } from 'react-redux';
import { menuController } from '@ionic/core';
import React, { useEffect, useRef } from 'react';

import CartModel, { PointerModel } from '$business/models/cart';
import { Div } from '$gstyles';

import List from './list';
import { Wrapper, Content } from './styles';
import { cartActions } from '$business/redux/cart';
import { ROUTES } from '$business/enums';
import { useHistory } from 'react-router';
import { Button } from '$gcomponents/primitives';
import intl from '$intl';
import { SettingModel } from '$business/models/store';
import { dollar, sleep } from '$ghelpers/util';
import { dialog } from '$gcomponents/reusables';
import { menuActions } from '$business/redux/menu';

interface ReceiptProps {
  cart: CartModel;
  setting: SettingModel;
  isLoggedIn: boolean;
  currentRoute: string;
  changePointer: Function;
  removeProduct: Function;
  cleanProducts: Function;
  clearCart: Function;
  fetchProduct: Function;
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Receipt: React.FC<ReceiptProps> = ({
  cart,
  setting,
  isLoggedIn,
  changePointer,
  currentRoute,
  removeProduct,
  cleanProducts,
  clearCart,
  fetchProduct,
}) => {
  const history = useHistory();
  const prevPointer: PointerModel | undefined = usePrevious({ ...cart.pointer });
  const { freeDelivery, waiveDeliveryMin, singlePage } = setting;
  const defaultRoute = singlePage ? ROUTES.ROUTE_HOME : ROUTES.ROUTE_CATEGORIES;
  const redirectRoute = defaultRoute.path + (singlePage ? '' : defaultRoute.defaultId);

  const onEditItem = async index => {
    const { productId } = cart.products[index];
    await changePointer(productId, index, 0);

    if (singlePage) {
      if (currentRoute === redirectRoute) {
        fetchProduct(productId);
        menuController.close();
      } else {
        history.push(redirectRoute);
        await sleep(1);
        fetchProduct(productId);
      }
      return;
    }
    history.push(redirectRoute);
    menuController.close();
  };

  const showCheckoutButton = cart.products?.length && currentRoute !== ROUTES.CHECKOUT;
  const isFreeDeliveryMin = !freeDelivery && (waiveDeliveryMin || 0) > 0;
  const isFreeDelivery = freeDelivery;

  const onClickClear = () => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'RECEIPT.CLEAR_WARNING',
      onPress: clearCart,
    });
  };
  const onCheckout = () => {
    history.push(`${ROUTES.DECISION}`);
    menuController.close();
  };

  // POINTER CHANGED
  useEffect(() => {
    if (singlePage) return;
    async function reload() {
      if (cart.pointer.productIndex >= cart.products.length) return;
      const currentItem = cart.products[cart.pointer.productIndex];
      if (!currentItem.productId) return;

      // If product pointer has changed (EDIT) and they happen to be the same product then clean
      if (prevPointer && cart.pointer.productIndex !== prevPointer.productIndex) {
        if (currentItem.productId === cart.products[prevPointer.productIndex]?.productId) cleanProducts();
      }
      history.push(`${ROUTES.PRODUCTS}/${currentItem?.productId}/${cart.pointer.modifierIndex}`);
    }
    if (!currentRoute.includes(ROUTES.PRODUCTS) && currentRoute.includes(ROUTES.CATEGORIES)) return;
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.pointer.productIndex, cart.pointer.modifierIndex]);

  const onDeleteItem = index => {
    removeProduct(index);
  };
  return (
    <Wrapper side="end" contentId="main">
      <IonContent>
        <Content>
          <Div className="header">
            <div className="title">{intl('RECEIPT.TITLE')}</div>
            {isFreeDelivery && <div className="delivery">{intl('RECEIPT.FREE_DELIVERY')}</div>}
            {isFreeDeliveryMin && (
              <div className="delivery waive">
                {intl('RECEIPT.FREE_WAIVE', { minimum: dollar(waiveDeliveryMin, false) })}
              </div>
            )}
          </Div>
          <List cart={cart} currentRoute={currentRoute} onDeleteItem={onDeleteItem} onEditItem={onEditItem} />
          {/* <div>{JSON.stringify(cart.pointer || {})}</div> */}
        </Content>
      </IonContent>
      {!!showCheckoutButton && (
        <IonFooter>
          <Button fullWidth variant="text" className="danger outlined" size="large" onClick={onClickClear}>
            {intl('RECEIPT.CLEAR_CART_BUTTON')}
          </Button>
          <Button fullWidth size="large" onClick={onCheckout}>
            {intl('RECEIPT.CHECKOUT_BUTTON')}
          </Button>
        </IonFooter>
      )}
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  cart: state.localStorage.cart,
  setting: state.store.setting,
});

const mapDispatchToProps = {
  clearCart: cartActions.resetCart,
  fetchProduct: menuActions.fetchProduct,
  changePointer: cartActions.changePointer,
  cleanProducts: cartActions.cleanProducts,
  removeProduct: cartActions.removeProduct,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(Receipt);
