export const BUSINESS_HOURS = [
  { label: 'Sunday', status: 0, start: 44, end: 84 },
  { label: 'Monday', status: 1, start: 44, end: 84 },
  { label: 'Tuesday', status: 1, start: 44, end: 84 },
  { label: 'Wednesday', status: 1, start: 44, end: 84 },
  { label: 'Thursday', status: 1, start: 44, end: 84 },
  { label: 'Friday', status: 1, start: 44, end: 84 },
  { label: 'Saturday', status: 1, start: 44, end: 84 },
];

export const HOURS_OF_DAY = [
  { value: 0, labelText: '12:00am' },
  { value: 1, labelText: '1:00am' },
  { value: 2, labelText: '2:00am' },
  { value: 3, labelText: '3:00am' },
  { value: 4, labelText: '4:00am' },
  { value: 5, labelText: '5:00am' },
  { value: 6, labelText: '6:00am' },
  { value: 7, labelText: '7:00am' },
  { value: 8, labelText: '8:00am' },
  { value: 9, labelText: '9:00am' },
  { value: 10, labelText: '10:00am' },
  { value: 11, labelText: '11:00am' },
  { value: 12, labelText: '12:00pm' },
  { value: 13, labelText: '1:00pm' },
  { value: 14, labelText: '2:00pm' },
  { value: 15, labelText: '3:00pm' },
  { value: 16, labelText: '4:00pm' },
  { value: 17, labelText: '5:00pm' },
  { value: 18, labelText: '6:00pm' },
  { value: 19, labelText: '7:00pm' },
  { value: 20, labelText: '8:00pm' },
  { value: 21, labelText: '9:00pm' },
  { value: 22, labelText: '10:00pm' },
  { value: 23, labelText: '11:00pm' },
];

export const TIME_SLOTS = [
  { value: 0, labelText: '12:00am' },
  { value: 1, labelText: '12:15am' },
  { value: 2, labelText: '12:30am' },
  { value: 3, labelText: '12:45am' },
  { value: 4, labelText: '01:00am' },
  { value: 5, labelText: '01:15am' },
  { value: 6, labelText: '01:30am' },
  { value: 7, labelText: '01:45am' },
  { value: 8, labelText: '02:00am' },
  { value: 9, labelText: '02:15am' },
  { value: 10, labelText: '02:30am' },
  { value: 11, labelText: '02:45am' },
  { value: 12, labelText: '03:00am' },
  { value: 13, labelText: '03:15am' },
  { value: 14, labelText: '03:30am' },
  { value: 15, labelText: '03:45am' },
  { value: 16, labelText: '04:00am' },
  { value: 17, labelText: '04:15am' },
  { value: 18, labelText: '04:30am' },
  { value: 19, labelText: '04:45am' },
  { value: 20, labelText: '05:00am' },
  { value: 21, labelText: '05:15am' },
  { value: 22, labelText: '05:30am' },
  { value: 23, labelText: '05:45am' },
  { value: 24, labelText: '06:00am' },
  { value: 25, labelText: '06:15am' },
  { value: 26, labelText: '06:30am' },
  { value: 27, labelText: '06:45am' },
  { value: 28, labelText: '07:00am' },
  { value: 29, labelText: '07:15am' },
  { value: 30, labelText: '07:30am' },
  { value: 31, labelText: '07:45am' },
  { value: 32, labelText: '08:00am' },
  { value: 33, labelText: '08:15am' },
  { value: 34, labelText: '08:30am' },
  { value: 35, labelText: '08:45am' },
  { value: 36, labelText: '09:00am' },
  { value: 37, labelText: '09:15am' },
  { value: 38, labelText: '09:30am' },
  { value: 39, labelText: '09:45am' },
  { value: 40, labelText: '10:00am' },
  { value: 41, labelText: '10:15am' },
  { value: 42, labelText: '10:30am' },
  { value: 43, labelText: '10:45am' },
  { value: 44, labelText: '11:00am' },
  { value: 45, labelText: '11:15am' },
  { value: 46, labelText: '11:30am' },
  { value: 47, labelText: '11:45am' },

  { value: 48, labelText: '12:00pm' },
  { value: 49, labelText: '12:15pm' },
  { value: 50, labelText: '12:30pm' },
  { value: 51, labelText: '12:45pm' },
  { value: 52, labelText: '01:00pm' },
  { value: 53, labelText: '01:15pm' },
  { value: 54, labelText: '01:30pm' },
  { value: 55, labelText: '01:45pm' },
  { value: 56, labelText: '02:00pm' },
  { value: 57, labelText: '02:15pm' },
  { value: 58, labelText: '02:30pm' },
  { value: 59, labelText: '02:45pm' },
  { value: 60, labelText: '03:00pm' },
  { value: 61, labelText: '03:15pm' },
  { value: 62, labelText: '03:30pm' },
  { value: 63, labelText: '03:45pm' },
  { value: 64, labelText: '04:00pm' },
  { value: 65, labelText: '04:15pm' },
  { value: 66, labelText: '04:30pm' },
  { value: 67, labelText: '04:45pm' },
  { value: 68, labelText: '05:00pm' },
  { value: 69, labelText: '05:15pm' },
  { value: 70, labelText: '05:30pm' },
  { value: 71, labelText: '05:45pm' },
  { value: 72, labelText: '06:00pm' },
  { value: 73, labelText: '06:15pm' },
  { value: 74, labelText: '06:30pm' },
  { value: 75, labelText: '06:45pm' },
  { value: 76, labelText: '07:00pm' },
  { value: 77, labelText: '07:15pm' },
  { value: 78, labelText: '07:30pm' },
  { value: 79, labelText: '07:45pm' },
  { value: 80, labelText: '08:00pm' },
  { value: 81, labelText: '08:15pm' },
  { value: 82, labelText: '08:30pm' },
  { value: 83, labelText: '08:45pm' },
  { value: 84, labelText: '09:00pm' },
  { value: 85, labelText: '09:15pm' },
  { value: 86, labelText: '09:30pm' },
  { value: 87, labelText: '09:45pm' },
  { value: 88, labelText: '10:00pm' },
  { value: 89, labelText: '10:15pm' },
  { value: 90, labelText: '10:30pm' },
  { value: 91, labelText: '10:45pm' },
  { value: 92, labelText: '11:00pm' },
  { value: 93, labelText: '11:15pm' },
  { value: 94, labelText: '11:30pm' },
  { value: 95, labelText: '11:45pm' },
  { value: 96, labelText: 'Midnight' },
];
