import _ from 'lodash';
import React from 'react';
import { FastField, Field } from 'formik';

interface HiddenInputProps {
  name: string;
  disableFastField?: boolean;
}

const HiddenInput: React.FC<HiddenInputProps> = ({ name = '', disableFastField = false }) => {
  const FormField = disableFastField ? Field : FastField;

  return (
    <>
      <FormField name={name}>
        {({ form }) => {
          return (
            <>
              <input type="hidden" name={name} value={_.get(form.values, name)} />
            </>
          );
        }}
      </FormField>
    </>
  );
};

export default HiddenInput;
