import React from 'react';
import { currency } from '$ghelpers/util';

import { PriceSectionWrapper } from './styles';

interface PriceSectionWrapper {
  price?: number;
  height: string;
}

const PriceSection: React.FC<PriceSectionWrapper> = ({ price, height }) => {
  if (!price) return null;

  return <PriceSectionWrapper height={height}>{currency(price)}</PriceSectionWrapper>;
};

export default PriceSection;
