import React from 'react';
import CartItemModel from '$business/models/cartItem';

import { currency } from '$ghelpers/util';

import { ItemWrapper, ItemImage, ItemProduct, ItemName, ItemPrice, ItemModifiers } from './styles';
import { MoreHoriz } from '@material-ui/icons';
import PopOver from '$gcomponents/reusables/popOver';
import { Inline } from '$gstyles';
import { Button } from '@material-ui/core';
import { deriveModifierPrice } from '$business/redux/cart/utils';
import { Img } from '$gcomponents/primitives/textInput/styles';
import { ROUTES } from '$business/enums';

interface ReceiptItemProps {
  currentRoute: string;
  item: CartItemModel;
  isEditing: boolean;
  onDelete: () => void;
  onEdit: () => void;
}

const ReceiptItem: React.FC<ReceiptItemProps> = ({ currentRoute, item, isEditing, onDelete, onEdit }) => {
  const itemNameText = `${item.productName} ${item?.quantity > 1 ? ` x${item.quantity}` : ''}`;
  const price = item.isAdded
    ? (item.price?.subtotal || item.price?.original || 0) * item.quantity
    : item.price?.original;

  const showOption = item.isAdded && !isEditing && currentRoute !== ROUTES.CHECKOUT;
  const hasAdditionalPrice = item.price.original !== price;

  return (
    <ItemWrapper isAdded={item.isAdded && !isEditing}>
      <ItemProduct className="line">
        <ItemName>
          <Inline>{itemNameText}</Inline>
        </ItemName>
        <ItemPrice>{currency(price)}</ItemPrice>
      </ItemProduct>
      {item.image && (
        <ItemImage className="image">
          <Img src={`${process.env.REACT_APP_IMAGE_BASE}/${item.image}`} />
        </ItemImage>
      )}
      {hasAdditionalPrice && (
        <ItemModifiers>
          <ItemName className="modifier">{`Base price`}</ItemName>
          <ItemPrice className="modifier">{currency(item.price.original)}</ItemPrice>
        </ItemModifiers>
      )}
      {item.modifiers.map((modifier, index) => {
        const modifierNameText = `${modifier.modifierName} ${
          modifier?.quantity > 1 ? ` x${modifier.quantity}` : ''
        }`;
        const modifierPrice = deriveModifierPrice(modifier, true);
        const renderSplit =
          modifier.splits?.length &&
          modifier.splits.map((split, j) => {
            return (
              <ItemModifiers key={j}>
                <ItemName className="split">
                  {split.splitName} {split.quantity > 1 ? ` x${split.quantity}` : ''}
                </ItemName>
                {split.price > 0 && <ItemPrice className="split">{currency(split.price)}</ItemPrice>}
              </ItemModifiers>
            );
          });
        return (
          <div key={index}>
            <ItemModifiers key={index}>
              <ItemName className="modifier">{modifierNameText}</ItemName>
              {modifierPrice > 0 && <ItemPrice className="modifier">{currency(modifierPrice)}</ItemPrice>}
            </ItemModifiers>
            {renderSplit}
          </div>
        );
      })}
      {item.instruction && (
        <ItemModifiers>
          <ItemName className="modifier">"{item.instruction}"</ItemName>
        </ItemModifiers>
      )}
      {showOption && (
        <div className="more-ellipsis">
          <PopOver component={<MoreHoriz className="pointer" />}>
            <>
              <Button onClick={onEdit}>Edit</Button>
              <Button onClick={onDelete}>Delete</Button>
            </>
          </PopOver>
        </div>
      )}
    </ItemWrapper>
  );
};

export default ReceiptItem;
