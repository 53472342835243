import React from 'react';
import { Col, Row } from '$gstyles';

import { Button, ButtonGroup } from '@material-ui/core';
import { TipWrapper, Amount, Percent } from '../styles';
import { SettingModel } from '$business/models/store';
import intl from '$intl';

interface TipSectionProps {
  formik: any;
  setting: SettingModel;
  subtotal: number;
  onSelect: Function;
  index?: number;
}

const calculateTip = (price, percent) => {
  return Math.round(percent * price) / 100;
};

const TipSection: React.FC<TipSectionProps> = ({ formik, setting, subtotal, onSelect, index }) => {
  const { tip1, tip2, tip3 } = setting;
  const buttonList = [
    {
      percent: tip1,
      amount: calculateTip(subtotal, setting.tip1),
    },
    {
      percent: tip2,
      amount: calculateTip(subtotal, setting.tip2),
    },
    {
      percent: tip3,
      amount: calculateTip(subtotal, setting.tip3),
    },
  ];

  return (
    <TipWrapper>
      <Row>
        <Col gridSize={8}>
          <ButtonGroup>
            {buttonList.map((item, i) => {
              return (
                <Button
                  key={i}
                  className={index === i ? 'selected' : ''}
                  onClick={() => onSelect(i, item.amount)}>
                  <Amount>{intl('PRICE', { amount: item.amount })}</Amount>
                  <Percent>{item.percent}%</Percent>
                </Button>
              );
            })}
            <Button className={index === -1 ? 'selected' : ''} onClick={() => onSelect(-1, null)}>
              <Amount>{intl('CHECKOUT.TIP.OTHER')}</Amount>
            </Button>
            <Button className="filled notnow" onClick={() => onSelect(-2, 0)}>
              <Amount>{intl('CHECKOUT.TIP.NOT_NOW')}</Amount>
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </TipWrapper>
  );
};

export default TipSection;
