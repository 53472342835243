import { Dispatch } from 'redux';

import { LOADING, LOADING_MODAL } from '$gbusiness/redux/loading/types';
import {
  CARD_FAILURE,
  LOAD_CARDS_SUCCESS,
  RESET_ALL,
  ADD_CARD_SUCCESS,
  RESET_STATUS,
  DELETE_CARD_SUCCESS,
  CardActionTypes,
  SET_DEFAULT_SUCCESS,
} from './types';
import { configs } from '$configs';
import { fetchApi } from '$gbusiness/services/api';
import { deriveRawToCard } from '../../models/card';
import { SET_TOASTER_DANGER, SET_TOASTER_SUCCESS } from '../toaster/types';

export function dispatchLoading(dispatch, key = '') {
  dispatch({
    type: LOADING,
    ...(key && { loadingText: key }),
  });
}

export function dispatchLoadingModal(dispatch, key = '') {
  dispatch({
    type: LOADING_MODAL,
    ...(key && { loadingText: key }),
  });
}

export function handleFail(dispatch, err, key, shouldToast = false) {
  const errorKey = key || 'ERROR.SERVER';
  dispatch({
    type: CARD_FAILURE,
    err: errorKey,
  });

  if (shouldToast) {
    dispatch({
      type: SET_TOASTER_DANGER,
      toast: { text: err, key: errorKey },
    });
  }
  // if (shouldToast) toast({ text: err, message: errorKey, color: COLORS.DANGER });
}

export function fetchCards(userId) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);

    const response = await fetchApi({
      url: configs.api.card.list,
      method: 'POST',
      param: {
        userId,
      },
    });

    if (!response || !response?.list) {
      handleFail(dispatch, response?.err, 'ERROR.FETCH_FAIL');
      return;
    }

    dispatch({
      type: LOAD_CARDS_SUCCESS,
      cards: response.list.map(c => deriveRawToCard(c)),
    });
  };
}

export function addCard(values) {
  return async (dispatch: Dispatch) => {
    dispatchLoadingModal(dispatch, 'PROGRESS.SUBMITTING');

    const response = await fetchApi({
      url: configs.api.card.create,
      method: 'POST',
      param: {
        ...values,
        cardNumber: values.cardNumber.replace(/ /g, ''),
      },
    });

    if (!response || !response?.success) {
      handleFail(dispatch, response?.err || response?.message, 'ERROR.ADD_CARD_FAIL', true);
      return;
    }

    dispatch({
      type: ADD_CARD_SUCCESS,
    });
    dispatch({
      type: SET_TOASTER_SUCCESS,
      toast: {
        key: 'MESSAGE.ADD_SUCCESS',
        autoClose: false,
      },
    });
  };
}

export function deleteCard(cardId) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.card.delete + cardId,
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleFail(dispatch, response?.err || response?.message, 'ERROR.ADD_CARD_FAIL', true);
      return;
    }

    dispatch({
      type: DELETE_CARD_SUCCESS,
      cardId: cardId,
    });
    dispatch({
      type: SET_TOASTER_SUCCESS,
      toast: {
        key: 'MESSAGE.DELETE_SUCCESS',
        autoClose: false,
      },
    });
  };
}

export function setCardDefault(cardId) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.card.setdefault,
      method: 'POST',
      param: {
        cardId,
      },
    });

    if (!response || !response?.success) {
      handleFail(dispatch, response?.err || response?.message, 'ERROR.SAVE', true);
      return;
    }

    dispatch({
      type: SET_DEFAULT_SUCCESS,
      cardId: cardId,
    });
    dispatch({
      type: SET_TOASTER_SUCCESS,
      toast: { key: 'MESSAGE.SET_DEFAULT_CARD' },
    });
  };
}

export function resetStatus(): CardActionTypes {
  return { type: RESET_STATUS };
}

export function dehydrate(): CardActionTypes {
  return { type: RESET_ALL };
}
