import {
  add,
  addMinutes,
  format as dateFormat,
  subDays,
  startOfToday,
  startOfWeek,
  startOfMonth,
  startOfYear,
  startOfYesterday,
  differenceInMinutes,
} from 'date-fns';

import BoxSizeModel from '$business/models/boxSize';
import { ITEM_BOX_MARGIN, ITEM_BOX_MARGIN_NUMBER } from '$business/enums/sizes';

import { dateToString } from '$ghelpers/date';
import { PERIODS } from '../enums';
import { DATE_FORMATS, SCREEN_SIZES } from '$gbusiness/enums';
import { intToArray } from '$ghelpers/util';
import { TIME_SLOTS } from '../enums/hours';
import intl from '$intl';

export const getDateRangeFromPeriod = (period, format = 'yyyy-MM-dd') => {
  const today = dateFormat(new Date(), format);

  switch (period) {
    case PERIODS.TODAY:
      return [dateFormat(new Date(), format), dateFormat(new Date(), format)];
    case PERIODS.YESTERDAY:
      const yesterday = dateFormat(startOfYesterday(), format);
      return [yesterday, yesterday];
    case PERIODS.LAST_7_DAYS:
      const last7 = dateFormat(subDays(new Date(), 7), format);
      return [last7, today];
    case PERIODS.LAST_30_DAYS:
      const last30 = dateFormat(subDays(new Date(), 30), format);
      return [last30, today];
    case PERIODS.LAST_365_DAYS:
      const last365 = dateFormat(subDays(new Date(), 365), format);
      return [last365, today];
    case PERIODS.THIS_WEEK:
      return [dateFormat(startOfWeek(new Date()), format), today];
    case PERIODS.THIS_MONTH:
      return [dateFormat(startOfMonth(new Date()), format), today];
    case PERIODS.THIS_YEAR:
      return [dateFormat(startOfYear(new Date()), format), today];
    default:
      return ['', today];
  }
};

export const getBoxSize = (defaultItemsPerRow, screenWidth, theme): BoxSizeModel => {
  let itemsPerRow = defaultItemsPerRow;
  let parentWidth = screenWidth;
  const { values } = theme;

  if (screenWidth < values[SCREEN_SIZES.SMALL]) {
    itemsPerRow = 1;
  } else if (screenWidth < 480) {
    itemsPerRow = 2;
  } else if (screenWidth < values[SCREEN_SIZES.MEDIUM]) {
    itemsPerRow = 3;
  } else if (screenWidth >= values[SCREEN_SIZES.LARGE]) {
    parentWidth = screenWidth - 320;
    const estimatedItemWidth = Math.floor(900 / itemsPerRow);
    itemsPerRow = Math.round(parentWidth / estimatedItemWidth);
  }

  const parentInnerWidth = parentWidth - (ITEM_BOX_MARGIN_NUMBER * parentWidth * 2) / 100;
  const marginHeight = (ITEM_BOX_MARGIN_NUMBER * parentInnerWidth) / 100;

  const width = `${Math.floor((100 / itemsPerRow - ITEM_BOX_MARGIN_NUMBER * 2) * 100) / 100}%`;
  const heightNumber =
    Math.floor(((parentInnerWidth - ITEM_BOX_MARGIN_NUMBER * 2) / itemsPerRow - marginHeight * 2) * 100) /
    100;
  const height = `${heightNumber}px`;

  return {
    width,
    height,
    heightNumber,
    marginWidth: ITEM_BOX_MARGIN,
    marginHeight: `${marginHeight}px`,
  };
};

export const getFutureDates = (days = 0) => {
  const now = new Date();
  const initialDates = [
    {
      value: '',
      labelText: intl('COMMON.TODAY'),
    },
  ];
  return intToArray(days).reduce((acc, index) => {
    const newDate = add(now, { days: index + 1 });

    acc.push({
      value: dateToString(newDate),
      labelText: dateFormat(newDate, DATE_FORMATS.DAY_WEEKDAY),
    });
    return acc;
  }, initialDates);
};

export const intToSlot = num => {
  return TIME_SLOTS[num]?.labelText;
};

export const getBusinessHours = day => {
  if (!day.status) return '';
  return `${intToSlot(day.start)} - ${intToSlot(day.end)}`;
};

export const getNextAvailable = interval => {
  const now = new Date();
  const currentMinutes = differenceInMinutes(now, startOfToday());
  return Math.ceil(currentMinutes / interval) * interval;
};

export const generateTimeSlots = (businessHours, future = null) => {
  const now = new Date();
  const dayOfWeekId = parseInt(dateFormat(future || now, 'e')) - 1;
  let startTimeMin = businessHours[dayOfWeekId].start;
  let endTimeMin = businessHours[dayOfWeekId].end;

  const INTERVAL = 15;
  const PREPRARE_INTERVAL = 2;
  const beforeClosing = 0;
  const slotLengthMin = 240; // 60 = 1 hour

  const nowMinutes = getNextAvailable(INTERVAL) / INTERVAL + PREPRARE_INTERVAL;
  if (!future) startTimeMin = Math.max(nowMinutes, startTimeMin);
  const slotEndTime = startTimeMin + Math.floor(slotLengthMin / INTERVAL);

  let timeSlots: Array<{ value: number; labelText: string }> = [];
  for (let i = startTimeMin; i <= endTimeMin - beforeClosing; i++) {
    if (i === startTimeMin) timeSlots.push({ value: -1, labelText: intl('COMMON.ASAP') });
    if (i === slotEndTime) break;
    timeSlots.push({
      ...TIME_SLOTS[i],
    });
  }

  return timeSlots;
};

export const deriveStoreOpening = store => {
  const {
    setting: { ohours },
  } = store;
  const now = new Date();
  const todayStart = startOfToday();
  const dow = parseInt(dateFormat(now, 'e')) - 1;
  const todayHours = ohours[dow];
  const { start, end } = todayHours;

  const businessStartTime = addMinutes(todayStart, 15 * start);
  const businessEndTime = addMinutes(todayStart, 15 * end);

  return now >= businessStartTime && now < businessEndTime;
};
