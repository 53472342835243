import * as INPUT from './input';
import STATES from './options/states';

import { validateEmail } from '$ghelpers/input';

export const ZIP = {
  label: 'INPUT.LABEL.ZIP',
  value: 'zip',
  input: INPUT.NUMBERTEXT,
  maxLength: 5,
  gridSize: 3,
};

export const STATE = {
  label: 'INPUT.LABEL.STATE',
  value: 'state',
  input: INPUT.SELECT,
  options: STATES,
  gridSize: 3,
};

export const PHONE = {
  label: 'INPUT.LABEL.PHONE',
  value: 'phone',
  input: INPUT.PHONE,
  maskType: INPUT.MASKS.PHONE,
  maxLength: 12,
  placeholder: '',
  gridSize: 6,
};

export const FAX = {
  label: 'INPUT.LABEL.FAX',
  value: 'phone',
  input: INPUT.PHONE,
  maskType: INPUT.MASKS.PHONE,
  maxLength: 12,
  placeholder: '',
  gridSize: 6,
};

export const MOBILE = {
  label: 'INPUT.LABEL.MOBILE',
  value: 'mobile',
  input: INPUT.PHONE,
  maskType: INPUT.MASKS.PHONE,
  maxLength: 12,
  placeholder: '',
  gridSize: 6,
};

export const FIRST_NAME = {
  label: 'INPUT.LABEL.FIRST_NAME',
  value: 'firstName',
  input: INPUT.TEXT,
  placeholder: '',
  gridSize: 6,
};

export const LAST_NAME = {
  label: 'INPUT.LABEL.LAST_NAME',
  value: 'lastName',
  input: INPUT.TEXT,
  placeholder: '',
  gridSize: 6,
};

export const PASSWORD = {
  label: 'INPUT.LABEL.PASSWORD',
  value: 'password',
  input: INPUT.PASSWORD,
  placeholder: '',
  gridSize: 6,
};

export const EMAIL = {
  label: 'INPUT.LABEL.EMAIL',
  value: 'email',
  input: INPUT.EMAIL,
  placeholder: '',
  gridSize: 6,
  validation: [
    {
      condition: v => !validateEmail(v) && v,
      message: 'INVALID_EMAIL',
    },
  ],
};

export const AMOUNT = {
  label: 'INPUT.LABEL.AMOUNT',
  value: 'amount',
  input: INPUT.CURRENCY,
  maskType: INPUT.MASKS.CURRENCY,
  postfix: 'INPUT.POSTFIX.CURRENCY',
  placeholder: '',
  gridSize: 6,
};

export const STREET = {
  label: 'INPUT.LABEL.STREET',
  value: 'street',
  input: INPUT.TEXT,
  placeholder: '',
  gridSize: 12,
};

export const CITY = {
  label: 'INPUT.LABEL.CITY',
  value: 'city',
  input: INPUT.TEXT,
  placeholder: '',
  gridSize: 6,
};

export const ADDRESS_GROUP = [
  {
    ...STREET,
  },
  {
    ...CITY,
  },
  {
    ...STATE,
  },
  {
    ...ZIP,
  },
];

export const SSN = {
  label: 'INPUT.LABEL.SSN',
  value: 'ssn',
  input: INPUT.NUMBERTEXT,
  maskType: INPUT.MASKS.SSN,
  maxLength: 11,
  placeholder: '',
  gridSize: 6,
};

export const DOB = {
  label: 'INPUT.LABEL.DOB',
  value: 'dob',
  input: INPUT.DATE,
  placeholder: '',
  gridSize: 6,
};

export const CARD_NUMBER = {
  value: 'cardNumber',
  input: INPUT.NUMBERTEXT,
  maskType: INPUT.MASKS.CARD_NUMBER,
  label: 'INPUT.LABEL.CARD_NUMBER',
  placeholder: 'INPUT.PLACEHOLDER.CARD_NUMBER',
  maxLength: 19,
  gridSize: 6,
};

export const EXPIRY = {
  value: 'expiry',
  input: INPUT.NUMBERTEXT,
  maskType: INPUT.MASKS.EXPIRY,
  label: 'INPUT.LABEL.EXPIRY',
  placeholder: 'INPUT.PLACEHOLDER.EXPIRY',
  maxLength: 5,
  gridSize: 3,
};

export const CVC = {
  value: 'CVC',
  input: INPUT.NUMBERTEXT,
  label: 'INPUT.LABEL.CVC',
  placeholder: 'INPUT.PLACEHOLDER.CVC',
  gridSize: 3,
  maxLength: 3,
  disableFastField: true,
  condition: values => {
    if (values.cardNumber && values.cardNumber[0] === '3')
      return {
        maxLength: 4,
        placeholder: 'INPUT.PLACEHOLDER.CVC4',
      };
  },
};

export const GOOGLE_ADDRESS = {
  value: 'address',
  input: INPUT.ADDRESS,
  label: 'INPUT.LABEL.ADDRESS',
  gridSize: 12,
};
