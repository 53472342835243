import React from 'react';
import { connect } from 'react-redux';
import { IonContent, IonPage } from '@ionic/react';
import DirectionsIcon from '@material-ui/icons/Directions';

import { screen } from '$gcomponents/hoc';
import StoreModel from '$business/models/store';

import { IonPageWrapper } from './styles';
import { ContentWrapper, PageTitle } from '$styles';
import { ColAuto, Div, Inline, Row, SPACE } from '$gstyles';
import { phone as formatPhone } from '$ghelpers/mask';
import intl from '$intl';
import BusinessHours from '$components/storeStatus/businessHours';
import { Flex } from '$gstyles/wrapper';

interface AboutUsScreenProps {
  store: StoreModel;
}

const AboutUsScreen: React.FC<AboutUsScreenProps> = ({ store }) => {
  if (!store) return <IonPage />;

  const {
    place,
    phone,
    storeName,
    setting: { bhours },
  } = store;
  const googleEmbedUrl =
    'https://www.google.com/maps/embed/v1/place?key=' +
    process.env.REACT_APP_GOOGLE_API_KEY +
    '&q=place_id:' +
    place?.placeId;
  return (
    <IonPageWrapper>
      <IonContent>
        <ContentWrapper>
          <PageTitle center>{intl('SCREEN.ABOUT_US.TITLE')}</PageTitle>
          <Div margin={SPACE.MEDIUM}>&nbsp;</Div>
          <Row>
            <ColAuto>
              <h3>{storeName}</h3>

              {place && (
                <>
                  <Div>{place?.street}</Div>
                  <Div>
                    {place?.city}, {place?.state} {place?.zip}
                  </Div>
                </>
              )}
              {!place && <Div>{store.address}</Div>}
              {phone && (
                <Div>
                  <a href={`tel:${phone}`}>{formatPhone(phone?.toString())}</a>
                </Div>
              )}
              {place && (
                <>
                  <Div margin={SPACE.LARGE}>&nbsp;</Div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.google.com/maps/dir/?api=1&destination=${place.lat},${place.lng}&destination_place_id=${place.placeId}`}>
                    <Flex className="directions">
                      <DirectionsIcon />
                      <Inline>{intl('SCREEN.ABOUT_US.DIRECTIONS')}</Inline>
                    </Flex>
                  </a>
                </>
              )}
            </ColAuto>
            <ColAuto>
              <h3>{intl('SCREEN.ABOUT_US.HOURS')}</h3>
              <BusinessHours hours={bhours} />
            </ColAuto>
          </Row>
          <Div margin={SPACE.LARGE}>&nbsp;</Div>
          <iframe title="map" width="100%" height="500" scrolling="no" src={googleEmbedUrl} />
          <Div margin={SPACE.LARGE}>&nbsp;</Div>
        </ContentWrapper>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = state => ({
  store: state.store,
});

const connected = connect(mapStateToProps, null);

export default connected(screen(AboutUsScreen));
