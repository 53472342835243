import { FONT } from '$gstyles';
import { IonHeader, IonTitle } from '@ionic/react';
import styled from 'styled-components';

const HEADER_HEIGHT = '56px';

export const IonHeaderWrapper = styled(IonHeader)`
  ion-button.receipt {
    min-width: 44px;
    height: 48px;
  }

  ion-toolbar,
  .MuiSvgIcon-root {
    color: var(--ion-color-secondary-contrast-bw);
  }
`;

export const BrandWrapper = styled(IonTitle)`
  text-align: center;

  &.has-caption {
  }
  .logo {
    height: calc(${HEADER_HEIGHT} - 12px);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  &.has-logo.has-caption .logo {
    height: calc(${HEADER_HEIGHT} - 24px);
  }
  &:not(.has-caption):not(.has-logo) {
    font-size: ${FONT.LARGE};
  }
  &.has-caption .caption {
    font-size: ${FONT.LARGE};
  }
  &.has-logo.has-caption .caption {
    font-size: ${FONT.SMALL};
    opacity: 0.8;
    margin: 0;
    padding: 0;
    line-height: 12px;
  }
  ion-button.receipt {
    min-width: 44px;
    height: 48px;
  }
`;

export const Logo = styled.div`
  ${p => p.url && 'background-image: url(' + p.url + ');'}
`;
