import styled from 'styled-components';
import { Div, SPACE } from '$gstyles';

export const Wrapper = styled(Div)`
  height: 44px;
  align-items: center;
  user-select: none;
  border-bottom: 1px solid var(--ion-border-color);

  .check {
    width: 48px;
    display: flex;
    position: relative;
  }
  .name {
    flex: 1;
  }
  .price {
    width: 120px;
    text-align: right;
    padding-right: ${SPACE.LARGE};
  }
`;

export const IconWrapper = styled(Div)`
  position: absolute;
  top: -12px;
  left: -2px;
  z-index: 1;
  color: var(--ion-color-primary);

  ion-icon {
    font-size: 36px;
    --ionicon-stroke-width: 48px;
  }
`;
