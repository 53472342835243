export const KEYS = {
  TAKE_OUT: 'TAKE_OUT',
  STAY_IN: 'STAY_IN',
};

const LIST = Object.keys(KEYS);

export default LIST.map(item => ({
  label: `TABLE_METHOD.${item}`,
  value: item,
}));

export type TABLE_METHOD_TYPES = typeof LIST[number] | undefined;
