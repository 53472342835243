export const TEXT = 'TEXT';
export const HIDDEN = 'HIDDEN';
export const TEXTAREA = 'TEXTAREA';
export const PASSWORD = 'PASSWORD';
export const NUMBER = 'NUMBER';
export const NUMBERTEXT = 'NUMBERTEXT';
export const EMAIL = 'EMAIL';
export const PHONE = 'PHONE';
export const CURRENCY = 'CURRENCY';
export const SELECT = 'SELECT';
export const BUTTON_SELECT = 'BUTTON_SELECT';
export const MULTI_SELECT = 'MULTI_SELECT';
export const RADIO = 'RADIO';
export const CHECKBOX = 'CHECKBOX';
export const DATE = 'DATE';
export const TIME = 'TIME';
export const PICKER = 'PICKER';
export const MULTI_PICKER = 'MULTI_PICKER';
export const CARD_NUMBER = 'CARD_NUMBER';
export const EXPIRY = 'EXPIRY';
export const CVC = 'CVC';
export const ADDRESS = 'ADDRESS';

export type types =
  | typeof TEXT
  | typeof TEXTAREA
  | typeof PASSWORD
  | typeof NUMBERTEXT
  | typeof NUMBER
  | typeof EMAIL
  | typeof PHONE
  | typeof CURRENCY
  | typeof SELECT
  | typeof MULTI_SELECT
  | typeof RADIO
  | typeof CHECKBOX
  | typeof CARD_NUMBER
  | typeof EXPIRY
  | typeof CVC
  | typeof DATE
  | typeof TIME
  | typeof PICKER
  | typeof ADDRESS
  | typeof MULTI_PICKER;

export const TYPES = {
  TEXTAREA: 'textarea',
  TEXT: 'text',
  HIDDEN: 'hidden',
  EMAIL: 'email',
  NUMBER: 'number',
  PASSWORD: 'password',
  SEARCH: 'search',
  TEL: 'tel',
  URL: 'url',
};

export const MODES = {
  TEXT: 'text',
  NONE: 'none',
  TEL: 'tel',
  URL: 'url',
  EMAIL: 'email',
  PASSWORD: 'password',
  NUMERIC: 'numeric',
  DECIMAL: 'decimal',
  SEARCH: 'search',
};

export const MASKS = {
  PHONE: 'PHONE',
  SSN: 'SSN',
  IP: 'IP',
  CURRENCY: 'MONEY',
  DATE: 'DATE',
  CARD_NUMBER: 'CARD_NUMBER',
  EXPIRY: 'EXPIRY',
};

export type InputType =
  | typeof TYPES.TEXT
  | typeof TYPES.NUMBER
  | typeof TYPES.TEL
  | typeof TYPES.URL
  | typeof TYPES.EMAIL
  | typeof TYPES.PASSWORD
  | typeof TYPES.HIDDEN
  | undefined;

export type InputModeType =
  | typeof MODES.TEXT
  | typeof MODES.NONE
  | typeof MODES.TEL
  | typeof MODES.URL
  | typeof MODES.PASSWORD
  | typeof MODES.EMAIL
  | typeof MODES.NUMERIC
  | typeof MODES.DECIMAL
  | typeof MODES.SEARCH
  | undefined;

export type MaskType =
  | typeof MASKS.PHONE
  | typeof MASKS.SSN
  | typeof MASKS.IP
  | typeof MASKS.CURRENCY
  | typeof MASKS.DATE;
