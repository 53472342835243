import React from 'react';

import { currency } from '$ghelpers/util';
import CartModel from '$business/models/cart';

import { TotalWrapper, TotalLine, TotalPrice, TotalLabel } from './styles';
import intl from '$intl';
import { WEIGHT } from '$gstyles';

interface TotalSectionProps {
  cart: CartModel;
}

const TotalSection: React.FC<TotalSectionProps> = ({ cart }) => {
  if (!cart.products.length) return null;

  const { total, subtotal, tax, delivery, tip, discount } = cart.price;

  return (
    <TotalWrapper>
      <TotalLine>
        <TotalLabel>{intl('RECEIPT.SUBTOTAL')}</TotalLabel>
        <TotalPrice>{currency(subtotal)}</TotalPrice>
      </TotalLine>
      {delivery > 0 && (
        <TotalLine>
          <TotalLabel>{intl('RECEIPT.DELIVERY')}</TotalLabel>
          <TotalPrice>{currency(delivery)}</TotalPrice>
        </TotalLine>
      )}
      {discount > 0 && (
        <TotalLine>
          <TotalLabel>{intl('RECEIPT.DISCOUNT')}</TotalLabel>
          <TotalPrice>{currency(discount)}</TotalPrice>
        </TotalLine>
      )}
      <TotalLine>
        <TotalLabel>{intl('RECEIPT.TAX')}</TotalLabel>
        <TotalPrice>{currency(tax)}</TotalPrice>
      </TotalLine>
      {tip > 0 && (
        <TotalLine>
          <TotalLabel>{intl('RECEIPT.TIP')}</TotalLabel>
          <TotalPrice>{currency(tip)}</TotalPrice>
        </TotalLine>
      )}
      <TotalLine fontWeight={WEIGHT.SEMI_BOLD}>
        <TotalLabel>{intl('RECEIPT.TOTAL')}</TotalLabel>
        <TotalPrice>{currency(total)}</TotalPrice>
      </TotalLine>
    </TotalWrapper>
  );
};

export default TotalSection;
