import TableModel, { deriveRawToTable } from './table';

export interface PlaceModel {
  addressId: number;
  city: string;
  county: string;
  formatted: string;
  lat: number;
  lng: number;
  placeId: string;
  state: string;
  street: string;
  zip: number;
}

export default interface StoreModel {
  storeNumber: number;
  storeCode: string;
  storeName: string;
  emai?: string;
  phone?: string;
  tax: number;
  place?: PlaceModel;
  address: string;
  paymentGateway?: string;
  setting: SettingModel;
  tables: Array<TableModel>;
}

export interface SettingModel {
  services: {
    delivery: boolean;
    pickup: boolean;
    curbside?: boolean;
    tableSide: boolean;
  };
  methodOptions: {
    payAtLocation: boolean;
    payAtDelivery: boolean;
    payAtCounter: boolean;
    forceAtCounter: boolean;
  };
  ohours: Array<hour>;
  bhours?: Array<hour>;
  dhours?: Array<hour>;
  phours?: Array<hour>;
  enableItemInstruction?: boolean;
  instructionText?: string;
  enablePayCounter?: boolean;
  shouldPromptTable?: boolean;
  pickupFutureDays?: number;
  deliveryFutureDays?: number;
  deliveryRadius1?: number;
  deliveryRadius2?: number;
  deliveryRadius3?: number;
  deliveryFee1?: number;
  deliveryFee2?: number;
  deliveryFee3?: number;
  displaySecond: boolean;
  markup1?: number;
  markup2?: number;
  markup3?: number;
  waiveDeliveryMin?: number;
  freeDelivery?: boolean;
  headerCaption?: string;
  logo?: string;
  theme?: theme;
  tax1?: number;
  tax2?: number;
  tax3?: number;
  enableTip: boolean;
  tip1?: number;
  tip2?: number;
  tip3?: number;
  singlePage?: boolean;
}

export interface hour {
  label: string;
  status: boolean;
  start: number;
  end: number;
}

export interface theme {
  fontFamily?: string;
  background: string;
  category: string;
  header: string;
  option: string;
  primary: string;
  product: string;
  receipt: string;
}

export const defaultStore: StoreModel = {
  storeNumber: 0,
  storeCode: '',
  storeName: '',
  tax: 6,
  address: '',
  setting: {
    displaySecond: true,
    services: {
      delivery: false,
      pickup: true,
      curbside: false,
      tableSide: false,
    },
    methodOptions: {
      payAtLocation: false,
      payAtDelivery: false,
      payAtCounter: false,
      forceAtCounter: false,
    },
    ohours: [
      { label: 'Sunday', status: true, start: 36, end: 84 },
      { label: 'Monday', status: true, start: 36, end: 84 },
      { label: 'Tuesday', status: true, start: 36, end: 84 },
      { label: 'Wednesday', status: true, start: 36, end: 84 },
      { label: 'Thursday', status: true, start: 36, end: 84 },
      { label: 'Friday', status: true, start: 36, end: 84 },
      { label: 'Saturday', status: true, start: 36, end: 84 },
    ],
    theme: {
      fontFamily: 'Roboto',
      background: '#333333',
      primary: '#e38a64',
      header: '#333333',
      receipt: '#ffffff',
      category: '#ffffff',
      product: '#ffffff',
      option: '#fff0f0',
    },
    enableTip: false,
  },
  tables: [],
};

export const deriveRawToStore = (rawStore, rawTables) => {
  if (!rawStore) return undefined;

  const {
    storeNumber,
    storeCode,
    name: storeName,
    email,
    phone,
    setting,
    tax,
    address,
    place,
    paymentMethod: paymentGateway,
  } = rawStore;
  const {
    servicesDelivery,
    servicesPickup,
    servicesTable,
    curbside,
    ohours,
    bhours,
    dhours,
    phours,
    instructionDisabled,
    instructionText,
    logo,
    payAtLocation,
    payAtDelivery,
    payCounter,
    forcePayCounter,
    pickupFutureDays,
    deliveryFutureDays,
    deliveryRadius1,
    deliveryRadius2,
    deliveryRadius3,
    deliveryFee1,
    deliveryFee2,
    deliveryFee3,
    secondLan,
    markup1,
    markup2,
    markup3,
    promptTable,
    waiveMinimum,
    freeDelivery,
    headerCaption,
    tax1,
    tax2,
    tax3,
    tip,
    tip1,
    tip2,
    tip3,
    theme,
    singlePage,
  } = setting;

  const store: StoreModel = {
    storeNumber,
    storeCode,
    storeName,
    ...(email && { email }),
    ...(phone && { phone }),
    tax: tax,
    address: address,
    paymentGateway,
    place,
    setting: {
      services: {
        delivery: !!servicesDelivery,
        pickup: !!servicesPickup,
        curbside: !!curbside,
        tableSide: !!servicesTable,
      },
      methodOptions: {
        payAtLocation,
        payAtDelivery,
        payAtCounter: payCounter,
        forceAtCounter: forcePayCounter,
      },
      ohours,
      ...(bhours && { bhours }),
      ...(dhours && { dhours }),
      ...(phours && { phours }),
      ...(deliveryFutureDays && { deliveryFutureDays }),
      shouldPromptTable: promptTable,
      enableItemInstruction: !instructionDisabled,
      instructionText,
      pickupFutureDays,
      deliveryFutureDays,
      deliveryRadius1,
      deliveryRadius2,
      deliveryRadius3,
      deliveryFee1,
      deliveryFee2,
      deliveryFee3,
      markup1,
      markup2,
      markup3,
      displaySecond: !!secondLan,
      enablePayCounter: !!payCounter,
      waiveDeliveryMin: waiveMinimum,
      freeDelivery,
      headerCaption,
      logo,
      theme: {
        ...theme,
        primary: theme.primary,
        secondary: theme.header,
        tertiary: theme.receipt,
        light: theme.background,
        category: theme.category,
        product: theme.product,
        option: theme.option,
      },
      tax1,
      tax2,
      tax3,
      enableTip: !!tip,
      tip1,
      tip2,
      tip3,
      singlePage: singlePage,
    },
    tables: rawTables.map(t => deriveRawToTable(t)),
  };

  return store;
};
