import React from 'react';
import { IonIcon } from '@ionic/react';
import { checkmarkOutline } from 'ionicons/icons';
import InfoIcon from '@material-ui/icons/Info';

import BoxSizeModel from '$business/models/boxSize';

import { TitleInner, TitleSection, Wrapper, IconWrapper } from './styles';
import ImgSection from './ImgSection';
import PriceSection from './PriceSection';
import { configs } from '$configs';
import DisplaySecond from '../../helpers/displaySecond';
import PopOver from '$gcomponents/reusables/popOver';
import { Div, FONT, SPACE } from '$gstyles';
import intl from '$intl';

interface ItemBoxProps {
  title: string;
  title2?: string;
  description?: string;
  description2?: string;
  price?: number;
  display: {
    hasPrice: boolean;
    hasImage: boolean;
    image?: string;
    shouldMarkNew: boolean;
    isSpicy?: boolean;
    isGlutenFree?: boolean;
    isVegan?: boolean;
    color?: string;
    backgroundColor?: string;
    borderRadius?: string;
    size: BoxSizeModel;
    numChecks?: number;
  };
  isDefault?: boolean;
  linkRoute?: string;
  onClick?: Function;
  setting: any;
}

const ItemBox: React.FC<ItemBoxProps> = ({
  title = '',
  title2 = '',
  description = '',
  description2 = '',
  price,
  isDefault = false,
  display: {
    hasPrice = false,
    hasImage = false,
    image,
    shouldMarkNew = false,
    color = '#333',
    backgroundColor = '#fff',
    size,
    numChecks = 0,
    isSpicy,
    isVegan,
    isGlutenFree,
  },
  linkRoute,
  onClick,
  setting,
}) => {
  const titleHeight = hasImage ? `${configs.display.boxTitleHeight || 54}px` : size.height;
  const priceHeight = hasPrice ? `${configs.display.boxPriceHeight || 32}px` : '';

  const newSize = {
    ...size,
    height: hasPrice && size ? `${(size.heightNumber || 0) + configs.display.boxPriceHeight}px` : size.height,
  };

  const checks = Array(numChecks)
    .fill(1)
    .map((m, i) => (
      <IconWrapper marginRight={`${i * 10}px`} key={i}>
        <IonIcon icon={checkmarkOutline} />
      </IconWrapper>
    ));

  return (
    <Wrapper bgcolor={backgroundColor} color={color} size={newSize} route={linkRoute} onClick={onClick}>
      <div className="upper-box">
        <TitleSection height={titleHeight}>
          <TitleInner>
            <DisplaySecond
              obj={{ title, title2 }}
              prop="title"
              lineBreak
              display2nd={setting.displaySecond}
            />
            {isDefault && <div className="default">(default)</div>}
          </TitleInner>
        </TitleSection>
        <ImgSection src={image} />
        <div className="flags">
          {isSpicy && <span className="hot" />}
          {isGlutenFree && <span className="gf" />}
          {isVegan && <span className="vegan" />}
        </div>
        {(description || description2) && (
          <PopOver
            component={
              <Div className="info pointer">
                <InfoIcon fontSize="large" color="secondary" />
              </Div>
            }
            backdrop>
            <Div padding={SPACE.LARGE} fontSize={FONT.MEDIUM} textAlign="center">
              <DisplaySecond
                obj={{ description, description2 }}
                prop="description"
                lineBreak
                display2nd={setting.displaySecond}
              />
            </Div>
          </PopOver>
        )}
      </div>
      <PriceSection price={price} height={priceHeight} />
      {checks}
      {shouldMarkNew && <div className="is-new">{intl('COMMON.NEW')}</div>}
    </Wrapper>
  );
};

export default ItemBox;
