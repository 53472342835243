import styled from 'styled-components';
import { Div, SPACE, WEIGHT } from '$gstyles';

export const Wrapper = styled(Div)`
  display: flex;
  position: relative;
  justify-content: space-between;
  min-height: ${p => p.height}px;
  padding: ${SPACE.SMALL} ${SPACE.MEDIUM};
  border-radius: 4px;
  background: var(--ion-color-product);
  color: var(--ion-color-product-contrast-bw);
  /* width: calc(100% - ${p => p.height}px);

  &.has-image {
    width: 100%;
  } */

  .text {
    flex: 1;
    position: relative;
    padding-top: 2px;

    .flex {
      font-weight: ${WEIGHT.SEMI_BOLD};
      justify-content: space-between;
    }
    .product-name {
      font-weight: ${WEIGHT.SEMI_BOLD};
      line-height: 1em;
    }
    .price {
      font-weight: ${WEIGHT.SEMI_BOLD};
      color: var(--ion-color-danger);
      line-height: 1em;
      padding-left: ${SPACE.SMALL};
    }
    .description {
      font-size: 0.85em;
      line-height: 1em;
    }
  }
  // Is New is on category style

  .flags {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 4px;
  }
  .flags > span {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    top: 0;
    left: 4px;
    display: inline-block;
    width: 20px;
    background-color: #fff;
    margin: 0 -3px;
    height: 20px;
  }
  .flags .vegan {
    background-image: url(/assets/img/vegan.png);
  }
  .flags .gf {
    background-image: url(/assets/img/gf.png);
  }
  .flags .hot {
    background-image: url(/assets/img/hot.png);
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  padding-top: 0;
  margin-left: ${SPACE.MEDIUM};
  width: ${p => p.size - 8}px;
  height: ${p => p.size - 8}px;
  > div {
    background-image: url(${props => props.source});
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 7px;
  }
`;
