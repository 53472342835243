import styled from 'styled-components';
import { IonButton } from '@ionic/react';
import { SPACE } from '$gstyles';

export const Wrapper = styled.div`
  flex: 1;
`;

export const Content = styled.div`
  position: absolute;
  margin: auto;
  text-align: center;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .MuiInputBase-inputMultiline {
    width: 300px;
    background-color: var(--ion-color-light-contrast-bw);
    padding: ${SPACE.MEDIUM};
  }

  h3 {
    margin-top: ${SPACE.LARGE};
  }
`;

export const InputWrapper = styled.div`
  height: 60px;
  width: 200px;
  margin: auto;
  overflow: hidden;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: #333;
  background-color: #fff;
  border-radius: 1px;
  div {
    flex: 1;
    font-size: 24px;
  }
`;

export const QuantityButton = styled(IonButton)`
  margin: 0;
  display: block;
  height: 100%;
  width: 60px;
  /* --background: #f5f5f5; 
  color: #333;*/
  --border-radius: 0;
`;
