import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ModifierSetModel from '$business/models/modifierSet';
import ModifierModel from '$business/models/modifier';
import BoxSizeModel from '$business/models/boxSize';

import ItemBox from '../ItemBox';
import { CartModifierModel } from '$business/models/cartItem';
import { cartActions } from '$business/redux/cart';
import { PointerModel } from '$business/models/cart';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '$business/enums';
import { Modal } from '$gcomponents/reusables';
import SplitContainer from '../SplitContainer';

interface ModifierBoxProps {
  modifierSet: ModifierSetModel;
  modifier: ModifierModel;
  productId: number;
  size: BoxSizeModel;
  pointer: PointerModel;
  index: number;
  setting: any;
  selections: Array<CartModifierModel>;
}

const setNumberOfChecks = (selection: CartModifierModel | undefined, maxChecks: number) => {
  if (!selection) return 1;
  const { quantity } = selection;
  return quantity >= maxChecks ? 0 : quantity + 1;
};

const ModifierBox: React.FC<ModifierBoxProps> = ({
  modifier,
  modifierSet,
  productId,
  pointer,
  size,
  index,
  selections,
  setting,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSplitModal, setShowSplitModal] = useState(false);
  const { productIndex, modifierIndex: modifierSetIndex } = pointer;
  const { isMultiple, isOptional, maxChecks, maxModifiers, hasSplits } = modifierSet;
  const {
    splitSet,
    hasImage,
    hasPrice,
    image,
    modifierName,
    modifierName2,
    description,
    description2,
    price,
    isDefault,
  } = modifier;
  const showSplits = splitSet && hasSplits;

  const selection = selections
    ? selections.find(m => m.modifierSetIndex === modifierSetIndex && m.modifierIndex === index)
    : undefined;

  const onClick = async () => {
    if (showSplits) {
      if (!selection) {
        // Not selected yet. Display modal
        setShowSplitModal(true);
        return;
      }
      await dispatch(cartActions.removeModifier(productIndex, modifierSetIndex, index));
    }
    if (isMultiple) {
      const newChecks = setNumberOfChecks(selection, maxChecks || 1);
      if (maxModifiers && newChecks > maxModifiers) {
        alert('Maximum has reached');
        return;
      }
      if (!newChecks) await dispatch(cartActions.removeModifier(productIndex, modifierSetIndex, index));
      else if (!selection)
        await dispatch(cartActions.addModifiers([modifier], productIndex, modifierSetIndex));
      else if (selection)
        await dispatch(cartActions.changeModifierQuantity(newChecks, productIndex, modifierSetIndex, index));
    } else {
      if (!selection || !selection.quantity) {
        await dispatch(cartActions.addModifiers([modifier], productIndex, modifierSetIndex, true));
        if (!isOptional) autoContinue();
      } else {
        await dispatch(cartActions.removeModifier(productIndex, modifierSetIndex, index));
      }
    }
  };

  const autoContinue = async () => {
    await dispatch(cartActions.changePointer(null, null, modifierSetIndex + 1));
    await history.push(`${ROUTES.PRODUCTS}/${productId}/${modifierSetIndex + 1}`);
  };

  const handleSplits = async split => {
    setShowSplitModal(false);
    await dispatch(cartActions.addModifiers([modifier], productIndex, modifierSetIndex, false, split));
    if (isOptional) autoContinue();
  };

  const numChecks = selection?.quantity || 0;
  const backgroundColor = 'var(--ion-color-option)';
  const color = 'var(--ion-color-option-contrast-bw)';

  return (
    <>
      <ItemBox
        onClick={onClick}
        title={modifierName}
        title2={modifierName2}
        isDefault={isDefault}
        description={description}
        description2={description2}
        price={price}
        setting={setting}
        display={{
          hasPrice,
          hasImage,
          image,
          backgroundColor,
          color,
          shouldMarkNew: false,
          size,
          numChecks,
        }}
      />
      {!!showSplits && (
        <Modal
          show={showSplitModal}
          useCustom
          titleText={`${splitSet?.splitSetName || ''} ${splitSet?.splitSetName2 || ''}`}
          onClose={() => setShowSplitModal(false)}>
          <SplitContainer setting={setting} splitSet={splitSet} onSetSplits={s => handleSplits(s)} />
        </Modal>
      )}
    </>
  );
};

export default ModifierBox;
