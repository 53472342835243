import React from 'react';
import { Formik, Form } from 'formik';

import { input } from '$ghelpers';
import { Button, Text } from '$gcomponents/primitives';
import { FormSection } from '$gcomponents/reusables';
import { Box, SPACE } from '$gstyles';

import REGISTER_FORM from '$gbox/screens/registerScreen/registerForm';

interface LoginFormProps {
  onSubmit: Function;
}

const RegisterForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const validateForm = values => {
    return input.validateError(REGISTER_FORM, values);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ state: '' }}
      validate={validateForm}
      onSubmit={values => {
        onSubmit(values);
      }}>
      {formik => (
        <Box.MEDIUM padding={SPACE.LARGE}>
          <Form>
            <FormSection
              title="SCREEN.REGISTER.SUBTITLE"
              FORM={REGISTER_FORM}
              formik={formik}
              marginBottom="0"
            />
            <Button
              color="primary"
              fullWidth
              onClick={formik.handleSubmit}
              disabled={!(formik.isValid && formik.dirty)}>
              <Text k="SCREEN.REGISTER.SUBMIT_BUTTON" />
            </Button>
          </Form>
        </Box.MEDIUM>
      )}
    </Formik>
  );
};

export default RegisterForm;
