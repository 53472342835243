import { FONT, SPACE } from '$gstyles';
import { desktopStyle } from '$gstyles/utils';
import { Dialog } from '@material-ui/core';
import styled from 'styled-components';

export const DialogModal = styled(Dialog)`
  .close-button {
    position: absolute;
    right: 0;
  }

  .MuiBackdrop-root.loading-backdrop {
    position: absolute;
    z-index: 200;
    flex-direction: column;

    .loading-text {
      color: #fff;
      font-size: ${FONT.MEDIUM};
      margin-top: ${SPACE.MEDIUM};
    }
    .MuiCircularProgress-root {
      color: #fff;
    }
  }

  ${p =>
    desktopStyle(`
    .MuiDialog-paperWidthSm {
      ${p.minwidth ? `min-width: ${p.minwidth};` : ''}
      ${p.width ? `max-width: ${p.width};` : ''}
      position: relative;
    }
  `)}
`;
