import styled from 'styled-components';
import { IonMenu } from '@ionic/react';

import { configs } from '$configs';
import { FONT, SPACE } from '$gstyles';

export const Wrapper = styled(IonMenu)`
  @media screen and (min-width: ${configs.display.desktopMinThreshold}) {
    --side-max-width: 250px;
    --side-min-width: 208px;
  }

  ion-content,
  ion-list,
  #background-content,
  ion-list-header,
  ion-item,
  div,
  ion-toolbar {
    --background: var(--ion-color-dark);
    color: var(--ion-color-dark-contrast-bw);
  }
  .list-md {
    background: var(--ion-color-dark);
    color: var(--ion-color-dark-contrast-bw);
  }
  ion-item.item ion-label,
  ion-item.item ion-icon {
    opacity: 0.8;
  }
  ion-item.item:hover {
    --background: rgba(var(--ion-color-light-rgb), 0.2);

    ion-label {
      color: var(--ion-color-dark-contrast-bw);
    }
  }
  ion-item.selected {
    --background: rgba(var(--ion-color-primary-rgb), 0.6);
  }

  ion-item ion-icon {
    margin-top: ${SPACE.MEDIUM};
    margin-bottom: ${SPACE.MEDIUM};
    margin-inline-end: ${SPACE.LARGE};
  }

  ion-item::part(native) {
    min-height: ${SPACE.LARGE};
    font-size: ${FONT.SMALL};
  }

  .logo {
    width: 190px;
    display: inline-block;
  }

  .app-version {
    display: inline-block;
    width: 40px;
    height: ${props => (props.isProd ? '14' : '28')}px;
    position: absolute;
    top: 50%;
    margin-top: -${props => (props.isProd ? '7' : '14')}px;
    right: ${SPACE.SMALL};
    font-size: 1.15rem;
    opacity: 0.5;

    > span {
      display: block;
    }
  }

  .name {
    font-size: 1.1em;
  }
  .email {
    margin-bottom: ${SPACE.LARGE};
  }

  &.md {
    ion-content {
      --padding-start: ${SPACE.MEDIUM};
      --padding-end: ${SPACE.MEDIUM};
      --padding-top: ${SPACE.MEDIUM};
      --padding-bottom: ${SPACE.MEDIUM};
    }
    ion-note {
      padding-left: ${SPACE.MEDIUM};
    }
    ion-item {
      --padding-start: ${SPACE.MEDIUM};
      --padding-end: ${SPACE.MEDIUM};
      border-radius: 4px;
    }

    ion-item ion-icon {
      color: var(--ion-color-dark-contrast-bw);
    }

    ion-item ion-label {
      font-weight: 500;
    }
  }
`;
