import styled from 'styled-components';
import { Div, SPACE } from '$gstyles';

export const Wrapper = styled(Div)`
  border: 1px solid var(--ion-border-color);
  padding: ${SPACE.LARGE} ${SPACE.MEDIUM};
  padding-right: 0;
  /* background: var(--ion-color-category);
  color: var(--ion-color-category-contrast-bw); */

  .category-name {
    margin-left: ${SPACE.SMALL};
  }
  .products {
    margin-top: ${SPACE.MEDIUM};
    .col {
      padding: ${SPACE.SMALL};
    }
  }
  .is-new {
    position: absolute;
    color: #ffff00;
    font-weight: 700;
    font-size: 0.9em;
    text-transform: uppercase;
    background-color: #ff1114;
    padding: 0px 6px;
    left: 0px;
    bottom: 6px;
    border-radius: 5px;
    /* transform: rotate(-45deg); */
  }
`;
