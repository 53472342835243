import React, { useState } from 'react';
import { connect } from 'react-redux';
import { screen } from '$gcomponents/hoc';
import { Div, H2 } from '$gstyles';
import { ContentWrapper, FlexIonContent } from '$styles';

import { IonPageWrapper } from './styles';
import intl from '$intl';
import { ApiTable } from '$gcomponents/reusables';
import { SORT } from '$gbusiness/enums';
import TABLE, { generateConfig } from './orderTable';
import OrderDisplay from '$components/orderDisplay';

interface OrderHistoryScreenProps {
  userId: number;
}

const OrderHistoryScreen: React.FC<OrderHistoryScreenProps> = ({ userId }) => {
  const [orderId, setOrderId] = useState(0);

  const onViewDetails = row => {
    setOrderId(row.orderId);
  };

  const TABLE_CONFIG = generateConfig(onViewDetails);
  const {
    endpoint,
    isPublic,
    pageSize,
    shouldVirtualize,
    deriveToModel,
    minWidth,
    maxWidth,
    color,
    fontColor,
    cellPadding,
    isStripped,
    onClickRow,
  } = TABLE_CONFIG;

  return (
    <IonPageWrapper>
      <FlexIonContent>
        <ContentWrapper>
          <H2 center>{intl('SCREEN.HISTORY.TITLE')}</H2>
          <Div overflow="auto">
            <ApiTable
              dataSource={{
                endpoint,
                isPublic,
                pageSize,
                shouldVirtualize,
                deriveToModel,
                onClickRow,
                defaultSortOrder: SORT.DSC,
              }}
              styles={{
                color,
                cellPadding,
                fontColor,
                isStripped,
                maxWidth,
                minWidth,
              }}
              TABLE={TABLE}
              filters={{
                userId,
              }}
            />
          </Div>
        </ContentWrapper>
        <OrderDisplay orderId={orderId} onCloseModal={() => setOrderId(0)} />
      </FlexIonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = state => ({
  userId: state.currentUser.userId,
});

const connected = connect(mapStateToProps, null);

export default connected(screen(OrderHistoryScreen));
