import styled from 'styled-components';
import { IonApp } from '@ionic/react';
import { ENUMS, SPACE } from '$gstyles';

export default styled(IonApp)`
  justify-content: flex-start;

  /* MATERIAL DEFAULT */
  .MuiSelect-select:focus {
    background-color: initial;
  }
  .MuiFormLabel-root {
    font-size: inherit;
  }
  .MuiInputAdornment-root ion-icon {
    font-size: 2rem;
  }

  .site-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: ${SPACE.MEDIUM}
  }

  /* MATERIAL UI INPUT COLORS */
  .MuiFormLabel-root {
    color: var(--ion-text-color);
  }

  .MuiButtonGroup-grouped {
    min-height: 40px;
    span {
      line-height: normal;
    }
  }
  .MuiInputBase-input.Mui-disabled {
    background-color: rgba(var(--ion-color-light-rgb), 0.2);
  }
  .MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12) !important;
  }

  @media screen and (max-width: ${ENUMS.SPLIT_PANE_WIDTH}) {
    .can-go-back ion-menu-button {
      display: none;
    }
  }

  /* @media screen and (min-width: ${ENUMS.SPLIT_PANE_MIN_WIDTH}) {
    .can-go-back > ion-header ion-back-button {
      display: none !important;
    }
  } */

  .ReactVirtualized__Table__headerColumn,
  .ReactVirtualized__Table__rowColumn {
    display: flex;
    align-items: center;
  }

  // Disabled input
  .MuiInputBase-root.Mui-disabled {
    opacity: 0.9;
    input {
      background-color: rgba(0, 0, 0, 0.13);
    }
  }

  ion-toast::part(container) {
  }

  ion-toast::part(message) {
    font-weight: 600;
  }
  .alert-message {
    color: var(--ion-text-color);
  }

  .warning.outlined {
    color: var(--ion-color-warning);
    border-color: var(--ion-color-warning);
  }
  .success.outlined {
    color: var(--ion-color-success);
    border-color: var(--ion-color-success);
  }
  .danger.outlined {
    color: var(--ion-color-danger);
    border-color: var(--ion-color-danger);
  }
  .primary.outlined {
    color: var(--ion-color-primary);
    border-color: var(--ion-color-primary);
  }
  .secondary.outlined {
    color: var(--ion-color-secondary);
    border-color: var(--ion-color-secondary);
  }
  .tertiary.outlined {
    color: var(--ion-color-tertiary);
    border-color: var(--ion-color-tertiary);
  }
  .tertiary.dark {
    color: var(--ion-color-dark);
    border-color: var(--ion-color-tertiary);
  }
  .tertiary.light {
    color: var(--ion-color-light);
    border-color: var(--ion-color-light);
  }
  .warning.filled {
    color: var(--ion-color-warning-contrast-bw);
    background-color: var(--ion-color-warning);
  }
  .success.filled {
    color: var(--ion-color-success-contrast-bw);
    background-color: var(--ion-color-success);
  }
  .danger.filled {
    color: var(--ion-color-danger-contrast-bw);
    background-color: var(--ion-color-danger);
  }
  .primary.filled {
    color: var(--ion-color-primary-contrast-bw);
    background-color: var(--ion-color-primary);
  }
  .secondary.filled {
    color: var(--ion-color-secondary-contrast-bw);
    background-color: var(--ion-color-secondary);
  }
  .tertiary.filled {
    color: var(--ion-color-tertiary-contrast-bw);
    background-color: var(--ion-color-tertiary);
  }
  .dark.filled {
    color: var(--ion-color-dark-contrast-bw);
    background-color: var(--ion-color-dark);
  }
  .light.filled {
    color: var(--ion-color-light-contrast-bw);
    background-color: var(--ion-color-light);
  }
`;
