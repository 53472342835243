import React, { useRef, useEffect, useState } from 'react';
import { menuController } from '@ionic/core';
import { Div } from '$gstyles';
import { Form, Formik } from 'formik';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { input, mask } from '$ghelpers';
import { H2 } from '$gstyles';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';

import { BASIC_INFO_FORM, DELIVERY_FORM, TIP_FORM, PAYMENT_FORM } from '../checkoutForm';
import intl from '$intl';
import CartModel from '$business/models/cart';
import { AccordionWrapper } from '../styles';
import { KEYS as DELIVERY_METHOD } from '$business/enums/options/deliveryMethods';
import { deriveDeliveryTime, getDefaultPaymentMethod, getDeliveryPrice, getMaxDeliveryRange } from '../utils';
import StoreModel from '$business/models/store';
import { KEYS } from '$business/enums/options/tableMethods';
import TipSection from '../tipSection';
import { Flex, Mobile } from '$gstyles/wrapper';
import UserModel from '$gbusiness/models/user';
import { Link } from '$gstyles/general';
import MuiModal from '$gcomponents/reusables/modal/material';
import TocContent from '$components/toc';
import { deriveItemShort } from '$business/models/cartItemShort';
import CardModel from '$gbusiness/models/card';
import { getDefaultCardId } from '$ghelpers/util';

interface FormComponentProps {
  store: StoreModel;
  cart: CartModel;
  user: UserModel;
  cards: Array<CardModel>;
  changeTip: Function;
  changeDelivery: Function;
  submitOrder: (v) => void;
  showLoginModal: () => void;
}

const initialAddressResult = {
  isValid: false,
  outOfRange: false,
  deliveryFee: 0,
  miles: 0,
};

const FormComponent: React.FC<FormComponentProps> = ({
  store,
  cart,
  cards,
  user,
  changeTip,
  changeDelivery,
  showLoginModal,
  submitOrder,
}) => {
  const formRef = useRef<any>();
  const { setting } = store;
  const { enableTip } = setting;
  const [tipIndex, setTipIndex] = useState(-2);
  const [addressResult, setAddressResult] = useState(initialAddressResult);
  const [showToc, setShowToc] = useState(false);
  const subtotal = cart?.price?.subtotal || 0;
  const total = cart?.price?.total || 0;

  const changeTipSelection = (index, amount) => {
    setTipIndex(index);
    if (amount === null) return;
    formRef.current.setFieldValue('tip', amount);
    onChangeTip({ value: amount });
  };

  const onChangeDeliveryMethod = deliveryMethod => {
    if (deliveryMethod === DELIVERY_METHOD.DELIVERY) return;
    setAddressResult(initialAddressResult);
    changeDelivery(0);
  };
  const onChangeTip = ({ value }) => {
    changeTip(Number(value));
  };
  const onChangeDelivery = ({ value, newValue }) => {
    if (!newValue || !value.place_id) {
      setAddressResult(initialAddressResult);
      return;
    }
    const { distance_miles: miles } = newValue;
    const deliveryFee = getDeliveryPrice({ miles, setting, subtotal });
    if (deliveryFee === -1) {
      setAddressResult({
        ...initialAddressResult,
        outOfRange: true,
        miles: miles.toFixed(2),
      });
      return;
    }
    setAddressResult({
      isValid: true,
      outOfRange: false,
      deliveryFee,
      miles: miles.toFixed(2),
    });
    changeDelivery(deliveryFee);
  };

  const initialValues = {
    deliveryAddress_place: undefined,
    paymentMethod: getDefaultPaymentMethod(cart.order?.type, setting),
    deliveryDate: '',
    deliveryTime: -1,
    deliveryMethod: cart.order?.type,
    ...(cards?.length && {
      cardId: getDefaultCardId(cards) || '',
    }),
    tableMethod: KEYS.TAKE_OUT,
    ...(user.userId && {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      mobile: mask.phone((user?.other?.mobile || '').toString()),
    }),
    ...{
      // cardNumber: '4358 8016 3036 5014',
      // expiry: '03/26',
      // cardHolder: 'Henry Han',
      // zip: '19090',
    },
  };

  const deriveFormToRequestParam = values => {
    return {
      ...values,
      deliveryTimeStr: deriveDeliveryTime(values),
      userId: user.userId,
      ...(values.deliveryMethod === DELIVERY_METHOD.DELIVERY && {
        place: JSON.stringify(values.deliveryAddress_place),
      }),
      ...(values.tip > 0 && { tip: Number(values.tip) }),
      ...cart.price,
      items: JSON.stringify(cart.products.map(p => deriveItemShort(p))),
    };
  };

  const onSubmit = values => {
    const requestObj = deriveFormToRequestParam(values);
    submitOrder(requestObj);
  };

  const validateForm = values => {
    const { deliveryMethod } = values;
    return input.validateError(
      [
        ...BASIC_INFO_FORM,
        ...DELIVERY_FORM({ store, deliveryMethod, onChangeDelivery, onChangeDeliveryMethod }),
        ...TIP_FORM({ setting, customizeTip: tipIndex === -1, onChangeTip }),
        ...PAYMENT_FORM(store, deliveryMethod, cards || []),
      ],
      values,
    );
  };

  useEffect(() => {
    formRef.current.validateForm();
  }, []);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={values => {
        onSubmit(values);
      }}>
      {formik => {
        const deliveryMethod = formik.values.deliveryMethod;
        return (
          <Form>
            <AccordionWrapper>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <H2 padding="0">{intl('CHECKOUT.TITLE_INFO')}</H2>
                </AccordionSummary>
                <AccordionDetails>
                  <FormSection FORM={BASIC_INFO_FORM} formik={formik} marginBottom="0" flex={1} />
                  {!user.userId && (
                    <div>
                      <Button onClick={showLoginModal}>Login</Button>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <H2 padding="0">{intl('CHECKOUT.TITLE_PICKUP')}</H2>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block' }}>
                  <FormSection
                    marginBottom="0"
                    FORM={DELIVERY_FORM({
                      store,
                      deliveryMethod,
                      onChangeDelivery,
                      onChangeDeliveryMethod,
                    })}
                    formik={formik}
                    width="100%"
                  />
                  {deliveryMethod === DELIVERY_METHOD.DELIVERY && (
                    <Flex className="delivery-message">
                      {addressResult.isValid && (
                        <Div className="valid">
                          {addressResult.miles && (
                            <div>
                              {intl('CHECKOUT.DELIVERY_RESULT.DISTANCE', { miles: addressResult.miles })}
                            </div>
                          )}
                          {addressResult.deliveryFee === 0 && (
                            <div>{intl('CHECKOUT.DELIVERY_RESULT.FREE_DELIVERY')}</div>
                          )}
                          {addressResult.deliveryFee > 0 && (
                            <div>
                              {intl('CHECKOUT.DELIVERY_RESULT.FEE', {
                                amount: intl('PRICE', { amount: addressResult.deliveryFee }),
                              })}
                            </div>
                          )}
                        </Div>
                      )}
                      {!addressResult.isValid && (
                        <Div className="invalid">
                          {!addressResult.outOfRange && <div>{intl('CHECKOUT.DELIVERY_RESULT.EMPTY')}</div>}
                          {addressResult.outOfRange && (
                            <div>
                              {intl('CHECKOUT.DELIVERY_RESULT.OUT_OF_RANGE', {
                                miles: getMaxDeliveryRange(setting),
                              })}
                            </div>
                          )}
                        </Div>
                      )}
                    </Flex>
                  )}
                  <FormSection
                    FORM={TIP_FORM({
                      setting,
                      customizeTip: tipIndex === -1,
                      onChangeTip,
                    })}
                    formik={formik}
                    width="100%"
                  />
                  {enableTip && (
                    <TipSection
                      formik={formik}
                      setting={setting}
                      subtotal={subtotal}
                      onSelect={changeTipSelection}
                      index={tipIndex}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <H2 padding="0">{intl('CHECKOUT.TITLE_PAYMENT')}</H2>
                </AccordionSummary>
                <AccordionDetails>
                  <Flex className="pay-label">
                    <div className="price">
                      {intl('CHECKOUT.TOTAL')} {intl('PRICE', { amount: total })}
                    </div>
                    <Mobile className="details">
                      <Link onClick={() => menuController.open('end')}>{intl('CHECKOUT.VIEW_DETAILS')}</Link>
                    </Mobile>
                  </Flex>
                  <FormSection FORM={PAYMENT_FORM(store, deliveryMethod, cards || [])} formik={formik} />
                </AccordionDetails>
              </Accordion>
              <Div maxWidth="600px">
                <Div className="toc">
                  Your data will be used to process your purchase and communicate with you. We do not use your
                  data for any other purpose. By clicking PLACE ORDER you agree to the
                  <Link onClick={() => setShowToc(true)}>Terms & Conditions</Link>. and to receive
                  communications from us via text, email, or phone call. (
                  <Link onClick={() => setShowToc(true)}>Read our privacy policy for details.</Link>)
                </Div>
                <Button
                  className="filled primary submit"
                  onClick={formik.handleSubmit}
                  disabled={!formik.isValid}>
                  {intl('CHECKOUT.SUBMIT_BUTTON')}
                </Button>
              </Div>
            </AccordionWrapper>
            <MuiModal open={showToc} onClose={() => setShowToc(false)} minWidth="600px">
              <TocContent onClickClose={() => setShowToc(false)} />
            </MuiModal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormComponent;
