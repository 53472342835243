import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { menuActions } from '$business/redux/menu';
import MuiModal from '$gcomponents/reusables/modal/material';
import { Space } from '$gstyles';
import { OrderDisplayWrapper } from '../../screens/checkoutScreen/styles';

interface OrderDisplayProps {
  onCloseModal: Function;
  orderId: number;
  display: string;
  getOrderDisplay: Function;
  cleanOrder: Function;
}

const OrderDisplay: React.FC<OrderDisplayProps> = ({
  onCloseModal,
  orderId,
  display,
  getOrderDisplay,
  cleanOrder,
}) => {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (orderId > 0) {
      getOrderDisplay(orderId);
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const handleClose = () => {
    cleanOrder();
    onCloseModal();
  };

  return (
    // <Modal show={showModal && !!display} onClose={handleClose}>
    <MuiModal open={showModal && !!display} onClose={handleClose} minWidth="500px">
      <Space.All margin="24px" />
      <OrderDisplayWrapper>
        <div dangerouslySetInnerHTML={{ __html: display }} />
      </OrderDisplayWrapper>
    </MuiModal>
  );
};

const mapStateToProps = state => ({
  display: state.menu?.order?.display,
});

const mapDispatchToProps = {
  getOrderDisplay: menuActions.getOrderDisplay,
  cleanOrder: menuActions.cleanOrder,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(OrderDisplay);
