import styled from 'styled-components';
import { Div, SPACE } from '$gstyles';

export const Wrapper = styled(Div)`
  .description {
    font-size: 1.1em;
    margin: ${SPACE.MEDIUM} 0px;
  }
  margin-bottom: ${SPACE.LARGE};
`;
