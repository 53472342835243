import ModifierSetModel from './modifierSet';

export default interface ProductModel {
  productId: number;
  productName: string;
  productName2?: string;
  categoryId: number;
  categoryName: string;
  catProd: boolean;
  description: string;
  description2?: string;
  price: number;
  tax: number;
  display: {
    hasImage: boolean;
    image?: string;
    shouldHide: boolean;
    shouldMarkNew: boolean;
    isSpicy: boolean;
    isGlutenFree: boolean;
    isVegan: boolean;
  };
  hasModifiers?: boolean;
  hasDefaultOption: boolean;
  defaultDescription?: string;
  modifierSets: Array<ModifierSetModel>;
}

export const deriveRawToModel = (rawProduct, rawGroup: any = null) => {
  if (!rawProduct) return undefined;

  // const { name: categoryName, groupId: categoryId } = category;
  const {
    id: productId,
    name: productName,
    name2: productName2,
    groupId: categoryId,
    description,
    description2,
    defaultOption,
    defaultDescription,
    price,
    tax,
    hasImage,
    options: modifierSets,
    image,
    hasModifiers,
    hide,
    isNew,
    flags,
  } = rawProduct;

  const features = {
    ...flags,
  };

  const product: ProductModel = {
    productId,
    productName: rawGroup?.isProduct ? rawGroup?.name + ' ' + productName : productName,
    productName2: rawGroup?.isProduct ? rawGroup?.name2 + ' ' + productName2 : productName2,
    categoryId,
    categoryName: rawGroup?.name,
    catProd: rawGroup?.isProduct,
    description,
    description2,
    price,
    tax,
    display: {
      hasImage,
      image,
      shouldHide: !!hide,
      shouldMarkNew: !!isNew,
      isSpicy: features.s ? true : false,
      isGlutenFree: features.g ? true : false,
      isVegan: features.v ? true : false,
    },
    hasModifiers: !!hasModifiers,
    hasDefaultOption: !!defaultOption,
    defaultDescription,
    modifierSets,
  };

  return product;
};
