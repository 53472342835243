import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import { input } from '$ghelpers';
import InputRowModel from '$gbusiness/models/inputRow';
import DELIVERY_METHOD_OPTIONS, { KEYS as DELIVERY_METHOD } from '$business/enums/options/deliveryMethods';
import TABLE_METHOD_OPTIONS from '$business/enums/options/tableMethods';
import { generateTimeSlots, getFutureDates } from '$business/helpers/util';
import { deriveVariables, getStoreLocation, shouldHidePaymentOptions } from './utils';
import CardModel from '$gbusiness/models/card';

const style = {
  label: 'var(--ion-color)',
  error: '#ffaaaa',
  focus: '#99ccff',
  background: '#fff',
};
export const BASIC_INFO_FORM: Array<InputRowModel> = [
  {
    style,
    items: [
      {
        ...INPUT_TYPES.FIRST_NAME,
        placeholder: 'INPUT.PLACEHOLDER.FIRST_NAME',
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.LAST_NAME,
        placeholder: 'INPUT.PLACEHOLDER.LAST_NAME',
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.EMAIL,
        placeholder: 'INPUT.PLACEHOLDER.EMAIL',
        validation: [
          input.requiredValidation,
          {
            condition: v => !input.validateEmail(v),
            message: 'INVALID_EMAIL',
          },
        ],
        gridSize: 6,
      },
      {
        ...INPUT_TYPES.MOBILE,
        placeholder: 'INPUT.PLACEHOLDER.PHONE',
        gridSize: 6,
      },
    ],
  },
];

export const DELIVERY_FORM = ({ store, deliveryMethod, onChangeDelivery, onChangeDeliveryMethod }) => {
  const { setting, tables } = store;
  const [futureDays, hours, dayLabel, timeLabel] = deriveVariables(deliveryMethod, setting);

  const deliveryTimeSlots = generateTimeSlots(hours);

  return [
    {
      style,
      items: [
        {
          input: INPUT.BUTTON_SELECT,
          options: DELIVERY_METHOD_OPTIONS,
          label: 'INPUT.LABEL.DELIVERY_METHOD',
          value: 'deliveryMethod',
          gridSize: 12,
          onChange: ({ formik, value }) => {
            if (value === DELIVERY_METHOD.IN_RESTAURANT && setting?.methodOptions?.forceAtCounter) {
              formik.setFieldValue('paymentMethod', 'OFFLINE');
            } else {
              formik.setFieldValue('paymentMethod', 'ONLINE');
            }
            formik.setFieldValue('deliveryDate', '');
            formik.setFieldValue('deliveryTime', -1);
            onChangeDeliveryMethod(value);
          },
        },
      ],
    },
    {
      style,
      items: [
        {
          isHidden: values => values.deliveryMethod === DELIVERY_METHOD.IN_RESTAURANT,
          input: INPUT.SELECT,
          options: getFutureDates(futureDays),
          label: dayLabel,
          value: 'deliveryDate',
          disableFastField: true,
          gridSize: 4,
        },
        {
          isHidden: values => values.deliveryMethod !== DELIVERY_METHOD.IN_RESTAURANT,
          input: INPUT.SELECT,
          options: TABLE_METHOD_OPTIONS,
          label: 'INPUT.LABEL.IN_OUT',
          value: 'tableMethod',
          gridSize: 4,
        },
        {
          input: INPUT.SELECT,
          options: deliveryTimeSlots,
          label: timeLabel,
          disabled: !deliveryTimeSlots.length,
          value: 'deliveryTime',
          disableFastField: true,
          gridSize: 4,
        },
        setting.shouldPromptTable && {
          isHidden: values => values.deliveryMethod !== DELIVERY_METHOD.IN_RESTAURANT,
          input: INPUT.SELECT,
          options: tables.map(t => ({ labelText: t.tableName, value: t.tableId })),
          label: 'INPUT.LABEL.TABLE',
          value: 'tableId',
          disableFastField: true,
          gridSize: 4,
          validation: [input.requiredValidation],
        },
      ],
    },
    {
      style,
      items: [
        {
          label: 'INPUT.LABEL.ADDITIONAL_INSTRUCTIONS',
          value: 'instructions',
          placeholder: 'INPUT.PLACEHOLDER.ADDITIONAL_INSTRUCTIONS',
          input: INPUT.TEXTAREA,
          gridSize: 8,
        },
      ],
    },
    {
      style,
      items: [
        {
          isHidden: values => values.deliveryMethod !== DELIVERY_METHOD.DELIVERY,
          ...INPUT_TYPES.GOOGLE_ADDRESS,
          label: 'INPUT.LABEL.DELIVERY_ADDRESS',
          value: 'deliveryAddress',
          gridSize: 8,
          validation: [input.requiredValidation],
          requestOptions: {
            origin: getStoreLocation(store),
          },
          onChange: onChangeDelivery,
        },
      ],
    },
  ];
};

export const TIP_FORM = ({ setting, customizeTip = false, onChangeTip }) => {
  return [
    {
      style,
      isHidden: () => !setting.enableTip,
      items: [
        {
          ...INPUT_TYPES.AMOUNT,
          label: 'INPUT.LABEL.TIP',
          value: 'tip',
          readonly: !customizeTip,
          disableFastField: true,
          postfix: 'INPUT.POSTFIX.US_DOLLAR',
          onChange: onChangeTip,
          gridSize: 8,
        },
      ],
    },
  ];
};

export const PAYMENT_FORM = (store, deliveryMethod, cards: Array<CardModel> = []) => {
  const { setting, paymentGateway } = store;
  const paymentLocationLabel =
    deliveryMethod === DELIVERY_METHOD.DELIVERY ? 'PAYMENT_LOCATION.DELIVERY' : 'PAYMENT_LOCATION.PICKUP';
  const forceAtCounter =
    deliveryMethod === DELIVERY_METHOD.IN_RESTAURANT && setting?.methodOptions?.forceAtCounter;
  const options = [
    {
      label: paymentLocationLabel,
      value: 'OFFLINE',
    },
  ];
  const cardOptions = [
    {
      label: 'NEW_CARD',
      value: '',
    },
    ...cards.map((c: any) => ({ labelText: `${c.cardType} xxx${c.last4}`, value: c.cardId })),
  ];

  if (!forceAtCounter)
    options.unshift({
      label: 'PAYMENT_LOCATION.ONLINE',
      value: 'ONLINE',
    });

  return [
    {
      style,
      items: [
        {
          input: INPUT.BUTTON_SELECT,
          disableFastField: true,
          isHidden: () => shouldHidePaymentOptions(deliveryMethod, setting),
          options,
          label: 'INPUT.LABEL.PAYMENT_METHOD',
          value: 'paymentMethod',
          gridSize: 12,
        },
      ],
    },
    {
      style,
      isHidden: values =>
        paymentGateway !== 'AUTH' || !cards.length || values.paymentMethod === 'OFFLINE' || forceAtCounter,
      items: [
        {
          input: INPUT.SELECT,
          options: cardOptions,
          label: 'INPUT.LABEL.SELECT_CARD',
          value: 'cardId',
          gridSize: 5,
        },
      ],
    },
    {
      style,
      isHidden: values => values.paymentMethod === 'OFFLINE' || forceAtCounter || values.cardId,
      items: [
        {
          ...INPUT_TYPES.CARD_NUMBER,
          gridSize: 6,
          placeholder: '',
          validation: [input.requiredValidation],
        },
        {
          ...INPUT_TYPES.EXPIRY,
          gridSize: 3,
          validation: [input.requiredValidation],
        },
        {
          ...INPUT_TYPES.CVC,
          gridSize: 2,
          value: 'cvc',
          validation: [input.requiredValidation],
        },
        {
          label: 'INPUT.LABEL.NAME',
          value: 'cardHolder',
          input: INPUT.TEXT,
          gridSize: 6,
          validation: [input.requiredValidation],
        },
        {
          ...INPUT_TYPES.ZIP,
          gridSize: 5,
          // validation: [input.requiredValidation],
        },
        ...(paymentGateway === 'AUTH'
          ? [
              {
                input: INPUT.CHECKBOX,
                label: 'INPUT.LABEL.SAVE_CARD',
                value: 'saveCard',
                gridSize: 12,
              },
            ]
          : []),
      ],
    },
  ];
};
