import React from 'react';
import StoreModel from '$business/models/store';
import { BrandWrapper, Logo } from './styles';

interface BrandProps {
  store: StoreModel;
}

const Brand: React.FC<BrandProps> = ({ store }) => {
  const { setting, storeName } = store;
  const { logo, headerCaption } = setting;

  return (
    <BrandWrapper className={`${!!logo && 'has-logo'} ${!!headerCaption && 'has-caption'}`}>
      {!!logo && <Logo className="logo" url={process.env.REACT_APP_IMAGE_BASE + '/' + logo} />}
      {!!headerCaption && <div className="caption">{headerCaption}</div>}
      {!headerCaption && !logo && <div className="caption">{storeName}</div>}
    </BrandWrapper>
  );
};

export default Brand;
