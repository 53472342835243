import { deriveRawToModel as rawToCategory } from '$business/models/category';
import { deriveRawToModel as rawToProduct } from '$business/models/product';
import { deriveRawToModel as rawToModiferSet } from '$business/models/modifierSet';

export const rawDataToCategoryModel = raw => {
  if (!raw.root) return [];

  return {
    ...rawToCategory(raw.root),
    categories: rawDataToCategoriesModel(raw.tree),
    products: rawDataToProductsModel(raw.products),
  };
};

export const rawDataToUpsellModel = raw => {
  if (!raw.upsells) return [];

  return rawDataToCategoriesModel(raw.upsells);
};

export const rawDataToCategoriesModel = raw => {
  if (!raw) return [];

  return raw.reduce((acc, group) => {
    if (group.hide) return acc;

    acc.push(rawToCategory(group));

    return acc;
  }, []);
};

export const rawDataToProductsModel = raw => {
  if (!raw) return [];

  return raw.reduce((acc, product) => {
    if (product.hide) return acc;

    acc.push(rawToProduct(product));

    return acc;
  }, []);
};

export const rawDataToProductModel = (raw, group) => {
  return rawToProduct(raw, group);
};

export const rawDataToModifierSetModel = raw => {
  if (!raw) return [];

  return raw.reduce((acc, ms) => {
    // if (ms.hide) return acc;

    acc.push(rawToModiferSet(ms));

    return acc;
  }, []);
};
