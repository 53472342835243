import { clog, util } from '$ghelpers';
import { configs, features } from '$configs';
import ReduxStore from '$business/redux';
import { getStorageItem, clearAuth } from './local.storage';

export const fetchApi: any = async ({
  url,
  param = {},
  mockData = undefined,
  headers = {},
  method = 'POST',
  isPublic = false,
}) => {
  let response;
  if (!url && !mockData) {
    response = null;
    return response;
  }

  clog('REQUEST', param);
  if (features.useMockData && mockData) {
    await util.sleep(configs.mockFetchDuration);
    response = mockData;
  } else {
    response = await apiCall({ url, param, method, headers, isPublic });
  }
  clog('RESPONSE', response);

  return response;
};

export const checkIfTokenExpired = async () => {
  const expTimestp = await getStorageItem('expTimestp');
  if (!expTimestp || expTimestp === '0') return false;

  const now = +new Date();

  clog('NOW', now);
  clog('EXP', parseInt(expTimestp.toString()));
  return now > parseInt(expTimestp.toString());
};

export const apiCall = async ({ url, param = {}, headers = {}, method = 'POST', isPublic }) => {
  const store = ReduxStore.getState();
  const globalHeaders = store.init.headers;
  const { accessToken, userId } = store.localStorage;

  // Check if the token has expired
  if (!isPublic && accessToken && (await checkIfTokenExpired())) {
    // Token is Expired... Not what?

    // TODO: For now let's logout
    await clearAuth();
    window.location.reload();
    return;
  }

  const mergedHeaders = {
    ...headers,
    ...globalHeaders,
  };
  const options = {
    method,
    referrer: '',
    headers: {
      ...mergedHeaders,
      ...(!isPublic && { Authorization: `Bearer ${accessToken}` }),
      ...(userId && { userId: userId?.toString() }),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: method === 'GET' ? undefined : JSON.stringify(param),
  };

  let fullUrl = url.includes('://') ? url : `${process.env.REACT_APP_API_BASE}/${url}`;
  fullUrl = fullUrl.replace(/\/\s*$/, '');
  const response = await fetch(fullUrl, options).catch(handleErr);
  const result = await response.json().catch(handleErrJson);

  return Promise.resolve(result);
};

const handleErr = err => {
  console.warn(err);
  const resp = new Response(
    JSON.stringify({
      err: 'ERROR.NETWORK',
      message: err.message,
    }),
  );
  return resp;
};

const handleErrJson = err => {
  console.warn(err);
  const resp = new Response(
    JSON.stringify({
      err: 'ERROR.NETWORK',
      message: err.message,
    }),
  ).json();
  return resp;
};
