import { deriveModifierPrice } from '../redux/cart/utils';

export default interface CartItem2Model {
  id: number;
  name: string;
  name2?: string;
  quantity: number;
  price: number;
  oprice: number;
  tax: number;
  groupId: number;
  groupName: string;
  catprod: boolean;
  instruction?: string;
  optionset: CartModifierSet2Model;
}
export interface CartModifierSet2Model {
  name: string;
  main?: number;
  checks?: number;
  report?: number;
  options?: Array<CartModifier2Model>;
}

export interface CartModifier2Model {
  name: string;
  name2: string;
  price: number;
  quantity: number;
  tprice: number;
  subopt?: CartSplitSet2Model;
}

export interface CartSplitSet2Model {
  name: string;
  options?: Array<CartSplit2Model>;
}

export interface CartSplit2Model {
  name: string;
  name2?: string;
  price: number;
  quantity: number;
  tprice: number;
}

export const deriveItemShort = item => {
  const {
    productName,
    productName2,
    categoryId,
    categoryName,
    catProd,
    productId,
    quantity,
    price,
    modifiers,
    instruction,
  } = item;

  return {
    id: productId,
    name: productName,
    ...(productName2 && { name2: productName }),
    quantity,
    groupId: categoryId,
    groupName: categoryName,
    catprod: catProd,
    price: price.subtotalSum,
    oprice: price.original,
    tax: price.taxPercentage,
    optionset: deriveOptionSet(modifiers),
    instruction: instruction || '',
  };
};

export const deriveOptionSet = modifiers => {
  return modifiers.reduce((acc, m) => {
    const { modifierSetIndex, label } = m;
    const newModifier = deriveModifierShort(m);
    const msIndex = acc.findIndex(ms => ms.index === modifierSetIndex);
    if (msIndex < 0) {
      // new modifier set
      const modifierSet = {
        index: modifierSetIndex,
        name: label,
        options: [newModifier],
      };
      acc.push(modifierSet);
    } else {
      // existing modifier set
      acc[msIndex].options.push(newModifier);
    }
    return acc;
  }, []);
};

export const deriveModifierShort = modifier => {
  const { modifierName, modifierName2, quantity, price, splits } = modifier;

  return {
    name: modifierName,
    ...(modifierName2 && { name2: modifierName2 }),
    quantity,
    price: price.original || 0,
    tprice: deriveModifierPrice(modifier),
    ...(splits && {
      subopt: {
        options: splits.map(s => deriveSplitShort(s)),
      },
    }),
  };
};

export const deriveSplitShort = split => {
  const { splitName, splitName2, label, quantity, price } = split;

  return {
    name: splitName,
    ...(splitName2 && { name2: splitName2 }),
    label,
    quantity,
    price: price || 0,
    tprice: price || 0,
  };
};
