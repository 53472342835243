import React from 'react';
import intl from '$intl';
import StoreModel from '$business/models/store';
import { IonFooterWrapper } from './styles';
import { deriveStoreOpening } from '$business/helpers/util';
import { IonTitle, IonToolbar } from '@ionic/react';

interface StoreStatusProps {
  store: StoreModel;
}

const StoreStatus: React.FC<StoreStatusProps> = ({ store }) => {
  const isStoreOpenNow = deriveStoreOpening(store);

  if (isStoreOpenNow) return null;

  return (
    <IonFooterWrapper>
      <IonToolbar>
        <IonTitle>{intl('MESSAGE.STORE_CLOSED')}</IonTitle>
      </IonToolbar>
    </IonFooterWrapper>
  );
};

export default StoreStatus;
