import styled from 'styled-components';
import { Div, SPACE } from '$gstyles';

const highlighted = 'var(--ion-highlighted-item)';
const priceWidth = '80px';

export const ItemWrapper = styled(Div)`
  ${props => (!props.isAdded ? `background-color: ${highlighted};` : '')}
  padding: ${SPACE.MEDIUM} ${SPACE.LARGE};
  border-bottom: 1px solid #ccc;

  .more-ellipsis {
    position: relative;
    top: ${SPACE.MEDIUM};
    left: ${SPACE.SMALL};
  }
`;

export const ItemProduct = styled(Div)`
  display: flex;
`;

export const ItemImage = styled(Div)`
  display: flex;
  padding: ${SPACE.SMALL} 0;

  img {
    max-width: initial;
    max-height: 40px;
  }
`;

export const ItemName = styled(Div)`
  flex: 1;
`;

export const ItemPrice = styled(Div)`
  width: ${priceWidth};
  text-align: right;
`;

export const ItemModifiers = styled(Div)`
  display: flex;
  padding-left: 10px;
  opacity: 0.7;
  font-size: 0.9em;

  .split {
    padding-left: 10px;
  }
`;

export const TotalWrapper = styled(Div)`
  padding: ${SPACE.MEDIUM} ${SPACE.LARGE};
`;

export const TotalLine = styled(Div)`
  display: flex;
  padding: ${SPACE.XSMALL} 0;
`;

export const TotalLabel = styled(Div)`
  flex: 1;
  text-align: right;
`;

export const TotalPrice = styled(Div)`
  width: ${priceWidth};
  text-align: right;
`;
