import React from 'react';
import intl from '$intl';
import MuiModal from '$gcomponents/reusables/modal/material';
import { Button } from '@material-ui/core';
import { Col, Div, FONT, SPACE, Space } from '$gstyles';
import { CartModifierModel } from '$business/models/cartItem';
import { Flex } from '$gstyles/wrapper';

interface DefaultModalProps {
  modifiers: Array<CartModifierModel>;
  description?: string;
  show: boolean;
  onClose: () => void;
  onSkipDefault: () => void;
}

const DefaultModal: React.FC<DefaultModalProps> = ({
  modifiers,
  description,
  show,
  onClose,
  onSkipDefault,
}) => {
  const footer = (
    <>
      <Button onClick={onSkipDefault} variant="contained" color="primary">
        {intl('SCREEN.DEFAULT_MODAL.BUTTON_YES')}
      </Button>
      <Button onClick={onClose} variant="contained" color="default">
        {intl('SCREEN.DEFAULT_MODAL.BUTTON_NO')}
      </Button>
    </>
  );

  return (
    <MuiModal
      minWidth="400px"
      title={intl('SCREEN.DEFAULT_MODAL.TITLE')}
      open={show}
      onClose={onClose}
      footer={footer}>
      <Div>
        {description && <Space.Bottom margin={SPACE.LARGE}>{description}</Space.Bottom>}
        {modifiers.map((m, i) => (
          <Flex key={i}>
            <Col gridSize={4} fontSize={FONT.SMALL}>
              {m.label}:
            </Col>
            <Col gridSize={8}>{m.modifierName}</Col>
          </Flex>
        ))}
      </Div>
    </MuiModal>
  );
};

export default DefaultModal;
