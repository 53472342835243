import React from 'react';
import CartModel from '$business/models/cart';

import Item from './ReceiptItem';
import Total from './Total';

interface ReceiptListProps {
  currentRoute: string;
  cart: CartModel;
  onDeleteItem: Function;
  onEditItem: Function;
}

const ReceiptList: React.FC<ReceiptListProps> = ({ cart, onDeleteItem, onEditItem, currentRoute }) => {
  const items = cart.products.map((item, i) => {
    const isEditing = cart.pointer.productIndex === i;
    return (
      <Item
        key={i}
        currentRoute={currentRoute}
        isEditing={isEditing}
        item={item}
        onDelete={() => onDeleteItem(i)}
        onEdit={() => onEditItem(i)}
      />
    );
  });

  return (
    <>
      {items}
      <Total cart={cart} />
    </>
  );
};

export default ReceiptList;
