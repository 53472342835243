import styled from 'styled-components';
import { IonPage } from '@ionic/react';

export const IonPageWrapper = styled(IonPage)`
  .MuiPaginationItem-root,
  .MuiPaginationItem-icon {
    color: var(--color);
  }

  .MuiTableCell-root {
    position: relative;
  }
  .view-icon {
    position: absolute;
    right: 4px;
  }
`;
