import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { FONT, SPACE } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .heretogo-choices {
    display: flex;

    > div {
      padding: ${SPACE.MEDIUM};
    }

    button {
      flex: 1;
      height: 100px;
      font-size: ${FONT.LARGE};
    }
  }

  .delivery-options-container {
    margin: auto;
    max-width: 800px;
  }
`;
