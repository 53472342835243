import SplitModel, { deriveSplits } from './split';

export default interface SplitSetModel {
  splitSetId: number;
  splitSetName: string;
  splitSetName2?: string;
  description: string;
  description2?: string;
  isOptional: boolean;
  isMultiple: boolean;
  maxSplits: number;
  maxChecks: number;
  isMain: boolean;
  splits?: Array<SplitModel>;
}

export const deriveRawToSplitSet = rawSplitSet => {
  if (!rawSplitSet) return undefined;

  const {
    id: splitSetId,
    checks: maxChecks,
    desc: description,
    desc2: description2,
    main: isMain,
    max: maxSplits,
    multi: isMultiple,
    name: splitSetName,
    name2: splitSetName2,
    optional: isOptional,
    options: splits,
  } = rawSplitSet;

  const splitSet: SplitSetModel = {
    splitSetId,
    splitSetName,
    splitSetName2,
    description,
    description2,
    isOptional: !!isOptional,
    isMultiple: !!isMultiple,
    maxSplits,
    maxChecks,
    isMain: !!isMain,
    splits: deriveSplits(splits, rawSplitSet),
  };

  return splitSet;
};
