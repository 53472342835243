import { combineReducers } from 'redux';

import { loadingReducer } from '$gbusiness/redux/loading';
import { routerReducer } from '$gbusiness/redux/router';
import { cuserReducer } from '$gbusiness/redux/currentUser';
import { cardReducer } from '$gbusiness/redux/card';
import { toasterReducer } from '$gbusiness/redux/toaster';
import { localStorageReducer } from '$gbusiness/redux/localStorage';

import { initReducer } from './init';
import { menuReducer } from './menu';
import { storeReducer } from './store';

const appReducer = combineReducers({
  init: initReducer,
  loading: loadingReducer,
  localStorage: localStorageReducer,
  routing: routerReducer,
  currentUser: cuserReducer,
  toaster: toasterReducer,
  store: storeReducer,
  card: cardReducer,
  menu: menuReducer,
});

export default appReducer;
