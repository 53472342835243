import { FORGOT_SUCCESS, LOGIN_SUCCESS } from '$gbusiness/redux/auth/types';

import { InitModel, INIT_STATE, LOAD_HEADERS } from './types';
import { FETCH_STORE_FAILURE, FETCH_STORE_SUCCESS } from '../store/types';

export function initReducer(state: InitModel = INIT_STATE, action): InitModel {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        headers: {
          ...state.headers,
        },
      };
    case LOAD_HEADERS:
      return {
        ...state,
        headers: {
          ...state.headers,
          ...action.headers,
        },
      };
    case FETCH_STORE_SUCCESS:
      return {
        ...state,
        initSuccess: true,
      };
    case FETCH_STORE_FAILURE:
      return {
        ...state,
        initSuccess: false,
      };
    case FORGOT_SUCCESS:
      return {
        ...state,
        public: {
          ...state.public,
          resetRequested: true,
        },
      };
    default:
      return state;
  }
}
