import React from 'react';

import CategoryModel from '$business/models/category';
import BoxSizeModel from '$business/models/boxSize';

import ItemBox from '../ItemBox';
import { CATEGORIES } from '$business/enums/routes';

interface CategoryBoxProps {
  category: CategoryModel;
  size: BoxSizeModel;
  setting: any;
}

const CategoryBox: React.FC<CategoryBoxProps> = ({ category, size, setting }) => {
  const {
    display: { hasImage, image, shouldMarkNew },
    categoryId,
    categoryName,
    categoryName2,
    description,
    description2,
  } = category;

  const backgroundColor = 'var(--ion-color-category)';
  const color = 'var(--ion-color-category-contrast-bw)';

  return (
    <ItemBox
      linkRoute={`${CATEGORIES}/${categoryId}`}
      title={categoryName}
      title2={categoryName2 || ''}
      description={description}
      description2={description2 || ''}
      setting={setting}
      display={{
        hasPrice: false,
        hasImage,
        image,
        shouldMarkNew,
        backgroundColor,
        color,
        size,
      }}
    />
  );
};

export default CategoryBox;
