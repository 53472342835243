import { IonFooter } from '@ionic/react';
import styled from 'styled-components';

export const IonFooterWrapper = styled(IonFooter)`
  ion-toolbar {
    --background: var(--ion-color-danger);
  }
  .toolbar-background {
    background-color: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast-bw);
  }

  .message {
    display: block;
  }
`;

export const HoursWrapper = styled.div`
  text-align: left;
`;
