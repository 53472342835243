import CartItemModel from './cartItem';

export default interface CartModel {
  products: Array<CartItemModel>;
  price: {
    subtotal: number;
    total: number;
    tax: number;
    delivery: number;
    discount: number;
    tip: number;
  };
  order?: {
    type: string; // DELIVERY, PICKUP, IN-RESTARURANT
    tableId?: number;
    tableName?: string;
    deliveryTime?: string;
    instruction?: string;
  };
  pointer: PointerModel;
}

export interface PointerModel {
  productIndex: number;
  modifierIndex: number;
}

export const defaultCart: CartModel = {
  products: [],
  price: {
    subtotal: 0,
    total: 0,
    delivery: 0,
    tax: 0,
    discount: 0,
    tip: 0,
  },
  pointer: {
    productIndex: 0,
    modifierIndex: 0,
  },
};
