import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { IonButton, IonFooter, IonIcon, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { arrowBackSharp } from 'ionicons/icons';

import { screen } from '$gcomponents/hoc';
import { menuActions, cartActions } from '$business/redux/actions';
import { Font } from '$gstyles';
import CategoryContainer from '$components/menu/CategoryContainer';
import { FlexIonContent, PageTitle } from '$styles';
import CategoryModel from '$business/models/category';
import DisplaySecond from '$components/helpers/displaySecond';
import { ROUTES } from '$business/enums';
import { IconButton } from '@material-ui/core';
import { Flex } from '$gstyles/wrapper';
import intl from '$intl';

import { IonPageWrapper } from './styles';

interface MenuScreenProps {
  onHydrate;
  onDehydrate;
  setting;
  category: CategoryModel;
  cleanProducts: Function;
}

const Screen: React.FC<MenuScreenProps> = ({ category, setting, cleanProducts }) => {
  useIonViewDidEnter(() => {
    cleanProducts(true);
  });

  const history = useHistory();

  if (!category) return <IonPageWrapper />;
  const hasParent = !!category.parentId;

  const handleBack = () => {
    if (!category?.parentId) history.goBack();
    else history.push(`${ROUTES.CATEGORIES}/${category.parentId}`);
  };

  const renderBackButton = () => {
    if (!hasParent) return null;
    return (
      <IconButton onClick={handleBack}>
        <ArrowBackIcon />
      </IconButton>
    );
  };

  return (
    <IonPageWrapper>
      <FlexIonContent>
        <PageTitle>
          <Flex className="title">
            {renderBackButton()}
            <Font.LARGE weight>
              <DisplaySecond obj={category} prop="categoryName" display2nd={setting.displaySecond} />
            </Font.LARGE>
          </Flex>
        </PageTitle>
        <CategoryContainer category={category} setting={setting} />
      </FlexIonContent>
      {hasParent && (
        <IonFooter>
          <IonToolbar>
            <IonButton onClick={handleBack}>
              <IonIcon slot="start" icon={arrowBackSharp} />
              {intl('COMMON.BACK')}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPageWrapper>
  );
};

const mapStateToProps = state => ({
  resetOnRoute: true,
  category: state.menu.category,
  setting: state.store.setting,
});

const mapDispatchToProps = {
  cleanProducts: cartActions.cleanProducts,
  onHydrate: params => menuActions.fetchCategories(params.categoryId),
  onDehydrate: menuActions.dehydrateCategories,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(Screen));
