import React from 'react';

import { ImgSectionWrapper } from './styles';

interface ImageSectionProps {
  src?: string;
}

const imageBase = process.env.REACT_APP_IMAGE_BASE;

const ImageSection: React.FC<ImageSectionProps> = ({ src }) => {
  if (!src) return null;

  return (
    <ImgSectionWrapper src={`${imageBase}/${src}`}>
      <div />
    </ImgSectionWrapper>
  );
};

export default ImageSection;
