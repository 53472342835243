import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonIcon, IonFooter, IonToolbar } from '@ionic/react';
import { arrowForwardSharp, arrowBackSharp } from 'ionicons/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';

import { screen } from '$gcomponents/hoc';
import { cartActions, menuActions } from '$business/redux/actions';
import { Font, Inline, SPACE } from '$gstyles';
import ProductContainer from '$components/menu/ProductContainer';
import { FlexIonContent, PageTitle } from '$styles';
import ProductModel from '$business/models/product';
import { useHistory } from 'react-router';
import { ROUTES } from '$business/enums';
import CartModel from '$business/models/cart';
import DisplaySecond from '$components/helpers/displaySecond';
import intl from '$intl';

import DefaultModal from './defaultModal';
import { IonPageWrapper } from './styles';
import { Flex } from '$gstyles/wrapper';
import { clog } from '$ghelpers';

interface ProductScreenProps {
  hydrate: Function;
  onDehydrate;
  product: ProductModel;
  cart: CartModel;
  addProduct: Function;
  changePointer: Function;
  cleanProducts: Function;
  finishProduct: Function;
  removeModifierSet: Function;
  setting: any;
  match: any;
}

const ProductScreen: React.FC<ProductScreenProps> = ({
  product,
  cart,
  addProduct,
  changePointer,
  removeModifierSet,
  cleanProducts,
  finishProduct,
  match,
  hydrate,
  setting,
}) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  /*
   * The following logic for menu state management is complex.
   * Please do not touch unless
   * you are absolutley sure what you are doing.
   */
  useEffect(() => {
    clog('MOUNT');
    return () => {
      cleanProducts();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // PRODUCT CHANGED
  useEffect(() => {
    async function handleProduct() {
      if (!product) return;
      clog('PRODUCT CHANGED', product.productId, cart.products, cart.products.length);
      // On Edit or Manual navigation to different product
      const currentItem = cart.products[cart.pointer.productIndex];
      if (cart.pointer.productIndex < cart.products.length && currentItem.isAdded) {
        const currentItem = cart.products[cart.pointer.productIndex];
        clog('CLEANING2');
        await cleanProducts();
        if (currentItem.productId === product.productId) return; // Don't add product for same
      }
      clog('ADDING');
      addProduct(product);
      if (product.hasDefaultOption && cart.pointer.modifierIndex === 0) {
        setShowModal(true);
      }
      // Check if default options is enabled
    }
    handleProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  // ROUTE - PRODUCT ID CHANGED
  useEffect(() => {
    async function handleRouteChange() {
      clog('ROUTE PRODUCTID CHANGED', match.params.productId);
      await hydrate(parseInt(match.params.productId));
    }
    handleRouteChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.productId]);

  // ROUTE - MODIIFER INDEX CHANGED
  useEffect(() => {
    async function handleRouteChange() {
      clog('ROUTE MODIFIERINDEX CHANGED', match.params.index);
      // If modifier index is great than pointer then navigate back to the pointer
      if (match.params.index > cart.pointer.modifierIndex)
        history.replace(`${ROUTES.PRODUCTS}/${match.params?.productId}/${cart.pointer.productIndex}`);
      else if (match.params.index < cart.pointer.modifierIndex) {
        changePointer(null, null, parseInt(match.params.index));
      }
    }
    handleRouteChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.index]);

  const index = parseInt(match.params.index);
  if (!product) return <IonPageWrapper />;

  // Adjust index if out of range or trying to skip
  const { pointer, products: cartItems } = cart;
  const { modifierSets } = product;

  // Valid modifierset implies modifiers to show otherwise show quantity screen
  const modifierSet = modifierSets[index] || undefined;

  const currentItem = cartItems[pointer.productIndex];
  const currentSelections =
    currentItem && currentItem.modifiers
      ? currentItem.modifiers.filter((m, i) => m?.modifierSetIndex === index)
      : [];

  const nextButtonKey =
    'SCREEN.PRODUCT.' + (modifierSet ? 'CONTINUE' : currentItem?.isAdded ? 'MODIFY' : 'ADD');

  const handleContinue = async () => {
    if (!modifierSet) {
      await finishProduct();
      await changePointer(null, cart.products.length, 0);
      history.push(ROUTES.DECISION);
    } else {
      await changePointer(null, null, index + 1); // pointer change useEffect should navigate
    }
  };

  const skipDefault = () => {
    const endIndex = product.modifierSets.length;
    changePointer(null, null, endIndex);
    setShowModal(false);
  };

  const handleBack = () => {
    if (!product) history.goBack();
    else if (index <= 0) history.push(`${ROUTES.CATEGORIES}/${product.categoryId}`);
    else changePointer(null, null, index - 1);
  };

  const renderBackButton = () => {
    if (!product) return null;

    return (
      <IconButton onClick={handleBack}>
        <ArrowBackIcon />
      </IconButton>
    );
  };

  const shouldDisableContinue =
    modifierSet && !modifierSet.isMultiple && !modifierSet.isOptional && !currentSelections.length;

  return (
    <IonPageWrapper>
      <FlexIonContent>
        <PageTitle>
          <Flex className="title">
            {renderBackButton()}
            <Font.LARGE weight>
              <DisplaySecond obj={product} prop="productName" display2nd={setting.displaySecond} />
              {modifierSet && (
                <>
                  <Inline margin={SPACE.MEDIUM}>&raquo;</Inline>
                  <DisplaySecond obj={modifierSet} prop="description" display2nd={setting.displaySecond} />
                </>
              )}
            </Font.LARGE>
          </Flex>
        </PageTitle>
        <ProductContainer
          currentItem={currentItem}
          pointer={pointer}
          modifierSet={modifierSet}
          selections={currentSelections}
          setting={setting}
        />
      </FlexIonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton onClick={handleBack}>
            <IonIcon slot="start" icon={arrowBackSharp} />
            {intl('COMMON.BACK')}
          </IonButton>
          <IonButton onClick={handleContinue} disabled={shouldDisableContinue}>
            <IonIcon slot="start" icon={arrowForwardSharp} />
            {intl(nextButtonKey)}
          </IonButton>
        </IonToolbar>
      </IonFooter>
      <DefaultModal
        modifiers={currentItem?.modifiers || []}
        description={product.defaultDescription}
        show={showModal}
        onSkipDefault={skipDefault}
        onClose={() => setShowModal(false)}
      />
    </IonPageWrapper>
  );
};

const mapStateToProps = state => ({
  resetOnRoute: true,
  product: state.menu.product,
  cart: state.localStorage.cart,
  setting: state.store.setting,
});

const mapDispatchToProps = {
  hydrate: menuActions.fetchProduct,
  onDehydrate: menuActions.dehydrateProduct,
  addProduct: cartActions.addProduct,
  cleanProducts: cartActions.cleanProducts,
  changePointer: cartActions.changePointer,
  removeModifierSet: cartActions.removeModifierSet,
  finishProduct: cartActions.finishProduct,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ProductScreen));
