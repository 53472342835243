import React from 'react';
import { Button } from '$gcomponents/primitives';

import { Wrapper } from './styles';

interface TocContentProps {
  onClickClose: () => void;
}

const TocContent: React.FC<TocContentProps> = ({ onClickClose }) => {
  return (
    <Wrapper>
      <h2 className="center">TERMS &amp; CONDITIONS and PRIVACY POLICY</h2>
      <div className="center">
        <h3>Non-Refundable</h3>
        <p>All Gift Card purchases are non-refundable.</p>
        <h3>OUR PRIVACY COMMITMENT</h3>
        <p>
          We are committed to respecting your privacy and recognizing your need for appropriate protection and
          management of personally identifiable information you share with us. The purpose of this privacy
          policy is to inform you what personally identifiable information or personal information we may
          collect from you and how we use such information.
        </p>
        <p>
          As part of our commitment to your privacy, we adhere to the guidelines and practices adopted by the
          Direct Marketing Association’s “Privacy Promise to American Consumers.” We do not share your data
          with any other marketers. We honor customer requests not to receive mail, telephone or other
          solicitations from us.
        </p>
        <h3>WHAT INFORMATION IS COLLECTED?</h3>
        <p>
          For each visitor to our online gift card service, we ask you to provide your name, billing address,
          telephone number credit card information and email address. We also maintain a record of your online
          transactions. Whether or not to provide such information is completely your own choice. But if you
          choose not to provide this information we request, you may be unable to purchase products, or access
          certain services, warranty service, offers and content on our website.
        </p>
        <h3>USING INFORMATION ABOUT YOU</h3>
        <p>
          Your personally identifiable information is not shared with other organizations. We use the
          personally identifiable information you provide for internal purposes only, such as confirming your
          gift card purchase.
        </p>
        <h3>SECURITY</h3>
        <p>
          We always use industry-standard encryption technologies when transferring and receiving consumer
          data exchanged with our website. We have appropriate security measures in place in our physical
          facilities to protect against the loss, misuse or alteration of information that we have collected
          from you at our site.
        </p>
        <p>
          Unfortunately, however, no data transmission over the Internet can be guaranteed to be 100% secure.
          As a result, while we strive to protect your personal information, we cannot guarantee or warrant
          the security of any information you transmit to or from our website, and you do so at your own risk.
          Once we receive your transmission, we will do our best to protect its security on our systems.
        </p>
      </div>
      <div>
        <Button className="close" type="button" onClick={onClickClose}>
          Close
        </Button>
      </div>
    </Wrapper>
  );
};

export default TocContent;
