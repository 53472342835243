import styled from 'styled-components';
import { FONT, SPACE, WEIGHT } from '$gstyles';

const common = props => `
  .MuiTableCell-root,
  .MuiTableCell-head
  {
    font-weight: normal;
    line-height: ${FONT.SMALL};
    border-bottom: none;
    padding: ${props.cellPadding || 'inherit'};
    display: flex;
    align-items: center;
    justity-content: start;
  }
  .MuiTableSortLabel-root:hover {
    color: var(--ion-color-light);
  }
  .MuiTableSortLabel-root.MuiTableSortLabel-active,
  .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
    .MuiTableSortLabel-icon {
    color: var(--ion-color-light);
    font-weight: ${WEIGHT.BOLD};
  }
`;

export const Vwrapper = styled.div`
  height: calc(100% - ${p => p.additionalHeight || 0}px);

  ${p => common(p)}
  .MuiCheckbox-root {
    padding: 0;
  }
  .MuiTableCell-root {
    border-bottom: none;
    padding: ${p => p.cellPadding || SPACE.MEDIUM};
  }
  .MuiTableCell-root {
    overflow: hidden;
    ${p => (p.fontColor ? `color: ${p.fontColor}` : '')};
    ${p => (p.fontSize ? `font-size: ${p.fontSize}` : '')};
  }
  .ReactVirtualized__Table__headerRow,
  .MuiTableRow-head,
  .MuiTableCell-head {
    background: ${p => `var(--ion-color-${p.headerColor || 'primary'})`};
    color: ${p => `var(--ion-color-${p.headerColor || 'primary'}-contrast-bw)`};
  }
  /* .MuiTableRow-root .MuiTableCell-root:first-child {
    padding-left: calc(${p => p.cellPadding || SPACE.MEDIUM} + 2px);
  } */
  .ReactVirtualized__Table__headerColumn,
  .MuiTableRow-head {
    ${p => (p.headerHeight ? `height: ${p.headerHeight}px` : '')};
    overflow: hidden;
    /* border-bottom: 1px solid var(--ion-border-color); */
  }

  .ReactVirtualized__Table__Grid {
    min-width: 100% !important;
  }
  .ReactVirtualized__Grid__innerScrollContainer {
    max-width: 100% !important;
  }

  .virtualized-table {
    ${p => (p.minWidth ? `min-width: ${p.minWidth}px;` : '')}
    ${p => (p.maxWidth ? `max-width: ${p.maxWidth}px;` : '')}
  }
  .virtualized-row {
    display: flex;
    ${p => (p.minWidth ? `min-width: ${p.minWidth}px;` : '')}
    ${p => (p.maxWidth ? `max-width: ${p.maxWidth}px;` : '')}
  }
  .select-all {
    color: ${p => `var(--ion-color-${p.headerColor || 'primary'}-contrast-bw)`};
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: inherit;
  }

  .MuiPagination-root {
    display: flex;
    justify-content: center;
  }

  ${p =>
    p.stripped
      ? `
        .virtualized-row:nth-child(even) {
        background: var(--ion-color-light-shade);
        }`
      : ''}
`;
