import StoreModel, { defaultStore } from '$business/models/store';

export const FETCH_STORE_SUCCESS = 'FETCH_STORE_SUCCESS';
export const FETCH_STORE_FAILURE = 'FETCH_STORE_FAILURE';

export interface FetchStoreSuccessType {
  type: typeof FETCH_STORE_SUCCESS;
  store: StoreModel;
}

export interface FetchStoreFailureType {
  type: typeof FETCH_STORE_FAILURE;
  err: string;
}

export type InitActionTypes = FetchStoreSuccessType | FetchStoreFailureType;

export const INIT_STATE: StoreModel = defaultStore;
