import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { FONT, SPACE, WEIGHT } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  font-size: ${FONT.MEDIUM};
  .table {
  }

  .directions span {
    text-transform: uppercase;
    margin-left: ${SPACE.MEDIUM};
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
`;
