import { Dispatch } from 'redux';
import Cookies from 'js-cookie';

import { configs } from '$configs';
import { LOADING } from '$gbusiness/redux/loading/types';
import { LOAD_STORAGE } from '$gbusiness/redux/localStorage/types';
import { SET_CUSER, CUSER_INIT_STATE } from '$gbusiness/redux/currentUser/types';
import { apiService, localStorage } from '$gbusiness/services';

import { AppModel } from '../';
import { FETCH_STORE_SUCCESS, FETCH_STORE_FAILURE } from '../store/types';
import { LOAD_HEADERS } from './types';
import { deriveRawToStore } from '$business/models/store';
import { defaultCart } from '$business/models/cart';
import { deriveRawToUser } from '$gbusiness/models/user';
import { LOAD_CARDS_SUCCESS } from '$gbusiness/redux/card/types';
import { swithCustomTheme } from '../../services/theme';
import { clog } from '$ghelpers/util';

// function restoreTheme(localTheme) {
//   theme.switchTheme(localTheme);
// }

async function loadStorage(dispatch) {
  const storageData = await localStorage.getStorageData();
  const storeNumber = parseInt(Cookies.get('storeNumber') || '0');

  clog('storageData', storageData, storeNumber);

  const colorSet = storageData.colorSet !== '' ? JSON.parse(storageData.colorSet) : undefined;
  const cart = storageData.cart ? JSON.parse(storageData.cart) : defaultCart;

  dispatch({
    type: LOAD_STORAGE,
    localStorage: {
      ...storageData,
      storeNumber,
      colorSet,
      cart,
    },
  });

  dispatch({
    type: LOAD_HEADERS,
    headers: {
      storeNumber,
    },
  });
}

async function fetchStore(dispatch) {
  const response = await apiService.fetchApi({
    url: configs.api.init,
    param: {},
    isPublic: true,
    method: 'POST',
  });

  if (!response || response.err || (response.store && !response.store.storeNumber)) {
    return dispatch({
      type: FETCH_STORE_FAILURE,
      err: response?.err,
    });
  }

  const user = deriveRawToUser(response.user) || CUSER_INIT_STATE;

  if (!user.userId) {
    await localStorage.clearAuth();
  }

  dispatch({
    type: SET_CUSER,
    user,
  });

  const store = deriveRawToStore(response.store, response.tables);
  if (store?.setting) {
    const {
      setting: { theme: storeTheme },
    } = store;
    swithCustomTheme(storeTheme);

    await localStorage.setStorageItems({
      storeNumber: store.storeNumber,
      userId: user?.userId,
    });
  }

  dispatch({
    type: FETCH_STORE_SUCCESS,
    store,
  });
  dispatch({
    type: LOAD_CARDS_SUCCESS,
    cards: response.cards,
  });
}

export function loadApp() {
  return async (dispatch: Dispatch, getState: () => AppModel) => {
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.INITIALIZING',
    });

    // Load LOCAL STORAGE and HEADERS
    await loadStorage(dispatch);

    // Fetching initial store
    await fetchStore(dispatch);
  };
}
