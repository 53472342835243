import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';

import { ROUTES as GROUTES } from '$gbusiness/enums';
import { ROUTES } from '$business/enums';

import PrivateRoute from './privateRoute';
import RouterModel from '$gbusiness/models/route';

interface RouterProps {
  isLoggedIn: boolean;
  defaultRoute: RouterModel;
  singlePage: boolean;
}

const AppOutlet: React.FC<RouterProps> = ({ isLoggedIn, defaultRoute, singlePage }) => {
  return (
    <IonRouterOutlet id="main" animated={false}>
      {ROUTES.PRIVATE_LIST.map((menu, i) => (
        <PrivateRoute
          key={i}
          exact={menu.exact || true}
          path={`${menu.path}${menu.param ? `/${menu.param}` : ''}`}
          component={menu.component}
          isLoggedIn={isLoggedIn}
        />
      ))}
      {ROUTES.PUBLIC_LIST.map((menu, i) => {
        if (singlePage && (menu.path === ROUTES.CATEGORIES || menu.path === ROUTES.PRODUCTS))
          return <div key={i}></div>;
        return (
          <Route
            key={i}
            exact={menu.exact || true}
            path={`${menu.path}${menu.param ? `/${menu.param}` : ''}`}
            component={menu.component}
          />
        );
      })}
      {GROUTES.PUBLIC_LIST.map((menu, i) => (
        <Route
          key={i}
          path={`${menu.path}${menu.param ? `/${menu.param}` : ''}`}
          component={menu.component}
          exact={menu.exact || true}
        />
      ))}

      <Route
        path={defaultRoute.path}
        component={defaultRoute.component}
        render={() => <Redirect to={defaultRoute.path} exact />}
        exact
      />
    </IonRouterOutlet>
  );
};

export default AppOutlet;
