import React from 'react';
import { ImageWrapper } from './styles';

interface ImageSectionProps {
  src?: string;
  size: number;
}

const imageBase = process.env.REACT_APP_IMAGE_BASE;

const ImageBox: React.FC<ImageSectionProps> = ({ src, size }) => {
  if (!src) return <ImageWrapper size={size} />;

  return (
    <ImageWrapper source={`${imageBase}/${src}`} size={size}>
      <div />
    </ImageWrapper>
  );
};

export default ImageBox;
