import SplitSetModel, { deriveRawToSplitSet } from './splitSet';

export default interface ModifierModel {
  index: number;
  modifierId: number;
  modifierName: string;
  modifierName2?: string;
  description: string;
  description2?: string;
  label: string;
  hasImage: boolean;
  hasPrice: boolean;
  image?: string;
  price?: number;
  price2?: number;
  price3?: number;
  isDefault?: boolean;
  splitSet?: SplitSetModel;
}

export const deriveModifiers = (raw, rawModifierSet) => {
  if (!raw) return [];

  return raw.reduce((acc, modifier, i) => {
    if (modifier.hide) return acc;

    acc.push(deriveRawToModifier(modifier, i, rawModifierSet));

    return acc;
  }, []);
};

export const deriveRawToModifier = (rawModifier, index, rawModifierSet) => {
  if (!rawModifier) return undefined;

  const {
    id: modifierId,
    n: modifierName,
    n2: modifierName2,
    d: description,
    d2: description2,
    def: isDefault,
    hasImage,
    image,
    p: price,
    p2: price2,
    p3: price3,
    sos: splitSets,
  } = rawModifier;

  const { multi, defopt, subopt: hasSplits } = rawModifierSet;
  const isNewDefault = !multi && defopt === index ? true : !!isDefault;

  const modifier: ModifierModel = {
    index,
    modifierId,
    modifierName,
    modifierName2,
    description,
    description2,
    label: rawModifierSet?.name || '',
    hasImage,
    hasPrice: price !== undefined || price2 !== undefined || price3 !== undefined,
    image,
    price,
    price2,
    price3,
    isDefault: isNewDefault,
    ...(!!hasSplits &&
      splitSets &&
      splitSets[0] && {
        splitSet: deriveRawToSplitSet(splitSets[0]),
      }),
  };

  return modifier;
};
